import React, { FC, useMemo } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Paragraph, Popup } from '@stylique/core';
import {
  useProductDispatchContext,
  useProductStateContext
} from 'providers/Product';

import { convertGroupsData, createGroupsData } from 'utils/products';
import Tabs from './Tabs';
import { combinationsString } from 'utils/combinations';

const VariationPropertiesPopup: FC = () => {
  const { setIndexToFillProps, setVariationImages, setVariationProperties } =
    useProductDispatchContext();

  const { variations, indexToFillProps } = useProductStateContext();
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();

  const currentVariation = useMemo(
    () => variations[indexToFillProps],
    [indexToFillProps, variations]
  );

  const closeModal = () => {
    setVariationImages([]);
    setIndexToFillProps(-1);
  };

  const onFinish = (inputs: {
    [key: string]: { [key: string]: string } | string | number[] | boolean;
  }) => {
    setVariationProperties(indexToFillProps, {
      name: inputs.name as string,
      is_simple_product: inputs.is_simple_product as boolean,
      slug: inputs.slug as string,
      sku: inputs.sku as string,
      price: inputs.price as string,
      tags: inputs.tags as number[],
      inputs: convertGroupsData(
        inputs as { [key: string]: { [key: string]: string } }
      )
    });
  };

  const initialValues = useMemo(() => {
    if (currentVariation?.inputs) {
      return {
        name: currentVariation.name,
        is_simple_product: currentVariation.is_simple_product,
        slug: currentVariation.slug,
        sku: currentVariation.sku,
        price: currentVariation.price,
        tags: currentVariation.tags,
        ...createGroupsData(currentVariation.inputs)
      };
    }

    return {};
  }, [currentVariation]);

  return (
    <Popup isVisible onClose={closeModal} width={840} closable={false}>
      <Row align="middle" justify="space-between" style={{ paddingBottom: 20 }}>
        <Col>
          <Paragraph color="text-color" style={{ fontSize: 18 }}>
            {combinationsString(currentVariation.combinations as any)}
          </Paragraph>
        </Col>
        <Col>
          <Button type="primary" onClick={form.submit}>
            {t('save', { ns: 'common' })}
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        id="variation-properties"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Tabs isMultiVariation />
      </Form>
    </Popup>
  );
};

export default VariationPropertiesPopup;
