import { gql } from '@apollo/client';

import { MANUFACTURES_FRAGMENT } from './fragments';

export const GET_MANUFACTURERS = gql`
  query manufacturers {
    manufacturers {
      data {
        ...ManufacturerFragment
      }
      message
      status
    }
  }
  ${MANUFACTURES_FRAGMENT}
`;
