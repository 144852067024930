import { gql } from '@apollo/client';

export const VENDOR_FRAGMENT = gql`
  fragment VerifyVendorFragment on Vendor {
    id
    name
    surname
    status
    email
    phone
    avatar
  }
`;
