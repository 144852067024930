import React, { RefObject, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { FormItem, Select } from '@stylique/core';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { GET_TAGS } from 'graphql/tags/queries';
import { tagsUpdate_tagsUpdate_data } from '__generated__/tagsUpdate';

const ByTag = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const form = Form.useFormInstance();
  const isChecked = Form.useWatch('tagsCheckbox', form);

  const {
    data: { tags: { data: { results: tags = [] } = {} } = {} } = {},
    loading: isTags
  } = useQuery(GET_TAGS, {
    fetchPolicy: 'no-cache'
  });

  const tagOptions = useMemo(() => {
    return tags.map(({ name, id }: tagsUpdate_tagsUpdate_data) => ({
      label: name,
      value: id
    }));
  }, [tags]);

  return (
    <div ref={parentRef}>
      <FormItem valuePropName="checked" name="tagsCheckbox">
        <Checkbox style={{ fontSize: '14px' }}>
          {t('website_menu.by_tag')}
        </Checkbox>
      </FormItem>
      {isChecked ? (
        <FormItem name="tags">
          <Select
            loading={isTags}
            allowClear
            mode="multiple"
            style={{ fontSize: '14px', margin: '0' }}
            placeholder={t('website_menu.by_tag_placeholder')}
            options={tagOptions}
            size="middle"
            filterOption={(inputValue, option) => {
              if (typeof option?.label === 'string') {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(inputValue.toLowerCase());
              }

              return false;
            }}
            getPopupContainer={() => parentRef.current as HTMLElement}
          />
        </FormItem>
      ) : null}
    </div>
  );
};

export default ByTag;
