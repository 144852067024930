import Hotjar from '@hotjar/browser';
import { useAuth } from 'auth';

export interface IAnalyticsService {
  init: () => void;
}

class AnalyticsService implements IAnalyticsService {
  init() {
    const isPartner = useAuth.getState().adminRole !== 'admin';
    const siteId = 3546725;
    const hotjarVersion = 6;

    if (isPartner) {
      Hotjar.init(siteId, hotjarVersion);
    }
  }
}

export default new AnalyticsService();
