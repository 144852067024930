import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  StopOutlined
} from '@ant-design/icons';
import { Space } from 'antd';
import { TFunction } from 'react-i18next';

const getMenuActions = (
  t: TFunction,
  isPublished: boolean,
  level: '2' | '3' | '4'
) => {
  const isLevel4 = String(level) === '4';

  const statusAction = isPublished
    ? {
        key: 'published',
        label: (
          <Space
            size={8}
            align="center"
            style={{ fontSize: '14px', color: '#FF4D4F' }}
          >
            <StopOutlined />
            <span>{t('unpublish', { ns: 'common' })}</span>
          </Space>
        )
      }
    : {
        key: 'unpublished',
        label: (
          <Space size={8} align="center" style={{ fontSize: '14px' }}>
            <SendOutlined />
            <span>{t('publish', { ns: 'common' })}</span>
          </Space>
        )
      };

  return [
    {
      key: 'edit',
      label: (
        <Space size={8} align="center" style={{ fontSize: '14px' }}>
          <EditOutlined />

          <span>
            {isLevel4
              ? t('website_menu.edit_and_link', { ns: 'admin' })
              : t('edit', { ns: 'common' })}
          </span>
        </Space>
      )
    },
    {
      key: 'delete',
      label: (
        <Space size={8} align="center" style={{ fontSize: '14px' }}>
          <DeleteOutlined />
          <span>{t('delete', { ns: 'common' })}</span>
        </Space>
      )
    },
    statusAction
  ];
};

export default getMenuActions;
