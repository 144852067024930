import { gql } from '@apollo/client';

import { ATTRIBUTE_FRAGMENT } from './fragments';

export const GET_ATTRIBUTES = gql`
  query attributes($query: SearchAttributesInput) {
    attributes(query: $query) {
      data {
        results {
          ...AttributeFragment
        }
        count
      }
      message
      status
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;

export const VARIATION_ATTRIBUTES = gql`
  query variationAttributes($id: ID!, $query: filterableAttributeOptionInput) {
    variationAttributes(id: $id, query: $query) {
      data {
        total
        results {
          id
          value
          attr_id
          variation {
            sku
          }
        }
      }
      message
      status
    }
  }
`;

export const FILTERABLE_ATTRIBUTE_OPTIONS = gql`
  query filterableAttributeOptions(
    $id: ID!
    $query: filterableAttributeOptionInput
  ) {
    filterableAttributeOptions(id: $id, query: $query) {
      data {
        total
        results {
          id
          name
          value
          attr_id
          switchType
          description
        }
      }
      message
      status
    }
  }
`;

export const GET_ATTRIBUTE_BY_ID = gql`
  query attributeById($id: ID!) {
    attributeById(id: $id) {
      data {
        ...AttributeFragment
      }
      message
      status
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;

export const GET_ATTRIBUTE_OPTIONS = gql`
  query attributeOptions($id: ID!) {
    attributeOptions(id: $id) {
      data {
        id
        name
        switchType
        description
        value
        attr_id
      }
      message
      status
    }
  }
`;

export const ATTRIBUTE_OPTION_GROUPS = gql`
  query attributeOptionGroups($id: ID!) {
    attributeOptionGroups(id: $id) {
      data {
        id
        attr_id
        name
        image
      }
      message
      status
    }
  }
`;

export const INFORMATIVE_ATTRIBUTE_VALUES = gql`
  query informativeAttributeValues($query: SearchAttributeValueInput) {
    informativeAttributeValues(query: $query) {
      data {
        total
        results {
          value
        }
      }
      message
      status
    }
  }
`;
