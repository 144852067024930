import React, { FC } from 'react';
import EmptyDataBlocks from 'assets/icons/EmptyDataBlocks';
import { useTranslation } from 'react-i18next';

import { EmptyWrapper } from 'pages/admin/Sections/styles';
import { StyledCard } from '../styles';

const EmptySectionLevel: FC = () => {
  const { t } = useTranslation('admin');

  return (
    <StyledCard>
      <EmptyWrapper>
        <EmptyDataBlocks />
        <p className="empty-data-blocks__text">
          {t('website_menu.select_to_see_level_3')}
        </p>
      </EmptyWrapper>
    </StyledCard>
  );
};

export default EmptySectionLevel;
