import React from 'react';
import { Outlet } from 'react-router-dom';
import AUTH_ROLES_ENUM from 'auth/authRoles';

const Parents = React.lazy(() => import('./index'));
const ProductsDetails = React.lazy(() => import('./ProductsDetails'));

const ParentsProductsConfig = {
  settings: {
    layout: {}
  },
  auth: [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE],
  routes: [
    {
      path: 'products/parents',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Parents />
        },
        {
          path: 'details/:id',
          element: <ProductsDetails />
        },
        {
          path: 'details',
          element: <ProductsDetails />
        }
      ]
    }
  ]
};

export default ParentsProductsConfig;
