import { gql } from '@apollo/client';

export const CLICK_COLLECT_FRAGMENT = gql`
  fragment clickCollectFragment on ClickAndCollect {
    showrooms {
      data {
        id
        name
      }
    }
    switch
  }
`;

export const SETTINGS_FRAGMENT = gql`
  fragment settingsFragment on VendorSettings {
    id
    click_and_collect
    payment
  }
`;
