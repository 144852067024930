import { gql } from '@apollo/client';

import { TAGS_FRAGMENT } from './fragments';

export const GET_TAGS = gql`
  query tags($query: SearchTagsInput) {
    tags(query: $query) {
      data {
        results {
          ...TagsFragment
        }
        count
      }
      message
      status
    }
  }
  ${TAGS_FRAGMENT}
`;
