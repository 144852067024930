import React from 'react';
import { Col, Row, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledCard, StyledInfoText } from '../styles';

const SkeletonWebsiteMenu = () => {
  const { t } = useTranslation('admin');

  return (
    <Row gutter={8}>
      <Col span={8}>
        <StyledCard>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: '24px' }}
          >
            <Space
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton.Button size="small" active style={{ width: '15px' }} />
              <Skeleton.Button
                active
                block
                style={{ width: '175px' }}
                shape="default"
              />
            </Space>
          </Space>
          <Skeleton.Input
            size="small"
            block
            active
            style={{ marginBottom: '8px' }}
          />
          <Skeleton.Input
            size="large"
            block
            active
            style={{ marginBottom: '16px' }}
          />
          <Skeleton.Input
            size="large"
            block
            active
            style={{ marginBottom: '16px' }}
          />
          <Skeleton.Input
            size="large"
            block
            active
            style={{ marginBottom: '16px' }}
          />
        </StyledCard>
      </Col>
      <Col span={16}>
        <StyledCard
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StyledInfoText>{t('website_menu.being_reassign')}</StyledInfoText>
        </StyledCard>
      </Col>
    </Row>
  );
};

export default SkeletonWebsiteMenu;
