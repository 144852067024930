import React, { FC } from 'react';
import { Form, FormItemProps } from 'antd';
import styled, { css } from 'styled-components';

const { Item } = Form;

interface Props extends FormItemProps {
  direction?: 'vertical' | 'horizontal';
  minHeight?: number;
  labelWidth?: number | string;
}

type StyledItemProps = {
  validateStatus?: string;
  direction?: 'vertical' | 'horizontal';
  minHeight?: number;
  $labelWidth?: number | string;
};

const StyledItem = styled(Item)<StyledItemProps>`
  margin: 0 0 16px;
  label {
    height: auto !important;
    ${({ $labelWidth }) => $labelWidth && `width: ${$labelWidth};`}
  }
  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      && {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 60%;
        .ant-form-item-control {
          justify-content: flex-end;
          flex-direction: row;
        }
      }
    `}
  ${({ minHeight }) =>
    minHeight &&
    css`
      .ant-form-item-control-input {
        min-height: ${minHeight}px;
      }
    `}
`;

const FormItem: FC<Props> = props => {
  const { labelWidth, ...restProps } = props;

  return <StyledItem $labelWidth={labelWidth} {...restProps} />;
};

export default FormItem;
