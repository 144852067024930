import { useAuth } from 'auth';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { useTranslation } from 'react-i18next';

interface IUserRole {
  userRole: string;
  userType: string;
  userRoleTranslation: string | undefined;
  userRoleTranslationWithAccauntType: string | undefined;
  isAdmin: boolean;
  isManufacturer: boolean;
  isVendor: boolean;
  isWholesaler: boolean;
  isCraftsman: boolean;
  isArchitect: boolean;
}

const INITIAL_VALUES: IUserRole = {
  userRole: '',
  userType: '',
  userRoleTranslation: '',
  userRoleTranslationWithAccauntType: '',
  isAdmin: false,
  isManufacturer: false,
  isVendor: false,
  isWholesaler: false,
  isCraftsman: false,
  isArchitect: false
};

const UserRole = (): IUserRole => {
  const { t } = useTranslation('common');
  const user = useAuth(state => state.user);

  if (!user) return INITIAL_VALUES;

  const getUserRoleFromAuth = () => {
    if (user.wholesaler) {
      return 'wholesaler';
    }

    if (user.roles.name.length === 1) {
      return user.roles.name[0];
    }

    return user.roles.name[1];
  };

  const isAdmin = getUserRoleFromAuth() === AUTH_ROLES_ENUM.ADMIN;
  const isManufacturer = getUserRoleFromAuth() === AUTH_ROLES_ENUM.MANUFACTURE;
  const isVendor = getUserRoleFromAuth() === AUTH_ROLES_ENUM.VENDOR;
  const isWholesaler = getUserRoleFromAuth() === AUTH_ROLES_ENUM.WHOLESALER;
  const isCraftsman = getUserRoleFromAuth() === AUTH_ROLES_ENUM.CRAFTSMAN;
  const isArchitect = getUserRoleFromAuth() === AUTH_ROLES_ENUM.ARCHITECT;
  const userType = user.roles.name[2] || null;

  const getUserRoleTranslation = (withAccauntType: boolean) => {
    if (isAdmin) {
      return t('user_role.admin');
    }

    if (isManufacturer) {
      return t('user_role.manufacturer');
    }

    if (withAccauntType) {
      if (isVendor) {
        return t('user_role.vendor');
      }

      if (isWholesaler) {
        return t('user_role.vendor_wholesaler2');
      }

      if (isCraftsman) {
        return userType === AUTH_ROLES_ENUM.ACCOUNT_BASIC
          ? t('user_role.craftsman_basic')
          : t('user_role.craftsman_pro');
      }

      if (isArchitect) {
        return userType === AUTH_ROLES_ENUM.ACCOUNT_BASIC
          ? t('user_role.architect_basic')
          : t('user_role.architect_pro');
      }
    } else {
      if (isVendor) {
        return t('user_role.vendor');
      }

      if (isWholesaler) {
        return t('user_role.wholesaler');
      }

      if (isCraftsman) {
        return t('user_role.craftsman');
      }

      if (isArchitect) {
        return t('user_role.architect');
      }
    }
  };

  return {
    userRole: getUserRoleFromAuth(),
    userType: user.roles.name[2] || null,
    userRoleTranslation: getUserRoleTranslation(false),
    userRoleTranslationWithAccauntType: getUserRoleTranslation(true),
    isAdmin,
    isManufacturer,
    isVendor,
    isWholesaler,
    isCraftsman,
    isArchitect
  };
};

export default UserRole;
