import React, { FC } from 'react';
import { Space, Image } from 'antd';

import { Paragraph } from '..';

interface Props {
  image?: string;
  title: string;
  subtitles?: string[];
  href?: string;
  preview?: boolean;
  ellipsisLine?: number;
  copyable?: boolean;
}

const TableImage: FC<Props> = ({
  image,
  title,
  subtitles,
  href,
  preview = false,
  ellipsisLine = 3,
  copyable = false
}) => (
  <Space>
    {!!image && (
      <Image
        src={image}
        width={48}
        height={48}
        preview={preview}
        style={{ borderRadius: 16 }}
        wrapperClassName="antd-image-relative"
      />
    )}
    <div>
      <Paragraph
        color="text-color"
        lines={ellipsisLine}
        style={{ wordBreak: 'break-word' }}
        paragraphProps={{
          ellipsis: { tooltip: title, rows: ellipsisLine },
          copyable: copyable && {
            text: title,
            tooltips: false,
            format: 'text/plain'
          }
        }}
      >
        {href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {title}
          </a>
        ) : (
          title
        )}
      </Paragraph>

      {subtitles?.map((item, i) => (
        <Paragraph
          key={`title-item-${i}`}
          color="text-color"
          style={{ fontSize: 13, wordBreak: 'break-word' }}
        >
          {item}
        </Paragraph>
      ))}
    </div>
  </Space>
);

export default TableImage;
