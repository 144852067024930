import { useMemo } from 'react';
import { manufacturers_manufacturers_data as ManufacturerType } from '__generated__/manufacturers';

const useManufacturersOptions = (manufacturers: ManufacturerType[]) => {
  const manufacturersCopy = JSON.parse(JSON.stringify(manufacturers));

  const manufacturersData = useMemo(() => {
    const manufacturersAlphabetically = manufacturersCopy.sort(
      (a: ManufacturerType, b: ManufacturerType) =>
        a.company_name
          .trim()
          .toLowerCase()
          .localeCompare(b.company_name.trim().toLowerCase())
    );

    return manufacturersAlphabetically?.map((item: ManufacturerType) => ({
      value: item?.id || '',
      label: item?.company_name || ''
    }));
  }, [manufacturersCopy]);

  return manufacturersData;
};

export default useManufacturersOptions;
