import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import storageApi from 'services/storage';
import { PageSetting } from 'types/pageSettings';
import defaultSettings from 'configs/settingsConfig';
import defaultNavigationConfig from 'configs/navigationConfig';
import { merge } from 'lodash';
import { NavigationType } from 'types/navigation';

type Lng = 'en' | 'de';

type State = {
  language: Lng;
  defaultSetings: PageSetting;
  settings: PageSetting;
  navigation: NavigationType[];
};

type Actions = {
  setLanguage: (lng: Lng) => void;
  setSettings: (settings: Partial<PageSetting>) => void;
  setNavigation: (navigation: NavigationType[]) => void;
  reset: () => void;
};

type Store = State & Actions;

const initialState: State = {
  language: 'de',
  defaultSetings: defaultSettings,
  settings: defaultSettings,
  navigation: defaultNavigationConfig
};

const useSettings = create<Store>()(
  persist(
    (set, get) => ({
      ...initialState,
      setLanguage: (language: Lng) => set({ language }),
      setSettings: (settings: Partial<PageSetting>) =>
        set({ settings: merge({}, get().defaultSetings, settings) }),
      setNavigation: (navigation: NavigationType[]) => set({ navigation }),
      reset: () => set(initialState)
    }),
    {
      name: 'settings',
      storage: createJSONStorage(() => storageApi),
      partialize(state) {
        return { language: state.language };
      }
    }
  )
);

export default useSettings;
