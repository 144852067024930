import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Star: FC<Props> = props => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M11.3046 3.87288L7.90418 3.37869L6.38409 0.296992C6.34257 0.212617 6.27426 0.144313 6.18989 0.102795C5.97828 -0.00166915 5.72114 0.0853844 5.61534 0.296992L4.09525 3.37869L0.694801 3.87288C0.601051 3.88628 0.515336 3.93047 0.449711 3.99744C0.370374 4.07898 0.326656 4.18869 0.328163 4.30245C0.329669 4.41621 0.376278 4.52472 0.457747 4.60413L2.91801 7.00279L2.33676 10.3898C2.32313 10.4686 2.33185 10.5497 2.36193 10.6238C2.39201 10.6978 2.44225 10.762 2.50695 10.809C2.57164 10.856 2.64821 10.8839 2.72797 10.8896C2.80773 10.8953 2.88748 10.8785 2.95819 10.8412L5.99971 9.24208L9.04123 10.8412C9.12426 10.8854 9.22069 10.9001 9.3131 10.884C9.54614 10.8439 9.70284 10.6229 9.66266 10.3898L9.08141 7.00279L11.5417 4.60413C11.6086 4.53851 11.6528 4.4528 11.6662 4.35905C11.7024 4.12467 11.539 3.90771 11.3046 3.87288ZM8.04614 6.66529L8.52962 9.48181L5.99971 8.15324L3.4698 9.48315L3.95328 6.66663L1.90685 4.6711L4.73542 4.25994L5.99971 1.69788L7.264 4.25994L10.0926 4.6711L8.04614 6.66529Z"
      fill="#D4B106"
    />
  </SVG>
);

export default Star;
