import { message } from 'antd';
import { MutationFunction } from '@apollo/client';
import { TFunction } from 'react-i18next';

import {
  ISubmitLevel2,
  TCurrentItemId,
  TGetMenuItems,
  TParentItemId
} from '../types';

const submitLevel2 = async (
  data: ISubmitLevel2,
  type: 'create' | 'update',
  parentItemId: TParentItemId,
  createMenuItem: MutationFunction,
  getCurrentMenuData: TGetMenuItems,
  updateMenuItem: MutationFunction,
  currentItemId: TCurrentItemId,
  t: TFunction
) => {
  const { name, mobile_image, seo_data, promo_block, brands, marketing } = data;

  const input = {
    level: 2,
    parent_id: parentItemId ? Number(parentItemId) : undefined,
    name,
    mobile_image: mobile_image || '',
    seo_data: {
      title: seo_data.title || undefined,
      description: seo_data.description || undefined,
      meteKeywords: seo_data.meteKeywords || []
    },
    image: promo_block.image || '',
    link: promo_block.link,
    promo_text: promo_block.promo_text,
    brands,
    marketing: {
      title: marketing.title,
      text: marketing.text
    }
  };

  if (type === 'create') {
    await createMenuItem({
      variables: {
        input
      }
    }).then(async () => {
      message.success(t('messages.item_saved', { ns: 'common' }));
      await getCurrentMenuData({
        variables: {
          id: parentItemId ? Number(parentItemId) : undefined
        }
      });
    });
  }

  if (type === 'update') {
    await updateMenuItem({
      variables: {
        id: Number(currentItemId),
        input
      }
    }).then(async () => {
      message.success(t('messages.item_saved', { ns: 'common' }));
      await getCurrentMenuData({
        variables: {
          id: parentItemId ? Number(parentItemId) : undefined
        }
      });
    });
  }
};

export default submitLevel2;
