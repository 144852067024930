import React from 'react';

import Login from './index';

const LoginConfig = {
  settings: {
    layout: {
      navigation: {
        display: false
      }
    }
  },
  auth: [],
  routes: [
    {
      path: '/login',
      element: <Login />
    }
  ]
};

export default LoginConfig;
