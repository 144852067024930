import React from 'react';
import { StUtils } from '@stylique/utils';
import { Navigate } from 'react-router-dom';

import pagesConfigs from '../pages/pagesConfigs';

const routeConfigs: any = [...pagesConfigs];

const routes = [
  ...StUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '*',
    element: <Navigate to="/" />
  }
];

export default routes;
