import React, { FC, useState, useEffect } from 'react';
import { Col, Row, Space, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { attributes_attributes_data_results as AttributeType } from '__generated__/attributes';
import AntSelect, { SelectValue } from 'antd/lib/select';
import { useLazyQuery } from '@apollo/client';
import { attributeOptions_attributeOptions_data } from '__generated__/attributeOptions';
import { Paragraph, Select, FormItem } from '@stylique/core';
import { getImageUrl } from '@stylique/tools';
import {
  useProductDispatchContext,
  useProductStateContext
} from 'providers/Product';
import { GET_ATTRIBUTE_OPTIONS } from 'graphql/attributes/queries';

import TransparentButton from 'components/TransparentButton';

const { Option } = AntSelect;

interface Item extends AttributeType {
  disabled: boolean;
}

interface Props {
  name: number;
  fieldKey?: number;
  index: number;
  remove: (index: number) => void;
  canRemove: boolean;
  data: Item[];
}

const Container = styled.div`
  padding-bottom: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--gray-6);
  position: relative;
`;

const Color = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const OptionItem: FC<Props> = ({ remove, index, data, canRemove, name }) => {
  const { t } = useTranslation('admin');
  const { setAttributes, removeAttribute, deleteVariationByOption } =
    useProductDispatchContext();

  const { attributes, isAutomatic } = useProductStateContext();
  const [selected, setSelected] = useState<AttributeType | undefined>(
    undefined
  );

  const [
    getOptions,
    { data: { attributeOptions: { data: attributeOptions = [] } = {} } = {} }
  ] = useLazyQuery(GET_ATTRIBUTE_OPTIONS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (attributes[index]) {
      setSelected(
        data.find(attribute => attribute.id === attributes[index].attribute)
      );
    }
  }, [attributes, data, index]);

  const onAttributeSelect = (id: SelectValue) => {
    setAttributes({
      attribute: id as string,
      index,
      values: []
    });
    setSelected(data.find(attribute => attribute.id === id));
  };

  const onOptionSelect = (values: SelectValue) => {
    if (selected) {
      setAttributes({
        attribute: selected.id,
        index,
        values: values as string[]
      });
    }
  };

  const onOptionDeSelect = (value: unknown) =>
    !isAutomatic && deleteVariationByOption(value as string);

  const onDeleteOptions = () => {
    !isAutomatic && deleteVariationByOption(attributes[index]?.values);
  };

  const onRemove = () => {
    if (!isAutomatic) {
      deleteVariationByOption(attributes[index]?.values);
    }

    removeAttribute(index);
    remove(index);
  };

  useEffect(() => {
    if (selected) {
      getOptions({ variables: { id: selected.id } });
    }
  }, [getOptions, selected]);

  return (
    <Container>
      <Paragraph color="text-color" style={{ marginBottom: 16 }}>
        {t('variations.option')} {index + 1}
      </Paragraph>
      <Row gutter={[8, 0]}>
        <Col sm={6}>
          <FormItem
            name={[name, 'attribute']}
            label={t('variations.attribute')}
          >
            <Select onChange={onAttributeSelect} size="large" bordered>
              {data.map((item: Item) => (
                <Option
                  key={`attribute-${item.id}`}
                  value={item.id}
                  disabled={item.disabled}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col sm={18}>
          <FormItem
            name={[name, 'values']}
            label={t('values', { ns: 'common' })}
          >
            <Select
              mode="multiple"
              onChange={onOptionSelect}
              onDeselect={onOptionDeSelect}
              onClear={onDeleteOptions}
              value={attributes[index]?.values || []}
              optionFilterProp="name"
              size="large"
              allowClear
              bordered
            >
              {attributeOptions.map(
                (item: attributeOptions_attributeOptions_data) => (
                  <Option
                    key={`attribute-option-${item.id}`}
                    value={item.id}
                    name={item.name}
                  >
                    <Space>
                      {!!item.value && item.switchType === 'color' && (
                        <Color color={item.value} />
                      )}
                      {!!item.value && item.switchType === 'image' && (
                        <Image
                          src={getImageUrl(item.value)}
                          width={24}
                          height={24}
                          preview={false}
                          style={{ borderRadius: 8 }}
                        />
                      )}
                      {item.name}
                    </Space>
                  </Option>
                )
              )}
            </Select>
          </FormItem>
        </Col>
      </Row>
      {canRemove && (
        <TransparentButton
          style={{ position: 'absolute', right: 0, top: 40, zIndex: 2 }}
          onClick={onRemove}
        >
          <Paragraph color="red-base" style={{ fontSize: 14 }}>
            {t('remove', { ns: 'common' })}
          </Paragraph>
        </TransparentButton>
      )}
    </Container>
  );
};

export default OptionItem;
