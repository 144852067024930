import { gql } from '@apollo/client';

import { MANUFACTURES_FRAGMENT } from './fragments';

export const GET_MANUFACTURER_BY_KEY = gql`
  query manufacturersKey($key: String!) {
    manufacturersKey(key: $key) {
      data {
        ...ManufacturerFragment
      }
      message
      status
    }
  }
  ${MANUFACTURES_FRAGMENT}
`;
