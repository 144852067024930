import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Edit: FC<Props> = props => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="#D7D8DB"
    />
    <path
      d="M7.33325 15.9996H10.1599C10.2477 16.0001 10.3346 15.9833 10.4159 15.9501C10.4971 15.917 10.571 15.8681 10.6333 15.8063L15.2466 11.1863L17.1399 9.33297C17.2024 9.27099 17.252 9.19726 17.2858 9.11602C17.3197 9.03478 17.3371 8.94764 17.3371 8.85963C17.3371 8.77163 17.3197 8.68449 17.2858 8.60325C17.252 8.52201 17.2024 8.44828 17.1399 8.3863L14.3133 5.5263C14.2513 5.46381 14.1775 5.41422 14.0963 5.38037C14.0151 5.34653 13.9279 5.3291 13.8399 5.3291C13.7519 5.3291 13.6648 5.34653 13.5835 5.38037C13.5023 5.41422 13.4286 5.46381 13.3666 5.5263L11.4866 7.41297L6.85992 12.033C6.79813 12.0953 6.74925 12.1691 6.71607 12.2504C6.68289 12.3316 6.66608 12.4186 6.66659 12.5063V15.333C6.66659 15.5098 6.73682 15.6793 6.86185 15.8044C6.98687 15.9294 7.15644 15.9996 7.33325 15.9996ZM13.8399 6.93963L15.7266 8.8263L14.7799 9.77297L12.8933 7.8863L13.8399 6.93963ZM7.99992 12.7796L11.9533 8.8263L13.8399 10.713L9.88659 14.6663H7.99992V12.7796ZM17.9999 17.333H5.99992C5.82311 17.333 5.65354 17.4032 5.52851 17.5282C5.40349 17.6533 5.33325 17.8228 5.33325 17.9996C5.33325 18.1764 5.40349 18.346 5.52851 18.471C5.65354 18.5961 5.82311 18.6663 5.99992 18.6663H17.9999C18.1767 18.6663 18.3463 18.5961 18.4713 18.471C18.5963 18.346 18.6666 18.1764 18.6666 17.9996C18.6666 17.8228 18.5963 17.6533 18.4713 17.5282C18.3463 17.4032 18.1767 17.333 17.9999 17.333Z"
      fill="#3A404A"
    />
  </SVG>
);

export default Edit;
