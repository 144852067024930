import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@stylique/core';

import SectionContainer from './SectionContainer';
import Tabs from './Tabs';

const SingleVariation: FC = () => {
  const { t } = useTranslation('admin');

  return (
    <SectionContainer>
      <Paragraph color="text-color" style={{ marginBottom: 24 }}>
        {t('variations.properties')}
      </Paragraph>
      <Tabs />
    </SectionContainer>
  );
};

export default SingleVariation;
