import React, { FC } from 'react';
import { useAuth } from 'auth';
import AUTH_ROLES_ENUM from 'auth/authRoles';

const AdminDashboard = React.lazy(() => import('./AdminDashboard'));
const VendorDashboard = React.lazy(() => import('./VendorDashboard'));
const ManufacturerDashboard = React.lazy(
  () => import('./ManufacturerDashboard')
);

const dashboards = {
  [AUTH_ROLES_ENUM.ADMIN]: <AdminDashboard />,
  [AUTH_ROLES_ENUM.VENDOR]: <VendorDashboard />,
  [AUTH_ROLES_ENUM.MANUFACTURE]: <ManufacturerDashboard />
};

const Dashboard: FC = () => {
  const adminRole = useAuth(state => state.adminRole);

  return dashboards[adminRole];
};

export default Dashboard;
