import { gql } from '@apollo/client';

export const GET_MENU_ITEMS = gql`
  query getMenuItems($id: Int) {
    getMenuItems(id: $id) {
      data {
        id
        name
        mobile_image
        image
        parent_id
        image
        link
        promo_text
        isPublished
        sortIndex
        brands {
          slug
          image
        }
        seo_data {
          title
          description
          meteKeywords
        }
        marketing {
          title
          text
        }
      }
      message
      status
    }
  }
`;

export const GET_MENU_ITEM = gql`
  query getMenuItem($id: Int) {
    getMenuItem(id: $id) {
      data {
        id
        name
        level
        parent_id
        image
        mobile_image
        link
        promo_text
        isPublished
        sortIndex
        marketing {
          title
          text
        }
        products {
          id
          sku
        }
        attributes_value_groups {
          id
          menu_item_id
          group_id
          data {
            id
            attr_id
            name
          }
        }
        brands {
          slug
          image
        }
        seo_data {
          title
          description
          meteKeywords
        }
        manufacturers {
          id
          manufacturer_id
        }
        tags {
          id
          tag_id
        }
        categories {
          id
          cat_id
        }
      }
      message
      status
    }
  }
`;
