import React, { FC } from 'react';
import styled, { CSSProperties, css } from 'styled-components';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';

const Paragraph = Typography.Paragraph;

interface Props {
  strong?: boolean;
  children: React.ReactNode | string;
  style?: CSSProperties;
  paragraphProps?: ParagraphProps;
  color?: string;
  lines?: number;
}

const StyledParagraph = styled(Paragraph)<{
  strong?: boolean;
  color?: string;
  lines?: number;
}>`
  ${({ strong }) => strong && `strong { font-weight: bold; }`};
  font-size: 16px;
  line-height: 1.54;
  letter-spacing: 0.1px;
  color: var(--${({ color = 'text-color-2' }) => color});
  && {
    margin-bottom: 0;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ lines }) =>
    lines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
    `}
`;

const CustomParagraph: FC<Props> = ({
  strong,
  children,
  style,
  paragraphProps = {},
  color,
  lines
}) => {
  return (
    <StyledParagraph
      strong={strong}
      style={style}
      color={color}
      lines={lines}
      {...paragraphProps}
    >
      {children}
    </StyledParagraph>
  );
};

export default CustomParagraph;
