/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable padding-line-between-statements */
/* eslint-disable prettier/prettier */
import {
  styleById_styleById_data_affiliates_products as AffiliateProductType,
  styleById_styleById_data_products_product as StyleProduct
} from '__generated__/styleById';

import { ColaboratorType } from 'pages/Styles/types';

// TODO: move to typescript project
type ValueOf<T> = T[keyof T];

export const ActionTypes = {
  LINK_PRODUCT: 'LINK_PRODUCT',
  UNLINK_PRODUCT: 'UNLINK_PRODUCT',
  UNLINK_AFFILIATE_PRODUCT: 'UNLINK_AFFILIATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_AFFILIATE_PRODUCT: 'UPDATE_AFFILIATE_PRODUCT',
  ADD_IMAGE: 'ADD_IMAGE',
  SET_MAIN_IMAGE: 'SET_MAIN_IMAGE',
  UPDATE_IMAGE: 'UPDATE_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  SET_IMAGE_POPUP: 'SET_IMAGE_POPUP',
  SET_STYLE_VALUES: 'SET_STYLE_VALUES',
  SET_VALUES: 'SET_VALUES',
  ADD_NETWORK: 'ADD_NETWORK',
  UPDATE_NETWORK: 'UPDATE_NETWORK',
  DELETE_NETWORK: 'DELETE_NETWORK',
  SET_STATUS: 'SET_STATUS',
  SET_IMAGE_TO_EDIT: 'SET_IMAGE_TO_EDIT',
  EDIT_IMAGE: 'EDIT_IMAGE',
  SET_SEARCH_BY_NAME: 'SET_SEARCH_BY_NAME',
  SET_SEARCH_BY_SKU: 'SET_SEARCH_BY_SKU',
  SET_SEARCH_BY_STATUS: 'SET_SEARCH_BY_STATUS',
  SET_LINK_TYPE_CLICKEDS: 'SET_LINK_TYPE_CLICKEDS',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP'
};

export interface ProductItem extends Omit<StyleProduct, 'type'> {
  type: 1 | 2;
}

export interface AffiliateProductItem
  extends Omit<AffiliateProductType, 'type'> {
  type: 1 | 2;
}

export type ImageItem = {
  filename: string;
  originName?: string;
};

export type ImageItemUpdate = {
  filename?: string;
  originName?: string;
};

export type NetworkInputs = {
  name?: string;
  link?: string;
};

export type StyleStateInputs = {
  title?: string;
  slug?: string;
  description?: string;
  short_desc?: string;
  tags?: number[];
  networks?: NetworkInputs[];
  collaborators: ColaboratorType[];
  status?: number;
  architect_id?: number;
  architect_id_real?: number;
  creator_id?: number;
  creator_type?: string;
};

export type Action = {
  type: ValueOf<typeof ActionTypes>;
  data?:
    | { index: number; data: ImageItemUpdate }
    | { index: number; data: ProductItem }
    | { index: string; data: AffiliateProductItem }
    | { index: number; data: NetworkInputs }
    | { id: number; data: number }
    | ImageItem
    | ProductItem
    | AffiliateProductItem
    | StyleStateInputs
    | State
    | number
    | string
    | boolean
    | NetworkInputs;
};

export type State = {
  images: ImageItem[];
  products: ProductItem[];
  affiliates_products: AffiliateProductItem[];
  isImagePopupVisible: boolean;
  inputs: StyleStateInputs;
  imageToEdit: number;
  searchByName?: string | undefined | any;
  searchBySku?: string | undefined | any;
  searchByStatus?: 'all' | 'linked' | 'notLinked' | any;
  linkTypeClickeds: any;
  //
  currentStep: number;
};

export type DispatchContext = {
  addImage: (data: ImageItem) => void;
  editImage: (data: ImageItem) => void;
  updateImage: (index: number, data: ImageItemUpdate) => void;
  deleteImage: (index: number) => void;
  linkProduct: (data: ProductItem) => void;
  updateProduct: (index: number, type: number) => void;
  updateAffiliateProducts: (index: string, type: string) => void;
  unlinkProduct: (productId: number) => void;
  unlinkAffiliateProduct: (productId: string) => void;
  setImagePopup: (data: boolean) => void;
  setStyleValues: (data: StyleStateInputs) => void;
  setValues: (data: State) => void;
  networkChanged: (data: (NetworkInputs | undefined)[]) => void;
  deleteNetwork: (index: number) => void;
  setStatus: (data: number) => void;
  setImageToEdit: (data: number) => void;
  setSearchByName: (data: string) => void;
  setSearchBySku: (data: string) => void;
  setSearchByStatus: (data: string) => void;
  setLinkTypeClickeds: (data: any) => void;
  setMainImage: (data: ImageItem[]) => void;
  //
  setCurrentStep: (step: number) => void;
};

export type Dispatch = (action: Action) => void;
