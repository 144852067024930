import { gql } from '@apollo/client';

import { PRODUCT_FRAGMENT, PRODUCTS_LIST_FRAGMENT } from './fragments';

export const UPLOAD_AVALABILATY = gql`
  query uploadAvalabilaty {
    uploadAvalabilaty {
      data
      message
      status
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($query: SearchProductsInput) {
    products(query: $query) {
      data {
        total
        results {
          ...ProductListFragment
        }
      }
      message
      status
    }
  }
  ${PRODUCTS_LIST_FRAGMENT}
`;

export const GET_PRODUCT_BY_ID = gql`
  query productById($id: ID!) {
    productById(id: $id) {
      data {
        ...ProductFragment
      }
      message
      status
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_LINKED_PRODUCTS = gql`
  query getSampleBoxProducts($id: ID!) {
    getSampleBoxProducts(id: $id) {
      data {
        id
        parent_id
        sample_box_id
        data {
          id
          name
          sku
          parent {
            id
            manufacturer {
              id
              company_name
            }
          }
          images {
            id
            filename
            main
          }
        }
      }
      message
      status
    }
  }
`;
