import React from 'react';

import Verify from './index';

const VerifyVendorConfig = {
  settings: {
    layout: {
      navigation: {
        display: false
      }
    }
  },
  auth: [],
  routes: [
    {
      path: '/vendor/:key',
      element: <Verify />
    }
  ]
};

export default VerifyVendorConfig;
