import React, { useCallback, useMemo } from 'react';
import { Badge, Dropdown, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Paragraph, TableImage } from '@stylique/core';
import {
  variations_variations_data_results as VariationType,
  variations_variations_data_results_combinations as CombinationType,
  variations_variations_data_results_category as CategoryType,
  variations_variations_data_results_parent as ParentType
} from '__generated__/variations';
import { getImageUrl } from '@stylique/tools';
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  LinkOutlined,
  MoreOutlined,
  PictureOutlined,
  SendOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useAuth } from 'auth';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { UserResponse } from 'auth/types';

import { genUserType } from 'pages/ParentsProducts/utils';

const useVariationsTable = (
  setIsVisibleServicesPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setVariationId: React.Dispatch<React.SetStateAction<number | null>>,
  setAddImagePopup: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      data: null;
    }>
  >,
  updateProductVariationsStatus: any,
  fetchData: () => Promise<void>,
  deletePressed: (id: number) => void,
  isLinkingProducts: boolean,
  linked: number[],
  handleLinkProduct: (parentId: number) => void
) => {
  const { t } = useTranslation('admin');
  const user = useAuth(state => state.user);
  const userType = useMemo(() => genUserType(user as UserResponse), [user]);
  const navigate = useNavigate();
  const { search } = useLocation();

  const renderActions = useCallback(
    record => {
      const items = [
        {
          keys: [AUTH_ROLES_ENUM.ADMIN],
          action: {
            key: 'settings',
            label: (
              <Space>
                <SettingOutlined />
                {t('settings', { ns: 'common' })}
              </Space>
            )
          }
        },
        {
          keys: [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE],
          action: {
            key: 'edit',
            label: (
              <Space>
                <EditOutlined />
                {t('edit', { ns: 'common' })}
              </Space>
            )
          }
        },
        {
          keys: [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE],
          action: {
            key: 'addImage',
            label: (
              <Space>
                <PictureOutlined />
                {t('styles.add_image', { ns: 'admin' })}
              </Space>
            )
          }
        },
        {
          keys:
            record.status === '2'
              ? [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE]
              : [],
          action: {
            key: 'publish',
            label: (
              <Space>
                <SendOutlined />
                {t('publish', { ns: 'common' })}
              </Space>
            )
          }
        },
        {
          keys:
            record.status === '1'
              ? [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE]
              : [],
          action: {
            key: 'draft',
            label: (
              <Space>
                <FileOutlined />
                {t('set_draft', { ns: 'common' })}
              </Space>
            )
          }
        },
        {
          keys: [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE],
          action: {
            key: 'delete',
            label: (
              <Space>
                <DeleteOutlined />
                {t('delete', { ns: 'common' })}
              </Space>
            )
          }
        }
      ]
        .filter(item => item.keys.includes(userType as AUTH_ROLES_ENUM))
        .map(item => item.action);

      return {
        items,
        onClick: async ({ key }: { key: string }) => {
          const productId = record.id;

          if (key === 'settings') {
            setIsVisibleServicesPopup(true);
            setVariationId(record?.id);
          }

          if (key === 'edit') {
            navigate(
              `/products/variations/details/${productId}?back=${search.replace(
                /&/g,
                'AND'
              )}`
            );
          }

          if (key === 'addImage') {
            setAddImagePopup({
              isVisible: true,
              data: record
            });
          }

          if (key === 'publish') {
            await updateProductVariationsStatus({
              variables: {
                input: {
                  ids: [productId],
                  status: '1'
                }
              }
            }).then(() => {
              message.success(
                t('messages.successfully_product_published', { ns: 'common' })
              );

              fetchData();
            });
          }

          if (key === 'draft') {
            await updateProductVariationsStatus({
              variables: {
                input: {
                  ids: [productId],
                  status: '2'
                }
              }
            }).then(() => {
              message.success(
                t('messages.successfully_product_set_draft', { ns: 'common' })
              );

              fetchData();
            });
          }

          if (key === 'delete' && record.id) {
            deletePressed(record.id);
          }
        }
      };
    },
    [
      deletePressed,
      fetchData,
      navigate,
      search,
      setAddImagePopup,
      setIsVisibleServicesPopup,
      setVariationId,
      t,
      updateProductVariationsStatus,
      userType
    ]
  );

  const columnsByType = useMemo(() => {
    if (isLinkingProducts) {
      return [
        {
          title: t('products.status'),
          dataIndex: 'status',
          key: 'status',
          width: '15%',
          render: (_: unknown, record: VariationType) => {
            const isLinked = record.id && linked.includes(record.id);

            return (
              <Badge
                status={isLinked ? 'success' : 'warning'}
                text={
                  isLinked ? t('products.linked') : t('products.not_linked')
                }
              />
            );
          }
        }
      ];
    }

    return [
      {
        title: t('products.parent'),
        dataIndex: 'parent',
        key: 'parent',
        width: '15%',
        render: (parent: ParentType | null) => (
          <Paragraph
            paragraphProps={{
              ellipsis: { tooltip: parent?.name, rows: 1 },
              copyable: {
                text: parent?.name,
                tooltips: false,
                format: 'text/plain'
              }
            }}
          >
            <Link
              to={`/products/parents/details/${parent?.id}`}
              style={{ color: 'var(--blue-color-1)' }}
            >
              {parent?.name}
            </Link>
          </Paragraph>
        )
      },
      {
        title: t('products.status'),
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        render: (status: '1' | '2') => (
          <Badge
            status={status === '1' ? 'success' : 'error'}
            text={
              status === '1'
                ? t('publish', { ns: 'common' })
                : t('draft', { ns: 'common' })
            }
          />
        )
      }
    ];
  }, [isLinkingProducts, linked, t]);

  const columns: ColumnProps<VariationType>[] = [
    {
      title: t('products.product_name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (name: string, record: VariationType) => (
        <TableImage
          image={getImageUrl(record?.image?.filename, 150)}
          title={name}
        />
      )
    },
    {
      title: t('products.sku'),
      dataIndex: 'sku',
      key: 'sku',
      width: '15%',
      render(value) {
        return (
          <Paragraph
            paragraphProps={{
              ellipsis: { tooltip: value, rows: 1 },
              copyable: {
                text: value,
                tooltips: false,
                format: 'text/plain'
              }
            }}
            style={{
              color: 'var(--gray-1)'
            }}
          >
            {value}
          </Paragraph>
        );
      }
    },
    {
      title: t('products.combinations'),
      dataIndex: 'combinations',
      key: 'combinations',
      width: '15%',
      render: (combinations: CombinationType[]) =>
        combinations?.reduce((acc, val): string => {
          if (!acc) {
            return val.option?.name || '';
          }

          return `${acc}/${val.option?.name}`;
        }, '')
    },
    {
      title: t('products.category'),
      dataIndex: 'category',
      key: 'category',
      width: '15%',
      render: (category: CategoryType[]) =>
        category?.map(item => item.data?.name).join(', ')
    },
    ...columnsByType,
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      align: 'right',
      render: (_: unknown, record: VariationType) =>
        isLinkingProducts ? (
          <Button
            size="small"
            type={
              record.id && linked.includes(record.id) ? 'primary' : 'default'
            }
            style={{ height: 32, borderColor: 'var(--primary-color)' }}
            onClick={() => record.id && handleLinkProduct(record.id)}
          >
            <LinkOutlined
              style={{
                color:
                  record.id && linked.includes(record.id)
                    ? 'var(--white)'
                    : 'var(--primary-color)'
              }}
            />
          </Button>
        ) : (
          <Dropdown menu={renderActions(record)}>
            <a onClick={e => e.preventDefault()}>
              <MoreOutlined />
            </a>
          </Dropdown>
        )
    }
  ];

  return columns;
};

export default useVariationsTable;
