import React, { RefObject, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { FormItem } from '@stylique/core';
import { Checkbox, Form, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { GET_CATEGORIES_WITH_SUBS } from 'graphql/categories/queries';

import { formatThreeSelectCategories } from 'utils/helpers';

const ByCategory = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const form = Form.useFormInstance();
  const isChecked = Form.useWatch('categoriesCheckbox', form);

  const {
    data: { categories: { data: categories = [] } = {} } = {},
    loading: isCategories
  } = useQuery(GET_CATEGORIES_WITH_SUBS, {
    fetchPolicy: 'no-cache'
  });

  return (
    <div ref={parentRef}>
      <FormItem valuePropName="checked" name="categoriesCheckbox">
        <Checkbox style={{ fontSize: '14px' }}>
          {t('website_menu.by_category')}
        </Checkbox>
      </FormItem>
      {isChecked ? (
        <FormItem name="categories">
          <TreeSelect
            treeData={formatThreeSelectCategories(categories)}
            placeholder={t('website_menu.by_category_placeholder')}
            treeNodeFilterProp="title"
            size="large"
            multiple
            bordered
            allowClear
            loading={isCategories}
            style={{ fontSize: '14px' }}
            getPopupContainer={() => parentRef.current as HTMLElement}
          />
        </FormItem>
      ) : null}
    </div>
  );
};

export default ByCategory;
