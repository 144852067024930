import React, { FC } from 'react';
import { Button } from '@stylique/core';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { StyledSectionTitle } from '../styles';
import { ISectionHeaderProps } from '../types';

const SectionHeader: FC<ISectionHeaderProps> = ({
  createHandler,
  level,
  count
}) => {
  const { t } = useTranslation('admin');

  const levelTitles: { [key: string]: string } = {
    '2': t('website_menu.level2_title'),
    '3': t('website_menu.level3_title'),
    '4': t('website_menu.level4_title')
  };

  return (
    <Row
      align="middle"
      style={{ marginBottom: '24px' }}
      justify="space-between"
      wrap={false}
    >
      <StyledSectionTitle>
        {levelTitles[level]} <span className="count">({count})</span>
      </StyledSectionTitle>

      <Button type="primary" onClick={createHandler}>
        {t('create_new_item', { ns: 'common' })}
        <PlusOutlined />
      </Button>
    </Row>
  );
};

export default SectionHeader;
