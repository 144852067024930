import React, { FC, useState } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button, FormItem, Input, Paragraph } from '@stylique/core';
import { useAuth } from 'auth';
import { Link } from 'react-router-dom';
import AUTH_ROLES_ENUM from 'auth/authRoles';

import { Container, LoginWrap, Titles, Welcome } from './styles';
import loginMutations, { queryPaths } from './graphql/mutations';

const Login: FC = () => {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();
  const adminRole = useAuth(state => state.adminRole);
  const setData = useAuth(state => state.setData);
  const [unauthorizedUser, setUnauthorizedUser] = useState(false);

  const [login, { loading }] = useMutation(loginMutations[adminRole], {
    onCompleted(data) {
      const {
        [queryPaths[adminRole]]: {
          data: { token, user }
        }
      } = data;

      setUnauthorizedUser(false);

      setData({ token, user });
    },
    onError({ message: errMessage }) {
      const isUnauthorizedMessage = errMessage.includes('Unauthorized');
      setUnauthorizedUser(isUnauthorizedMessage);
      message.destroy();
    }
  });

  const onFinish = async (input: { email: string; password: string }) => {
    await login({
      variables: { input }
    });
  };

  return (
    <LoginWrap>
      <Container>
        <Titles>
          <Welcome>{t('login.title')}</Welcome>
          <Paragraph
            style={{ fontSize: '16px', marginBottom: '24px' }}
            color="gray-1"
          >
            {t('login.subTitle')}
          </Paragraph>
        </Titles>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem
            label={t('email_address')}
            name="email"
            validateStatus={unauthorizedUser ? 'error' : ''}
            rules={[
              { required: true },
              {
                type: 'email',
                message: t('valid_email')
              }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label={t('password')}
            name="password"
            rules={[{ required: true }]}
            style={{ marginBottom: '8px' }}
            validateStatus={unauthorizedUser ? 'error' : ''}
          >
            <Input type="password" />
          </FormItem>
          {unauthorizedUser && (
            <p
              style={{
                marginBottom: '0',
                color: 'var(--primary-color-2)',
                fontSize: '14px'
              }}
            >
              {t('forgot_password.wrong_email_or_pass')}
            </p>
          )}
          {(adminRole === AUTH_ROLES_ENUM.VENDOR ||
            adminRole === AUTH_ROLES_ENUM.MANUFACTURE) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '24px'
              }}
            >
              <Link
                to="/forgot-password"
                style={{ color: 'var(--gray-12)', fontSize: '14px' }}
              >
                {t('forgot_password.forgot_password')}
              </Link>
            </div>
          )}

          <Form.Item style={{ marginTop: 16 }}>
            <Button
              style={{
                background: 'var(--gray-10)',
                borderColor: 'var(--gray-10)'
              }}
              type="primary"
              htmlType="submit"
              block
              loading={loading}
            >
              {t('sign_in')}
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </LoginWrap>
  );
};

export default Login;
