import { gql } from '@apollo/client';

export const ATTRIBUTE_FRAGMENT = gql`
  fragment AttributeFragment on Attribute {
    id
    name
    slug
    type
    is_configurable
    is_dropdown
    is_editable
    is_filterable
    sortIndex
    is_required
  }
`;

export const ATTRIBUTE_OPTIONS_FRAGMENT = gql`
  fragment AttributeOptionsFragment on AttributeOptions {
    id
    name
    switchType
    description
    value
    attr_id
  }
`;
