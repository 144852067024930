import { gql } from '@apollo/client';

import { VENDOR_FRAGMENT } from './fragments';

export const GET_VENDOR_BY_KEY = gql`
  query vendorKey($key: String!) {
    vendorKey(key: $key) {
      data {
        ...VerifyVendorFragment
      }
      message
      status
    }
  }
  ${VENDOR_FRAGMENT}
`;
