import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';

import { Title, Button, Paragraph } from '..';

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
  min-height: 600px;
`;

const EmptyContainer = styled.div`
  max-width: 340px;
`;

interface Props {
  title: string;
  description?: string;
  image?: ReactNode;
  buttonText?: string;
  buttonClicked?: () => void;
}

const emptyData: FC<Props> = ({
  title,
  description,
  image,
  buttonText,
  buttonClicked
}) => {
  return (
    <EmptyWrapper>
      <EmptyContainer>
        <Empty
          image={image}
          description={
            <>
              <Title titleLevel={4}>{title}</Title>
              <Paragraph>{description}</Paragraph>
            </>
          }
        >
          {!!buttonText && (
            <Button
              type="ghost"
              style={{ marginTop: 36 }}
              onClick={buttonClicked}
            >
              {buttonText}
            </Button>
          )}
        </Empty>
      </EmptyContainer>
    </EmptyWrapper>
  );
};

export default emptyData;
