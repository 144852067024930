import { Input } from '@stylique/core';
import { Button, Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding: 24px;
  min-height: 600px;
`;

export const StyledSectionTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: #000000d9;
  margin-right: 10px;
  margin-bottom: 0;
  white-space: nowrap;
  & .count {
    color: #999999;
  }
`;

export const ModalTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  color: #191919;
  margin: 16px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormItemsTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  color: #191919;
  & span {
    color: #999999;
  }
`;

export const StyledImageLink = styled(Input)`
  & .ant-input-group-addon {
    border-radius: 2px;
    font-size: 14px;
  }
  & .ant-input {
    padding: 6px 12px;
  }
`;

export const BrandNameLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`;

export const AddBrandButton = styled(Button)`
  font-size: 14px;
  color: #e5324b;
  border-color: #e5324b;
`;

export const PageHeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  & .part1 {
    font-size: 24px;
    font-weight: 500;
  }
  & .part2 {
    font-size: 20px;
    font-weight: 500;
    color: #00000073;
  }
`;

export const StyledInfoText = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #00000073;
`;
