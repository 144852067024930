import { gql } from '@apollo/client';

export const MANUFACTURES_FRAGMENT = gql`
  fragment ManufacturerFragment on Manufacturer {
    id
    company_name
    status
    email
    phone
    avatar
  }
`;
