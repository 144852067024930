import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

const Title = Typography.Title;

interface Props extends TitleProps {
  titleLevel?: 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  children: React.ReactNode | string;
  space?: number;
  size?: number | null;
  style?: React.CSSProperties;
  fontWeight?: number;
  bold?: boolean;
  color?: string;
}

const StyledTitle = styled(Title).withConfig({
  shouldForwardProp: prop => !['space', 'fontWeight'].includes(prop)
})<{
  space?: number;
  bold?: boolean;
  fontWeight?: number;
  color?: string;
}>`
  &&& {
    margin-top: 0;
    margin-bottom: 8px;
    color: var(--${({ color }) => color});
    ${({ fontWeight }) =>
      fontWeight !== undefined &&
      css`
        font-weight: ${fontWeight};
      `}
  }
  ${({ space }) =>
    space !== undefined &&
    css`
      margin-bottom: ${space}px !important;
    `}
`;

const StyledTitleH6 = styled.h6<{
  bold?: boolean;
  space?: number;
  color?: string;
}>`
  && {
    font-size: 18px;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    line-height: 1.34;
    color: var(--${({ color }) => color});
    margin-bottom: 8px;
    ${({ space }) =>
      space !== undefined &&
      css`
        margin-bottom: ${space}px !important;
      `};
  }
`;

const CustomTitle: FC<Props> = ({
  titleLevel,
  children,
  space,
  fontWeight,
  bold,
  color,
  ...rest
}) => {
  if (titleLevel === 6) {
    return (
      <StyledTitleH6 bold={bold} space={space} color={color}>
        {children}
      </StyledTitleH6>
    );
  } else {
    return (
      <StyledTitle
        level={titleLevel}
        fontWeight={fontWeight}
        space={space}
        color={color}
        {...rest}
      >
        {children}
      </StyledTitle>
    );
  }
};

export default CustomTitle;
