import { PageSetting } from 'types/pageSettings';

const settingsConfig: PageSetting = {
  layout: {
    navigation: {
      display: true
    }
  },
  theme: {
    main: 'default',
    navbar: 'mainThemeDark'
  }
};

export default settingsConfig;
