import { gql } from '@apollo/client';

import { CLICK_COLLECT_FRAGMENT, SETTINGS_FRAGMENT } from './fragments';

export const GET_CLICK_COLLECT = gql`
  query getClickAndCollect {
    getClickAndCollect {
      data {
        id
        ...clickCollectFragment
      }
      message
      status
    }
  }
  ${CLICK_COLLECT_FRAGMENT}
`;

export const GET_SETTINGS = gql`
  query vendorSettings {
    vendorSettings {
      data {
        ...settingsFragment
      }
      message
      status
    }
  }
  ${SETTINGS_FRAGMENT}
`;
