import React, { FC } from 'react';
import { FormItem, Input } from '@stylique/core';
import { useTranslation } from 'react-i18next';

const MarketingTextTitle: FC = () => {
  const { t } = useTranslation('admin');

  return (
    <FormItem name={['marketing', 'title']} label="Marketing Text Title">
      <Input
        size="middle"
        style={{ fontSize: '16px', padding: '6px 12px' }}
        placeholder={t('e.g. Exklusive Tapeten von Designern entworfen')}
      />
    </FormItem>
  );
};

export default MarketingTextTitle;
