import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FormItem, Select } from '@stylique/core';
import { paginate } from '@stylique/tools';
import { tags_tags_data_results as TagType } from '__generated__/tags';
import { GET_TAGS } from 'graphql/tags/queries';

// TODO: make api call without pagination
const PAGE_SIZE = 1000000;

interface Props {
  type: 'style' | 'product';
  label: string | React.ReactElement;
  name: string;
  onChange?: (e: any) => void;
  required?: boolean;
  labelWidth?: string | number;
}

const TagSelect: FC<Props> = ({
  type,
  label,
  name,
  onChange,
  required = true,
  labelWidth
}) => {
  const [page, setPage] = useState(0);
  const {
    data: { tags: { data: { results = [] } = {} } = {} } = {},
    loading,
    fetchMore
  } = useQuery(GET_TAGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        type,
        ...paginate(PAGE_SIZE)
      }
    }
  });

  const isPagingStopped = useRef(false);
  const parentRef: any = useRef();

  useEffect(() => {
    if (page) {
      fetchMore({
        variables: {
          query: {
            type,
            ...paginate(PAGE_SIZE, page)
          }
        },
        updateQuery: (
          prev,
          {
            fetchMoreResult: {
              tags: {
                data: { results: moreData }
              }
            }
          }
        ) => {
          // if last page data stop paging
          if (moreData.length < PAGE_SIZE) {
            isPagingStopped.current = true;
          }

          // if there is no more data return previous
          if (!moreData.length) return prev;

          // merge new and previous data
          const updated = {
            ...prev,
            tags: {
              ...prev.tags,
              data: {
                ...prev.tags.data,
                results: [...prev.tags.data.results, ...moreData]
              }
            }
          };

          return updated;
        }
      });
    }
  }, [page, fetchMore, type]);

  const onPopupScroll = useCallback(
    e => {
      e.persist();
      const target = e.target;

      if (
        !isPagingStopped.current &&
        !loading &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        setPage(page => page + 1);
      }
    },
    [loading]
  );

  return (
    <div ref={parentRef}>
      <FormItem
        label={label}
        name={name}
        rules={[{ required: required }]}
        labelWidth={labelWidth}
      >
        <Select
          getPopupContainer={() => parentRef.current}
          options={results.map((item: TagType) => ({
            label: item.name,
            value: item.id
          }))}
          placeholder="Select tags"
          mode="multiple"
          optionFilterProp="label"
          size="large"
          onPopupScroll={onPopupScroll}
          allowClear
          bordered
          onChange={onChange}
        />
      </FormItem>
    </div>
  );
};

export default TagSelect;
