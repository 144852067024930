import React, { FC } from 'react';
import { Select as CSelect } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import styled from 'styled-components';

const StyledSelect = styled(CSelect)`
  font-size: 16px;
  &.ant-select-multiple .ant-select-selector {
    min-height: 39px;
    padding: 1px 12px;
  }
  & .ant-select-selection-search-input {
    height: auto !important;
  }
`;

const Select: FC<SelectProps<SelectValue>> = props => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledSelect dropdownStyle={{ boxShadow: 'none' }} {...props}>
      {props.children}
    </StyledSelect>
  );
};

export default Select;
