import React from 'react';
import { Outlet } from 'react-router-dom';
import AUTH_ROLES_ENUM from 'auth/authRoles';

const Orders = React.lazy(() => import('./index'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));

const OrdersConfig = {
  settings: {
    layout: {}
  },
  auth: [
    AUTH_ROLES_ENUM.ADMIN,
    AUTH_ROLES_ENUM.VENDOR,
    AUTH_ROLES_ENUM.CRAFTSMAN,
    AUTH_ROLES_ENUM.ARCHITECT,
    AUTH_ROLES_ENUM.ACCOUNT_PRO
  ],
  routes: [
    {
      path: 'orders',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Orders />
        },
        {
          path: 'details/:id',
          element: <OrderDetails />
        }
      ]
    }
  ]
};

export default OrdersConfig;
