import { gql } from '@apollo/client';

export const GET_MANUFACTURER_USER_FRAGMENT = gql`
  fragment ManufacturerMeFragment on Manufacturer {
    id
    company_name
    short_name
    email
    roles {
      name
      value
    }
  }
`;

export const GET_ADMIN_USER_FRAGMENT = gql`
  fragment AdminMeFragment on AdminUser {
    id
    firstname
    lastname
    email
    roles {
      name
      value
    }
  }
`;

export const GET_VENDOR_USER_FRAGMENT = gql`
  fragment VendorMeFragment on Vendor {
    id
    name
    email
    surname
    company_name
    wholesaler
    isVisible
    account_type
    vendor_type
    roles {
      name
      value
    }
  }
`;
