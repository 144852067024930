import React from 'react';
import { Row, Col, Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NoStripeConnected = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Row gutter={[8, 0]} style={{ marginBottom: 10 }}>
      <Col span={24}>
        <Alert
          showIcon
          type="warning"
          message={
            <Row justify={'space-between'} align="middle">
              <Col>{t('profile.noStripeConnected')}</Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate('/configurations/payment-configuration')
                  }
                >
                  {t('profile.connectStripe')}
                </Button>
              </Col>
            </Row>
          }
        />
      </Col>
    </Row>
  );
};

export default NoStripeConnected;
