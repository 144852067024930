import { useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

type ParamsData = {
  key: string;
  value: string;
};

type ReturnType = {
  setParams: (data: ParamsData[]) => void;
  getParams: (keys: string[]) => { [key: string]: string | null }[];
  deleteParams: (keys: string[]) => void;
  getParam: (key: string) => string | null;
  deleteParam: (key: string) => void;
  deleteAllParams: () => void;
};

const useQueryParams = (): ReturnType => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);

  const setParams = useCallback(
    (data: ParamsData[]) => {
      data.forEach(item => {
        searchParams.set(item.key, item.value);
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const deleteParams = useCallback(
    (data: string[]) => {
      data.forEach(item => {
        searchParams.delete(item);
      });
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const deleteAllParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const getParams = useCallback(
    (keys: string[]) => keys.map(key => ({ [key]: searchParams.get(key) })),
    [searchParams]
  );

  const getParam = useCallback(
    (key: string) => searchParams.get(key),
    [searchParams]
  );

  const deleteParam = useCallback(
    (key: string) => searchParams.delete(key),
    [searchParams]
  );

  return {
    setParams,
    getParams,
    deleteParams,
    getParam,
    deleteParam,
    deleteAllParams
  };
};

export default useQueryParams;
