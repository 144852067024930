import React, { FC, useMemo } from 'react';
import { Loading, Popup } from '@stylique/core';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_MENU_ITEM } from 'graphql/websiteMenu/queries';

import { ModalTitle, FormItemsTitle } from '../../styles';
import { IModal4, ISubmitLevel4 } from '../../types';
import MetaKeywords from '../FormItems/MetaKeywords';
import Name from '../FormItems/Name';
import Save from '../FormItems/Save';
import SeoDescription from '../FormItems/SeoDescription';
import SeoTitle from '../FormItems/SeoTitle';
import ByBrand from '../FormItems/ByBrand';
import ByTag from '../FormItems/ByTag';
import ByCategory from '../FormItems/ByCategory';
import ByExactProduct from '../FormItems/ByExactProduct';
import ByAttributeValueGroup from '../FormItems/ByAttributeValueGroup';
import { getInitialValuesModal4 } from '../../utils/getInputValues';
import MarketingText from '../FormItems/MarketingText';
import MarketingTextTitle from '../FormItems/MarketingTextTitle';

const Level4Modal: FC<IModal4> = ({
  isVisible,
  data: modalData,
  onClose,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();
  const type = modalData ? 'update' : 'create';

  const {
    data: { getMenuItem: { data = {} } = {} } = {},
    loading: isGetMenuItem
  } = useQuery(GET_MENU_ITEM, {
    skip: !modalData?.id,
    fetchPolicy: 'no-cache',
    variables: {
      id: Number(modalData?.id)
    }
  });

  const initialValues = useMemo(() => {
    if (!data) return {};

    return getInitialValuesModal4(data);
  }, [data]);

  const onCloseHandler = () => {
    onClose();
    form.resetFields();
  };

  const onFinishHandler = (values: ISubmitLevel4) => {
    onSubmit(values, type);
    onCloseHandler();
  };

  return (
    <Popup
      isVisible={isVisible}
      afterClose={onClose}
      onCancel={onCloseHandler}
      onClose={onCloseHandler}
      destroyOnClose
      width={600}
    >
      <div style={{ minHeight: '500px' }}>
        {!isGetMenuItem ? (
          <>
            <ModalTitle>
              {type === 'create'
                ? t('website_menu.create_level_4')
                : t('website_menu.update_level_4')}
            </ModalTitle>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinishHandler}
              initialValues={initialValues}
            >
              <Name label={t('website_menu.name_level4')} />
              <FormItemsTitle>{t('website_menu.seo_block')}</FormItemsTitle>
              <MarketingTextTitle />
              <MarketingText />
              <SeoTitle />
              <SeoDescription />
              <MetaKeywords />
              <FormItemsTitle>{t('products.link_products')}</FormItemsTitle>
              <ByCategory />
              <ByAttributeValueGroup />
              <ByBrand />
              <ByTag />
              <ByExactProduct />
              <Save onClose={onClose} isLoading={isLoading} />
            </Form>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </Popup>
  );
};

export default Level4Modal;
