import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #1e2229;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.6;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
  border: 1px solid var(--border-color-split);
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeadingLeft = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
`;

export const HeadingExtra = styled.div`
  margin: 4px 0;
  white-space: nowrap;
`;

export const HeadingTitle = styled.h2`
  color: var(--text-color);
  margin-right: 12px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeadingSubTitle = styled.h3`
  color: var(--text-color);
  margin-bottom: 0;
  font-weight: 400;
  font-size: 13px;
`;

export const BackButton = styled.button`
  border: 0px;
  background: transparent;
  padding: 0px;
  line-height: inherit;
  display: inline-block;
  touch-action: manipulation;
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
`;
