import React from 'react';
import { ButtonProps, Button as CButton } from 'antd';
import styled, { css } from 'styled-components';

interface Props extends ButtonProps {
  inverse?: boolean;
}

type StyledProps = {
  type?: string;
  size?: string;
  ghost?: boolean;
  inverse?: Props['inverse'];
};

const StyledButton = styled(CButton).withConfig({
  shouldForwardProp: prop => !['inverse'].includes(prop)
})<StyledProps>`
  letter-spacing: 0.1px;
  border-radius: 2px;
  vertical-align: middle;
  ${({ size }) =>
    size === 'small' &&
    css`
      padding-left: 16px;
      padding-right: 16px;
    `};
  ${({ type }) => type === 'text' && 'color: var(--text-color-2);'}
  &.ant-btn[disabled] {
    color: var(--gray-12) !important;
  }
`;

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ inverse, ...props }, ref) => {
    return <StyledButton ref={ref} inverse={inverse} {...props} />;
  }
);

export default Button;
