import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Row, Space, TreeSelect } from 'antd';
import { Popup, Button, Title, FormItem } from '@stylique/core';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  getVariationServices_getVariationServices_data_serviceGroups as TServiceGroups,
  getVariationServices_getVariationServices_data_serviceGroups_services as TServices
} from '__generated__/getVariationServices';

import { GET_VARIATIONS_SERVICES } from '../../../graphql/variationsProducts/queries';
import { GET_SERVICE_GROUPS } from 'pages/admin/Services/graphql/queries';
import { STYLES_TITLE } from '../styles';
import { IPropsServicesPopup } from '../types';

const { SHOW_PARENT } = TreeSelect;

const ServicesPopup: FC<IPropsServicesPopup> = ({
  id,
  isVisible = false,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();
  const [serviceGroupIds, setServiceGroupIds] = useState<number[] | string[]>(
    []
  );

  const [serviceIds, setServiceIds] = useState<number[] | string[]>([]);

  const {
    data: { getVariationServices: { data = {} } = {} } = {},
    loading: isLoading_getVariationServices
  } = useQuery(GET_VARIATIONS_SERVICES, {
    fetchPolicy: 'no-cache',
    variables: {
      id
    }
  });

  const {
    data: { serviceGroups: { data: serviceGroupsData = [] } = {} } = {},
    loading: isLoadingserviceGroups
  } = useQuery(GET_SERVICE_GROUPS, {
    fetchPolicy: 'no-cache'
  });

  const idsService: number[] = useMemo(() => {
    return serviceGroupsData
      ?.map((serviceGroup: TServiceGroups) => {
        return serviceGroup?.services?.map((service: TServices | null) => {
          return Number(service?.id);
        });
      })
      .flat();
  }, [serviceGroupsData]);

  const serviceGroupsWithUniqIds = useMemo(() => {
    return serviceGroupsData?.map((group: TServiceGroups) => {
      return { ...group, id: `serviceGroup_${group.id}` };
    });
  }, [serviceGroupsData]);

  const filter_idsServiceGroups = useCallback((ids: string[]) => {
    return ids
      ?.filter((id: number | string) => `${id}`.includes('serviceGroup_'))
      ?.map((i: string) => Number(i.split('serviceGroup_')[1]));
  }, []);

  const filter_idsServices = useCallback(
    (ids: string[]) => {
      return ids?.filter((id: number | string) => idsService.includes(+id));
    },
    [idsService]
  );

  const onChangeTree = (ids: string[]) => {
    const idsServiceGroups = filter_idsServiceGroups(ids);
    const idsServices = filter_idsServices(ids);

    setServiceGroupIds(idsServiceGroups);
    setServiceIds(idsServices);
  };

  const tProps = {
    treeData: serviceGroupsWithUniqIds,
    onChange: onChangeTree,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    allowClear: true,
    indeterminate: 'true',
    placeholder: 'Select Service Groups or Services',
    fieldNames: {
      value: 'id',
      label: 'name',
      children: 'services'
    },
    style: {
      width: '100%'
    }
  };

  const resetStates = () => {
    setServiceGroupIds([]);
    setServiceIds([]);
  };

  useEffect(() => {
    const ids = data?.serviceGroups
      ?.map((group: TServiceGroups) => `serviceGroup_${group.id}`)
      .concat(data?.services.map((service: TServices) => service.id));

    const idsServiceGroups = filter_idsServiceGroups(ids);
    const idsServices = filter_idsServices(ids);

    setServiceGroupIds(idsServiceGroups);
    setServiceIds(idsServices);

    form.setFieldsValue({
      serviceGroups: ids
    });
  }, [
    form,
    isVisible,
    data,
    serviceGroupsData,
    idsService,
    filter_idsServiceGroups,
    filter_idsServices
  ]);

  return (
    <Popup isVisible={isVisible} onClose={onClose} width={600}>
      <Title style={STYLES_TITLE} titleLevel={4}>
        Attach Service Group
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={data => {
          onSubmit(data, serviceGroupIds, serviceIds);
          resetStates();
        }}
      >
        <FormItem label={'Service Group Name'} name="serviceGroups">
          <TreeSelect {...tProps} size="large" />
        </FormItem>

        <Form.Item>
          <Row style={{ marginTop: '16px', marginBottom: 0 }} justify="center">
            <Space align="center" size={32} direction="horizontal">
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  resetStates();
                  onClose();
                }}
              >
                {t('cancel', { ns: 'common' })}
              </Button>
              <Button
                inverse={true}
                type="primary"
                htmlType="submit"
                loading={
                  isLoadingserviceGroups || isLoading_getVariationServices
                }
              >
                {t('save', { ns: 'common' })}
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Popup>
  );
};

export default ServicesPopup;
