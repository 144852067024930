// Public
import LoginConfig from './Login/LoginConfig';
import ForgotPasswordConfig from './ForgotPassword/ForgotPasswordConfig';
import VerifyVendorConfig from './VerifyVendor/VerifyVendorConfig';
import VerifyManufacturerConfig from './VerifyManufacturer/VerifyManufacturerConfig';
// Role Specefic
import AdminConfig from './admin/AdminConfig';
import VendorConfig from './vendor/VendorConfig';
import ManufacturerConfig from './manufacturer/ManufacturerConfig';
// Common
import DashboardConfig from './Dashboard/DashboardConfig';
import VendorResetPasswordConfig from './vendor/ResetPassword/ResetPasswordConfig';
import PartnerBlogConfig from './PartnersBlog/PartnerBlogConfig';
import VariationsConfig from './VariationsProducts/VariationsProductsConfig';
import ParentsProductsConfig from './ParentsProducts/ParentsProductsConfig';
import StylesConfig from './Styles/StylesConfig';
import OrdersConfig from './Orders/OrdersConfig';
import DiscountsConfig from './Discounts/DiscountsConfig';

const pagesConfigs = [
  AdminConfig,
  VendorConfig,
  ManufacturerConfig,
  DashboardConfig,
  LoginConfig,
  VerifyVendorConfig,
  VerifyManufacturerConfig,
  ForgotPasswordConfig,
  VendorResetPasswordConfig,
  PartnerBlogConfig,
  VariationsConfig,
  ParentsProductsConfig,
  StylesConfig,
  OrdersConfig,
  DiscountsConfig
];

export default pagesConfigs;
