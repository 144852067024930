import React, { RefObject, useMemo, useRef } from 'react';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { FormItem, ImageUpload } from '@stylique/core';
import { Form, Input, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_MANUFACTURERS } from 'graphql/manufacturers/queries';
import { manufacturers_manufacturers_data as IBrand } from '__generated__/manufacturers';

import { getUrlWithNameAndId } from '../../utils';
import { AddBrandButton, BrandNameLabel } from '../../styles';

const Brands = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const form = Form.useFormInstance();
  const initialValuesBrands = form.getFieldValue('brands');

  const brandNames: string[] = [
    t('website_menu.first_brand'),
    t('website_menu.second_brand'),
    t('website_menu.third_brand'),
    t('website_menu.forth_brand')
  ];

  const {
    data: { manufacturers: { data: manufacturers = [] } = {} } = {},
    loading: isManufacturers
  } = useQuery(GET_MANUFACTURERS, {
    fetchPolicy: 'no-cache'
  });

  const manufacturerOptions = useMemo(() => {
    return manufacturers.map(({ company_name, id, status }: IBrand) => {
      return {
        label: company_name,
        value:
          status === '1'
            ? `unverified_${getUrlWithNameAndId(company_name, id)}`
            : getUrlWithNameAndId(company_name, id)
      };
    });
  }, [manufacturers]);

  return (
    <Form.List name="brands">
      {(fields, { add, remove }) => (
        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
          {fields.map(({ key, name, ...restField }, index) => {
            const image = initialValuesBrands?.[index]?.image;

            return (
              <div key={key} ref={parentRef}>
                <FormItem
                  {...restField}
                  name={[name, 'slug']}
                  labelWidth="100%"
                  rules={[{ required: true }]}
                  key={key}
                  label={
                    <BrandNameLabel>
                      <p style={{ margin: 0 }}>{brandNames[index]}</p>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          remove(name);
                        }}
                      >
                        <DeleteOutlined style={{ fontSize: '14px' }} />{' '}
                        <span style={{ fontSize: '14px' }}>
                          {t('remove', { ns: 'common' })}
                        </span>
                      </div>
                    </BrandNameLabel>
                  }
                >
                  <Select
                    getPopupContainer={() =>
                      parentRef.current as HTMLDivElement
                    }
                    size="middle"
                    style={{ fontSize: '16px' }}
                    placeholder={'Select Brand'}
                    options={manufacturerOptions}
                    loading={isManufacturers}
                  />
                </FormItem>

                <FormItem
                  {...restField}
                  extra={
                    <div>
                      {t('recommanded_resolution_640', { ns: 'common' })}
                    </div>
                  }
                  name={[name, 'image']}
                  rules={[{ required: true }]}
                >
                  <ImageUpload
                    multiple={false}
                    initialValues={image ? [image] : undefined}
                    dropZoneProps={{
                      accept: ['image/png', 'image/jpg', 'image/jpeg'],
                      maxSize: 1048576
                    }}
                    input={
                      <Input
                        prefix={<UploadOutlined />}
                        size="small"
                        style={{
                          pointerEvents: 'none',
                          fontSize: '14px',
                          padding: '5px 15px'
                        }}
                        placeholder={t('settings.title_images.placeholder')}
                      />
                    }
                  />
                </FormItem>
              </div>
            );
          })}

          {fields.length < 4 ? (
            <Space>
              <AddBrandButton
                size="small"
                type="default"
                onClick={() => add()}
                block
              >
                <PlusOutlined /> {t('website_menu.add_another_brand')}
              </AddBrandButton>
            </Space>
          ) : null}
        </div>
      )}
    </Form.List>
  );
};

export default Brands;
