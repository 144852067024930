/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable padding-line-between-statements */
/* eslint-disable prettier/prettier */
import { attributeOptions_attributeOptions_data } from '__generated__/attributeOptions';

// TODO: move to typescript project
type ValueOf<T> = T[keyof T];

export const ActionTypes = {
  TOGGLE_PARAM: 'TOGGLE_PARAM',
  SET_VARIATIONS: 'SET_VARIATIONS',
  ADD_VARIATION: 'ADD_VARIATION',
  DELETE_VARIATION: 'DELETE_VARIATION',
  DELETE_VARIATION_BY_OPTION: 'DELETE_VARIATION_BY_OPTION',
  SET_ATTRIBUTES: 'SET_ATTRIBUTES',
  SET_ALL_ATTRIBUTES: 'SET_ALL_ATTRIBUTES',
  REMOVE_ATTRIBUTE: 'REMOVE_ATTRIBUTE',
  SET_INDEX_TO_EDIT: 'SET_INDEX_TO_EDIT',
  SET_INDEX_TO_FILL_PROPS: 'SET_INDEX_TO_FILL_PROPS',
  EDIT_VARIATION: 'EDIT_VARIATION',
  SET_VARIATION_PROPERTIES: 'SET_VARIATION_PROPERTIES',
  SET_ATTRIBUTE_FAMILY: 'SET_ATTRIBUTE_FAMILY',
  SET_TYPE: 'SET_TYPE',
  SET_VARIATION_IMAGES: 'SET_VARIATION_IMAGES',
  SET_INITIAL_VALUES: 'SET_INITIAL_VALUES',
  SET_STATUS: 'SET_STATUS'
};

export type GroupsValues = {
  group: string;
  values: {
    attribute: string;
    value: string;
  }[];
};

export type VariationInput = {
  id?: number | null;
  inputs: GroupsValues[];
  tags: number[];
  name: string;
  slug: string;
  sku: string;
  price: string;
  is_simple_product: boolean;
};

export type Variation = {
  id?: number | null;
  combinations: attributeOptions_attributeOptions_data[];
  inputs?: GroupsValues[];
  images?: ImageType[];
  tags?: number[];
  name?: string;
  slug?: string;
  sku?: string;
  price?: string;
  is_simple_product?: boolean;
};

export type AttributeFormOptions = {
  attribute: string;
  values: string[];
};

export type AttributeWithOptions = {
  attribute: string;
  index: number;
  values: string[];
};

export type AttributeConvertedOptions = {
  attribute: string;
  values: attributeOptions_attributeOptions_data[];
};

export type ToggleParam = {
  key: string;
  value: boolean;
};

export type ManualValues = {
  [key: string]: string;
};

export type ProductInitialValues = {
  attributeFamily: number;
  type: 1 | 2;
  attributes: AttributeFormOptions[];
  variations: Variation[];
  images: ImageType[];
};

export type ImageType = {
  filename: string;
  main?: boolean;
};

export type Action = {
  type: ValueOf<typeof ActionTypes>;
  data: unknown;
};

export type State = {
  isAutomatic: boolean;
  variations: Variation[];
  attributes: AttributeFormOptions[];
  isVariationPopupVisible: boolean;
  indexToEdit: number;
  indexToFillProps: number;
  attributeFamily: number;
  type: 1 | 2;
  variationImages: ImageType[];
  status: 1 | 2;
};

export type DispatchContext = {
  toggleParam: (data: ToggleParam) => void;
  setAttributes: (data: AttributeWithOptions) => void;
  removeAttribute: (index: number) => void;
  deleteVariation: (index: number) => void;
  createManualVariation: (
    data: attributeOptions_attributeOptions_data[]
  ) => void;
  updateVariation: (
    index: number,
    data: attributeOptions_attributeOptions_data[]
  ) => void;
  setIndexToEdit: (index: number) => void;
  deleteVariationByOption: (id: string | string[]) => void;
  setIndexToFillProps: (index: number) => void;
  setVariationProperties: (index: number, data: VariationInput) => void;
  setAttributeFamily: (id: number) => void;
  setType: (data: 1 | 2) => void;
  setVariations: (data: Variation[]) => void;
  setVariationImages: (data: any) => void;
  setInitialData: (data: any) => void;
  setStatus: (data: 1 | 2) => void;
};

export type Dispatch = (action: Action) => void;
