import React, { FC, useState } from 'react';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Paragraph, Input } from '@stylique/core';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { CHECK_KEY } from 'graphql/resetPassword/queries';
import useAuth from 'auth/useAuth';

import { Container, LoginWrap, Titles, Welcome } from '../../Login/styles';
import { RESET_PASSWORD } from '../../../graphql/resetPassword/mutation';
import BackToBage from '../../../components/BackToLogin';
import { StyledCheckOutlined } from './styles';

const NewPassword: FC = () => {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [passwordRessetSucces, setPasswordResetSucces] =
    useState<boolean>(false);

  const adminRole = useAuth(state => state.adminRole);

  useQuery(CHECK_KEY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        type: adminRole,
        key: id
      }
    },
    onError(error) {
      const isNotValidKey =
        error?.graphQLErrors[0]?.extensions?.response?.body?.message ===
        'Not found';

      if (isNotValidKey) {
        navigate('/login');
      }
    }
  });

  const onFinish = async ({
    password,
    confirmPassword
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    await resetPassword({
      variables: {
        input: {
          type: adminRole,
          key: id,
          password,
          confirmPassword
        }
      },
      onCompleted() {
        setPasswordResetSucces(true);
      }
    });
  };

  return (
    <LoginWrap>
      {!passwordRessetSucces ? (
        <Container>
          <Titles>
            <Welcome>{t('forgot_password.set_new_password')}</Welcome>
            <Paragraph
              style={{ fontSize: '16px', marginBottom: '24px' }}
              color="var(--gray-3)"
            >
              {t('forgot_password.about_3')}
            </Paragraph>
          </Titles>

          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="password"
              label={t('password')}
              rules={[
                {
                  required: true
                }
              ]}
              hasFeedback={false}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={t('confirm_password')}
              dependencies={['password']}
              hasFeedback={false}
              rules={[
                {
                  required: true
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('forgot_password.password_not_match'))
                    );
                  }
                })
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item style={{ marginTop: 16, marginBottom: 29 }}>
              <Button
                style={{
                  background: 'var(--gray-10)',
                  borderColor: 'var(--gray-10)'
                }}
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                {t('forgot_password.reset_password')}
              </Button>
            </Form.Item>

            <BackToBage />
          </Form>
        </Container>
      ) : (
        <Container>
          <Space direction="vertical" size={24}>
            <Titles>
              <Welcome>{t('forgot_password.reset_password')}</Welcome>
              <Paragraph
                style={{ fontSize: '16px', marginBottom: '24px' }}
                color="var(--gray-3)"
              >
                {t('forgot_password.about_4')}
              </Paragraph>
            </Titles>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '24px'
              }}
            >
              <StyledCheckOutlined>
                <CheckOutlined style={{ fontSize: '45px', color: '#679436' }} />
              </StyledCheckOutlined>
            </div>

            <Button
              type="primary"
              block
              style={{
                background: 'var(--gray-10)',
                borderColor: 'var(--gray-10)'
              }}
              onClick={() => {
                navigate('/login');
              }}
            >
              {t('forgot_password.login')}
            </Button>
          </Space>
        </Container>
      )}
    </LoginWrap>
  );
};

export default NewPassword;
