import React, { memo, FC } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import styled from 'styled-components';

interface Props {
  handleChange: (e: ColorResult) => void;
  color: string;
}

const Container = styled.div`
  padding: 16px;
  background-color: var(--color-picker-background);
  border-radius: 12px;
`;

const ColorPicker: FC<Props> = ({ handleChange, color }) => {
  return (
    <Container>
      <ChromePicker
        color={color}
        onChange={(e: ColorResult) => handleChange(e)}
        disableAlpha
        styles={{
          default: {
            picker: {
              width: '100%',
              boxShadow: 'none',
              backgroundColor: '#F8F8F8'
            },
            saturation: {
              paddingBottom: 80,
              borderRadius: 8
            },
            Hue: {
              display: 'none'
            }
          }
        }}
      />
    </Container>
  );
};

export default memo(ColorPicker);
