import { gql } from '@apollo/client';
import {
  ATTRIBUTE_FRAGMENT,
  ATTRIBUTE_OPTIONS_FRAGMENT
} from 'graphql/attributes/fragments';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Products {
    id
    name
    status
    type
    family_id
    manufacturer_id
    category {
      isMain
      data {
        id
        name
        slug
        parentId
      }
    }
    variations {
      id
      is_simple_product
      name
      slug
      price
      sku
      status
      image {
        filename
      }
      images {
        filename
        main
      }
      tags {
        data {
          id
          name
        }
      }
      combinations {
        id
        attr_id
        option_id
        variation_id
        option {
          ...AttributeOptionsFragment
        }
        attribute {
          ...AttributeFragment
        }
      }
      variationAttributes {
        id
        group_id
        attr_id
        value
        attribute {
          id
          name
        }
        group {
          id
          slug
        }
      }
    }
  }
  ${ATTRIBUTE_FRAGMENT}
  ${ATTRIBUTE_OPTIONS_FRAGMENT}
`;

export const PRODUCTS_LIST_FRAGMENT = gql`
  fragment ProductListFragment on Products {
    id
    name
    status
    type
    category {
      data {
        id
        name
        slug
      }
    }
  }
`;
