import { Card } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCard = styled(Card)`
  min-height: 600px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const StyledArtNumber = styled.div<{
  valueLength: boolean;
}>`
  white-space: nowrap;
  ${valueLength =>
    valueLength &&
    css`
      max-width: 140px;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const StyledVariationDetailsCard = styled(Card)`
  padding: 24px;
`;

export const StyledVariationHeader = styled.div`
  .ant-page-header-heading-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const STYLES_TITLE: undefined | React.CSSProperties = {
  color: 'var(--gray-10)',
  textAlign: 'center',
  marginBottom: '49px'
};

export const STYLED_BULK_ACTIONS_BTN = {
  border: '1px solid var(--gray-10)',
  color: 'var(--text-color)',
  padding: '2px 16px',
  borderRadius: '4px',
  height: 'auto'
};

export const StyledCount = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  margin-right: 16px;
`;

export const StyledBulkActionBar = styled.div`
  height: auto;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 9px 16px 9px 9px;
  border-radius: 4px;
`;

export const StyledAddImagePopupText = styled.p<{ type: string }>`
  margin-bottom: 0;
  text-align: center;

  ${({ type }) => {
    if (type === 'title') {
      return css`
        color: var(--texts-text-1, #1e2229);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
      `;
    }

    if (type === '1') {
      return css`
        color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 0.9;
      `;
    }

    if (type === '2') {
      return css`
        color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      `;
    }

    if (type === '3') {
      return css`
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      `;
    }
  }}
`;
