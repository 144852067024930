import React, { useEffect, useState } from 'react';
import { Col, Row, Space } from 'antd';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { dragItemInArray } from '@stylique/tools';
import {
  SYNC_MENU_ITEM_PRODUCTS,
  UPDATE_MENU_ITEM_ORDERS
} from 'graphql/websiteMenu/mutations';
import { GET_MENU_ITEMS } from 'graphql/websiteMenu/queries';
import { cloneDeep } from 'lodash';
import { getMenuItems_getMenuItems_data as IMenuItem } from '__generated__/getMenuItems';

import Header from './components/PageHeader';
import EmptySectionLevel from './components/EmptySectionLevel';
import LevelSection from './components/LevelSection';
import SkeletonWebsiteMenu from './components/SkeletonWebsiteMenu';
import { TLevel } from './types';
import { WebsiteMenuStatus } from './constants';

const WebsiteMenu = () => {
  const { search } = useLocation();
  const parsedParams = qs.parse(search, { ignoreQueryPrefix: true });
  const [menuItemsStatus, setMenuItemsStatus] = useState<WebsiteMenuStatus>(
    WebsiteMenuStatus.COMPLETED
  );

  const [menuItems2, setMenuItems2] = useState<IMenuItem[]>([]);
  const [menuItems3, setMenuItems3] = useState<IMenuItem[]>([]);
  const [menuItems4, setMenuItems4] = useState<IMenuItem[]>([]);

  const setMenuItemsByLevel = {
    '2': setMenuItems2,
    '3': setMenuItems3,
    '4': setMenuItems4
  };

  const [updateMenuItemOrders] = useMutation(UPDATE_MENU_ITEM_ORDERS);
  const [syncMenuItemProducts, { loading: isSyncMenuItemProducts }] =
    useMutation(SYNC_MENU_ITEM_PRODUCTS);

  const handleMenuItemsResponse = (level: TLevel) => {
    return ({
      getMenuItems: {
        data: menuItemsData = [],
        message = WebsiteMenuStatus.COMPLETED
      } = {}
    }) => {
      setMenuItemsByLevel[level](menuItemsData);
      setMenuItemsStatus(message as WebsiteMenuStatus);
    };
  };

  const [getMenu2, { loading: isGetMenu2 }] = useLazyQuery(GET_MENU_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted: handleMenuItemsResponse('2')
  });

  useEffect(() => {
    getMenu2();

    const timer = setInterval(getMenu2, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [getMenu2]);

  const [getMenu3, { loading: isGetMenu3 }] = useLazyQuery(GET_MENU_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted: handleMenuItemsResponse('3')
  });

  useEffect(() => {
    const parentId = parsedParams.level2
      ? Number(parsedParams.level2)
      : undefined;

    getMenu3({
      variables: {
        id: parentId
      }
    });
  }, [getMenu3, parsedParams.level2]);

  const [getMenu4, { loading: isGetMenu4 }] = useLazyQuery(GET_MENU_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted: handleMenuItemsResponse('4')
  });

  useEffect(() => {
    const parentId = parsedParams.level3
      ? Number(parsedParams.level3)
      : undefined;

    getMenu4({ variables: { id: parentId } });
  }, [getMenu4, parsedParams.level3]);

  const moveRow = async (
    dragIndex: number,
    hoverIndex: number,
    level: TLevel
  ) => {
    const menuItemsByLevel = {
      '2': menuItems2,
      '3': menuItems3,
      '4': menuItems4
    };

    const copyMenuItems: IMenuItem[] = cloneDeep(menuItemsByLevel[level]);
    const reorderedMenuItems = dragItemInArray(
      copyMenuItems,
      dragIndex,
      hoverIndex
    );

    setMenuItemsByLevel[level](reorderedMenuItems);

    const input = reorderedMenuItems.map((menu: IMenuItem, index: number) => ({
      id: menu.id,
      sortIndex: index
    }));

    await updateMenuItemOrders({ variables: { input } });
  };

  const onReassignHandler = async () => {
    await syncMenuItemProducts();
  };

  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      <Header reassignHandler={onReassignHandler} status={menuItemsStatus} />

      {!isSyncMenuItemProducts ? (
        <Row gutter={[8, 0]}>
          <Col span={8}>
            <LevelSection
              level={'2'}
              getCurrentMenuData={getMenu2}
              moveRow={moveRow}
              isGetCurrentMenuData={isGetMenu2}
              menuItems={menuItems2}
              currentItemId={parsedParams.level2}
              parentItemId={null}
            />
          </Col>

          {parsedParams.level2 ? (
            <Col span={parsedParams.level3 ? 8 : 16}>
              <LevelSection
                level={'3'}
                getCurrentMenuData={getMenu3}
                moveRow={moveRow}
                isGetCurrentMenuData={isGetMenu3}
                menuItems={menuItems3}
                currentItemId={parsedParams.level3}
                parentItemId={parsedParams.level2}
              />
            </Col>
          ) : (
            <Col span={16}>
              <EmptySectionLevel />
            </Col>
          )}

          {parsedParams.level3 ? (
            <Col span={8}>
              <LevelSection
                level={'4'}
                getCurrentMenuData={getMenu4}
                moveRow={moveRow}
                isGetCurrentMenuData={isGetMenu4}
                menuItems={menuItems4}
                currentItemId={parsedParams.level4}
                parentItemId={parsedParams.level3}
              />
            </Col>
          ) : null}
        </Row>
      ) : (
        <SkeletonWebsiteMenu />
      )}
    </Space>
  );
};

export default WebsiteMenu;
