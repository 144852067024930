import React from 'react';
import { Outlet } from 'react-router-dom';
import AUTH_ROLES_ENUM from 'auth/authRoles';

import Variations from './index';
import VariationDetails from './VariationDetails';

const VariationsProductsConfig = {
  settings: {
    layout: {}
  },
  auth: [AUTH_ROLES_ENUM.ADMIN, AUTH_ROLES_ENUM.MANUFACTURE],
  routes: [
    {
      path: 'products/variations',
      element: <Outlet />,
      children: [
        {
          path: '',
          // TODO check type
          element: <Variations type="1" />
        },
        {
          path: 'details/:id',
          element: <VariationDetails />
        },
        {
          path: 'details',
          element: <VariationDetails />
        },
        {
          path: 'link-samples/:id',
          element: <Variations type="2" />
        }
      ]
    }
  ]
};

export default VariationsProductsConfig;
