import styled from 'styled-components';
import { Card } from 'antd';

export const MainLayoutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex: auto;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    min-height: calc(100% - 64px);
    padding-bottom: 64px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 74px;
`;

export const StyledCard = styled(Card)`
  height: 100%;
  min-height: 600px;
  width: 208px;
  padding: 60px 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LanguageSwitcher = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  span {
    margin-left: 5px;
  }
`;
