import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 15px;
    letter-spacing: 0.1px;
    font-weight: 400;
    min-height: 100%;
    height: 100vh;
    /* TODO */
    /* background-color: @gray-8; */
  }

  .upload-container {
    .ant-upload {
      width: 100%;
    }

    &.preview-inline {
      .ant-upload-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0 24px;
      }
      .ant-upload-list:before,
      .ant-upload-list:after {
        display: none;
      }
      .ant-upload-list-picture-container {
        width: calc(${100 / 4}% - 24px)
      }
    }
  }

  [style*='2147483647'] {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover span {
    color: var(--blue-5);
    transition: color 0.3s;
  }

  * {
    box-sizing: border-box;
  }

  .ant-modal {
    padding-bottom: 0 !important;
  }
  
  .ant-modal-footer {
    padding: 0 !important;
  }

  .product-info-suggestion-modal {
    .ant-modal-footer {
      padding: 10px 16px !important;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: 0;
  }

  .chrome-picker input {
    padding: 16px;
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #e5e8e8 !important;
  }

  .chrome-picker label {
    font-weight: bold !important;
    /* TODO */
    /* color: @text-color !important; */
  }

  .chrome-picker > div:second-child > div {
    align-items: center;
  }

  .chrome-picker > div:second-child > div > div {
    width: 30px;
  }

  .chrome-picker > div:second-child > div > div > div {
    width: 20px;
    height: 20px;
  }

  .ant-image {
    position: static !important;
    display: block !important;
    &.antd-image-relative {
      position: relative !important;
      .ant-image-mask {
        border-radius: 16px;
      }
    }
  }

  .ant-table tr.drop-over-downward td {
    /* TODO */
    /* background-color: @gray-7; */
  }

  .ant-table tr.drop-over-upward td {
    /* TODO */
    /* background-color: @gray-7; */
  }

  .ant-select,
  .ant-select-lg,
  .ant-select-multiple,
  .ant-select-show-search {
    width: 100%;
  }

  td {
    font-size: 14px;
    & .ant-typography {
      font-size: 14px;
    }
    & .ant-badge-status-text {
      font-size: 14px;
    }
  }

  th {
    font-size: 15px;
  }
  
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px;
  }

  .ant-picker-panel-container {
    background: white;
  }

  .ant-modal-wrap{
    padding: 37px !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    line-height: initial !important;
    height: inherit !important;
    align-items: center !important;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    border-color: #191919;
    box-shadow: none;
  }
  .ant-badge-status{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #F5F5F5;
    color: #00000073;
    border-radius: 2px;
  }
  div.ant-typography {
    margin-bottom: 0;
  }
  .ant-form-item-extra{
    font-size: 14px;
  }
  .ant-input-textarea-show-count::after{
    font-size: 14px;
  }
  .ant-input:focus {
    border-color: initial;
    box-shadow: none;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: initial;
    box-shadow: none;
  }

  // custom modals

  .blog-post-confirm-modal {
    .anticon-exclamation-circle {
      display: none;
    }
    .ant-modal-confirm-body-wrapper {
      padding: 24px 16px;
    }
    .ant-modal-confirm-content {
      margin-left: 0 !important;
      margin-top: 24px !important;
      color: var(--gray-10);
    }

    .ant-modal-confirm-body {
      text-align: center;

      .ant-modal-confirm-title {
        color: var(--gray-10);
      }
    }

    .ant-modal-confirm-btns {
      text-align: center;
      margin-top: 40px;
      .ant-btn {
        border-radius: 0;
      }
      .ant-btn + .ant-btn {
        margin-left: 24px;
      }
    }

    .ant-input-number::-webkit-inner-spin-button,
    .ant-input-number::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .showroom-config-collapse .ant-collapse-header {
      padding: 4px 8px !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: #F5F5F5 !important;
  }
  .linked-delivery-settings_table th ,.linked-delivery-settings_table td  {
    font-size: 12px !important;
    padding: 4px 8px !important;
    border-radius:0;
  }
  .linked-delivery-settings_table p{
    margin-bottom: 0;
  }
  .showroom-config-collapse .ant-collapse-content-box{
    padding: 8px;
  }
  .ant-dropdown-menu{
    background-color: white;
  }
  .ant-form-item-extra{
    min-height: 0 !important;
  }
`;

export default GlobalStyle;
