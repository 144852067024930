import React, { FC, ReactNode } from 'react';
import { Col, Row, Space } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { Title, Paragraph, Button } from '..';

interface Props {
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonClicked?: () => void;
  buttons?: ReactNode[];
}

const Container = styled.div`
  padding: 44px;
`;

const TableContainer = styled.div`
  padding-top: 24px;
`;

const DataTableWrapper: FC<Props> = ({
  buttonClicked,
  buttonText,
  buttons,
  subtitle,
  children,
  title
}) => {
  return (
    <Container>
      <Row justify="space-between">
        <Col>
          <Title titleLevel={4}>{title}</Title>
          <Paragraph style={{ maxWidth: 270, paddingTop: 4 }}>
            {subtitle}
          </Paragraph>
        </Col>
        <Col>
          <Space size={8} align="center">
            {buttons}
            {buttonText && (
              <Button onClick={buttonClicked} type="ghost">
                {buttonText}
                <PlusOutlined />
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <TableContainer>{children}</TableContainer>
    </Container>
  );
};

export default DataTableWrapper;
