import { NavigationType } from 'types/navigation';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';

const MENU_ITEMS: NavigationType[] = [
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO,
      AUTH_ROLES_ENUM.ACCOUNT_BASIC
    ],
    key: '/vendor-profile',
    label: 'navigation.profile',
    sortIndex: 1,
    children: [
      {
        key: '/vendor-profile/profile-information',
        label: 'navigation.profile_info'
      },
      {
        auth: [
          AUTH_ROLES_ENUM.VENDOR,
          AUTH_ROLES_ENUM.CRAFTSMAN,
          AUTH_ROLES_ENUM.ARCHITECT,
          AUTH_ROLES_ENUM.ACCOUNT_BASIC,
          AUTH_ROLES_ENUM.ACCOUNT_PRO
        ],
        key: '/vendor-profile/showrooms',
        label: 'navigation.showrooms'
      }
    ]
  },
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO
    ],
    key: '/configurations',
    label: 'navigation.configuration',
    sortIndex: 2,
    children: [
      {
        key: '/configurations/payment-configuration',
        label: 'navigation.payment_configuration'
      },
      {
        key: '/configurations/delivery/details',
        label: 'navigation.delivery_configuration'
      },
      {
        key: '/configurations/click-and-collect',
        label: 'navigation.click_and_collect'
      }
      // TODO
      // {
      //   key: '/configurations/order-settings',
      //   label: 'navigation.order_settings',
      //   auth: [
      //     AUTH_ROLES_ENUM.VENDOR,
      //     AUTH_ROLES_ENUM.CRAFTSMAN,
      //     AUTH_ROLES_ENUM.ACCOUNT_PRO
      //   ]
      // }
    ]
  },
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO
    ],
    key: '/vendor-partners',
    label: 'navigation.partners',
    sortIndex: 3,
    children: [
      {
        key: '/vendor-partners/my-network',
        label: 'navigation.myNetwork',
        auth: [
          AUTH_ROLES_ENUM.VENDOR,
          AUTH_ROLES_ENUM.CRAFTSMAN,
          AUTH_ROLES_ENUM.ARCHITECT,
          AUTH_ROLES_ENUM.ACCOUNT_PRO
        ]
      },
      {
        key: '/vendor-partners/stylique-network',
        label: 'navigation.styliqueNetwork',
        auth: [
          AUTH_ROLES_ENUM.VENDOR,
          AUTH_ROLES_ENUM.CRAFTSMAN,
          AUTH_ROLES_ENUM.ARCHITECT,
          AUTH_ROLES_ENUM.ACCOUNT_PRO
        ]
      }
    ]
  },
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO
    ],
    key: '/vendor-products',
    label: 'navigation.products',
    sortIndex: 4
  },
  {
    permissionType: 'hasAllPermissions',
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ACCOUNT_PRO
    ],
    key: '/craftsman-settings',
    label: 'navigation.craftsmen_settings',
    sortIndex: 2,
    children: [
      {
        key: '/craftsman-settings/service-settings',
        label: 'navigation.service_settings'
      },
      {
        key: '/craftsman-settings/driving-costs',
        label: 'navigation.driving_costs'
      }
    ]
  },
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO
    ],
    key: '/orders',
    label: 'navigation.orders',
    sortIndex: 4
  },
  {
    auth: [
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.ACCOUNT_PRO,
      AUTH_ROLES_ENUM.ACCOUNT_BASIC
    ],
    key: '/blog/list',
    label: 'navigation.blog',
    sortIndex: 3
  }
];

export default MENU_ITEMS;
