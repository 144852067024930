import React, { FC, useCallback, useMemo } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { attributeOptions_attributeOptions_data } from '__generated__/attributeOptions';
import { Popup, Button, Title } from '@stylique/core';
import {
  useProductDispatchContext,
  useProductStateContext
} from 'providers/Product';

import VariationsSelect from './VariationSelect';
import { createVariationValues } from 'utils/helpers';

const VariationPopup: FC = () => {
  const {
    toggleParam,
    createManualVariation,
    updateVariation,
    setIndexToEdit
  } = useProductDispatchContext();

  const { attributes, variations, indexToEdit } = useProductStateContext();
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();

  const closeModal = () => {
    if (indexToEdit > -1) {
      setIndexToEdit(-1);
    }

    toggleParam({ key: 'isVariationPopupVisible', value: false });
  };

  const data = useMemo(
    () =>
      attributes.map(attribute => ({
        attribute: attribute.attribute,
        values: createVariationValues(attribute)
      })),
    [attributes]
  );

  const checkIsExisting = useCallback(
    (inputs: attributeOptions_attributeOptions_data[]) => {
      return variations.some(variation =>
        variation.combinations.every(
          (item, index) => item.id === inputs[index].id
        )
      );
    },
    [variations]
  );

  const onFinish = (inputs: { [key: string]: string }) => {
    const variation = Object.keys(inputs).map(key =>
      data
        .find(item => item.attribute === key)
        ?.values.find((value: { id: string }) => value.id === inputs[key])
    );

    const isExisting = checkIsExisting(
      variation as attributeOptions_attributeOptions_data[]
    );

    if (isExisting) {
      return message.error(t('variations.variation_exists'));
    }

    if (indexToEdit > -1) {
      updateVariation(
        indexToEdit,
        variation as attributeOptions_attributeOptions_data[]
      );

      return closeModal();
    } else {
      createManualVariation(
        variation as attributeOptions_attributeOptions_data[]
      );
      closeModal();
    }
  };

  const initialValues = useMemo(() => {
    if (indexToEdit > -1) {
      const values: { [key: string]: string } = {};
      variations[indexToEdit].combinations.forEach(variation => {
        if (variation.attr_id) {
          values[variation.attr_id] = variation.id;
        }
      });

      return values;
    }

    return {};
  }, [indexToEdit, variations]);

  return (
    <Popup isVisible onClose={closeModal}>
      <Title titleLevel={4} style={{ marginBottom: 24 }}>
        {t(
          indexToEdit > -1
            ? 'variations.edit_variation'
            : 'variations.add_variation'
        )}
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {data.map(item => (
          <VariationsSelect
            key={`popup-option-${item.attribute}`}
            id={item.attribute}
            values={item.values}
          />
        ))}
        <Form.Item style={{ marginTop: 16, marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" block>
            {indexToEdit > -1
              ? t('confirm_edit', { ns: 'common' })
              : t('variations.create_variations')}
          </Button>
        </Form.Item>
      </Form>
    </Popup>
  );
};

export default VariationPopup;
