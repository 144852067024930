import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedBackBtn, StyledFeedbackWrapper } from './styles';
import FeedbackPopup from './FeedbackPopup';

const Feedback: FC = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const { t } = useTranslation('common');

  return (
    <StyledFeedbackWrapper>
      <FeedBackBtn onClick={() => setPopupVisible(true)}>
        {t('feedback.feedback')}
      </FeedBackBtn>
      <FeedbackPopup
        isVisible={popupVisible}
        onClose={() => setPopupVisible(false)}
      />
    </StyledFeedbackWrapper>
  );
};

export default Feedback;
