import { createGlobalStyle } from 'styled-components';
import EudoxusSansLightEot from 'assets/fonts/EudoxusSans-Light.eot';
import EudoxusSansLightWoff2 from 'assets/fonts/EudoxusSans-Light.woff2';
import EudoxusSansLightWoff from 'assets/fonts/EudoxusSans-Light.woff';
import EudoxusSansLightTtf from 'assets/fonts/EudoxusSans-Light.ttf';
import EudoxusSansRegularEot from 'assets/fonts/EudoxusSans-Regular.eot';
import EudoxusSansRegularWoff2 from 'assets/fonts/EudoxusSans-Regular.woff2';
import EudoxusSansRegularWoff from 'assets/fonts/EudoxusSans-Regular.woff';
import EudoxusSansRegularTtf from 'assets/fonts/EudoxusSans-Regular.ttf';
import EudoxusSansExtraLightEot from 'assets/fonts/EudoxusSans-ExtraLight.eot';
import EudoxusSansExtraLightWoff2 from 'assets/fonts/EudoxusSans-ExtraLight.woff2';
import EudoxusSansExtraLighWoff from 'assets/fonts/EudoxusSans-ExtraLight.woff';
import EudoxusSansExtraLightTtf from 'assets/fonts/EudoxusSans-ExtraLight.ttf';
import EudoxusSansExtraBoldEot from 'assets/fonts/EudoxusSans-ExtraBold.eot';
import EudoxusSansExtraBoldWoff2 from 'assets/fonts/EudoxusSans-ExtraBold.woff2';
import EudoxusSansExtraBoldWoff from 'assets/fonts/EudoxusSans-ExtraBold.woff';
import EudoxusSansExtraBoldTtf from 'assets/fonts/EudoxusSans-ExtraBold.ttf';
import EudoxusSansBoldEot from 'assets/fonts/EudoxusSans-Bold.eot';
import EudoxusSansBoldWoff2 from 'assets/fonts/EudoxusSans-Bold.woff2';
import EudoxusSansBoldWoff from 'assets/fonts/EudoxusSans-Bold.woff';
import EudoxusSansBoldTtf from 'assets/fonts/EudoxusSans-Bold.ttf';
import EudoxusSansMediumEot from 'assets/fonts/EudoxusSans-Medium.eot';
import EudoxusSansMediumWoff2 from 'assets/fonts/EudoxusSans-Medium.woff2';
import EudoxusSansMediumWoff from 'assets/fonts/EudoxusSans-Medium.woff';
import EudoxusSansMediumTtf from 'assets/fonts/EudoxusSans-Medium.ttf';

const FontStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Poppins');
    
    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansLightEot});
    src: url(${EudoxusSansLightWoff2}) format('woff2'),
        url(${EudoxusSansLightWoff}) format('woff'),
        url(${EudoxusSansLightTtf}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansRegularEot});
    src: url(${EudoxusSansRegularEot})
        format('embedded-opentype'),
        url(${EudoxusSansRegularWoff2}) format('woff2'),
        url(${EudoxusSansRegularWoff}) format('woff'),
        url(${EudoxusSansRegularTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansExtraLightEot});
    src: url(${EudoxusSansExtraLightEot})
        format('embedded-opentype'),
        url(${EudoxusSansExtraLightWoff2}) format('woff2'),
        url(${EudoxusSansExtraLighWoff}) format('woff'),
        url(${EudoxusSansExtraLightTtf}) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansExtraBoldEot});
    src: url(${EudoxusSansExtraBoldEot})
        format('embedded-opentype'),
        url(${EudoxusSansExtraBoldWoff2}) format('woff2'),
        url(${EudoxusSansExtraBoldWoff}) format('woff'),
        url(${EudoxusSansExtraBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansBoldEot});
    src: url(${EudoxusSansBoldEot})
        format('embedded-opentype'),
        url(${EudoxusSansBoldWoff2}) format('woff2'),
        url(${EudoxusSansBoldWoff}) format('woff'),
        url(${EudoxusSansBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansMediumEot});
    src: url(${EudoxusSansMediumEot})
        format('embedded-opentype'),
        url(${EudoxusSansMediumWoff2}) format('woff2'),
        url(${EudoxusSansMediumWoff}) format('woff'),
        url(${EudoxusSansMediumTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }
`;

export default FontStyles;
