import React, { FC } from 'react';
import {
  InputProps,
  InputNumberProps,
  Input as AntInput,
  InputNumber as AntInputNumber
} from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import styled, { css } from 'styled-components';

const { TextArea: AntTextArea } = AntInput;

const inputStyles = css`
  line-height: 1.5;
  font-size: 16px;
  border-radius: 2px;
  color: var(--gray-1);
  input {
    color: var(--gray-1) !important;
  }

  &:-internal-autofill-selected {
    background-color: transparent !important;
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: var(--gray-1) !important;
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--gray-2) !important;
  }

  .ant-input .ant-input-disabled {
    color: var(--gray-2) !important;
  }

  .anticon,
  [role='img'] {
    font-size: 20px;
    padding-right: 4px;
  }
`;

const StyledInput = styled(AntInput)`
  ${inputStyles}
`;

const StyledTextArea = styled(AntTextArea)`
  ${inputStyles}
  resize: none;
`;

const StyledInputNumber = styled(AntInputNumber)`
  ${inputStyles}
`;

const Input: FC<InputProps> = props => {
  return <StyledInput {...props} />;
};

const InputPassword: FC<InputProps> = props => {
  return <StyledInput.Password {...props} />;
};

const InputNumber: FC<InputNumberProps> = props => {
  return <StyledInputNumber {...props} />;
};

const TextArea: FC<TextAreaProps> = props => {
  return <StyledTextArea {...props} />;
};

export default Input;
export { InputPassword, TextArea, InputNumber };
