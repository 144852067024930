import React, { FC } from 'react';
import { FormItem, ImageUpload } from '@stylique/core';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

interface IProps {
  type: string;
  image: string | null | undefined;
}

const Image: FC<IProps> = ({ image, type }) => {
  const { t } = useTranslation('admin');
  const form = Form.useFormInstance();
  const promoLink = Form.useWatch(['promo_block', 'link'], form);
  const promoText = Form.useWatch(['promo_block', 'promo_text'], form);

  if (type === 'mobile_image') {
    return (
      <FormItem name="mobile_image" label={t('website_menu.image_mobile')}>
        <ImageUpload
          multiple={false}
          initialValues={image ? [image] : undefined}
        />
      </FormItem>
    );
  }

  if (type === 'image') {
    return (
      <FormItem
        name={['promo_block', 'image']}
        label={t('website_menu.promo_image')}
        rules={[
          {
            required: promoText?.length || promoLink?.length,
            message: t('required', { ns: 'common' })
          }
        ]}
      >
        <ImageUpload
          multiple={false}
          initialValues={image ? [image] : undefined}
        />
      </FormItem>
    );
  }

  return null;
};

export default Image;
