export const getMenuRowBackground = (currentSection: boolean) => ({
  background: currentSection ? '#F7F7F7' : '',
  borderColor: currentSection ? '#464646' : '#999999'
});

export const getUrlWithNameAndId = (name: string | null, id: number | null) => {
  if (!name) return;

  const nameSlug = encodeURIComponent(name.replace(/[ /]/g, '_'));
  const url = `${nameSlug}_${id}`;

  return url;
};

export const getNameFromSlug = (str: string | null | undefined) => {
  if (!str) return '';

  // Удаление последнего сегмента после последнего символа подчеркивания
  let formattedStr = str.replace(/_[^_]*$/, '');
  // Замена всех символов подчеркивания на пробелы
  formattedStr = formattedStr.replace(/_/g, ' ');

  if (formattedStr.includes('unverified')) {
    formattedStr = formattedStr.replace('unverified ', '');
  }

  return decodeURIComponent(formattedStr);
};
