import React, { RefObject, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { FormItem, Select } from '@stylique/core';
import { Checkbox, Form } from 'antd';
import { GET_MANUFACTURERS } from 'graphql/manufacturers/queries';
import { useTranslation } from 'react-i18next';
import { getManufacturers_getManufacturers_data_results as IBrand } from '__generated__/getManufacturers';

const ByBrand = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const form = Form.useFormInstance();
  const isChecked = Form.useWatch('manufacturersCheckbox', form);

  const {
    data: { manufacturers: { data: manufacturers = [] } = {} } = {},
    loading: isManufacturers
  } = useQuery(GET_MANUFACTURERS, {
    fetchPolicy: 'no-cache'
  });

  const manufacturerOptions = useMemo(() => {
    return manufacturers.map(({ company_name, id }: IBrand) => ({
      label: company_name,
      value: id
    }));
  }, [manufacturers]);

  return (
    <div ref={parentRef}>
      <FormItem valuePropName="checked" name="manufacturersCheckbox">
        <Checkbox style={{ fontSize: '14px' }}>
          {t('website_menu.by_brand')}
        </Checkbox>
      </FormItem>
      {isChecked ? (
        <FormItem name="manufacturers">
          <Select
            loading={isManufacturers}
            allowClear
            mode="multiple"
            style={{ fontSize: '14px', margin: '0' }}
            placeholder={t('website_menu.by_brand_placeholder')}
            options={manufacturerOptions}
            size="middle"
            filterOption={(inputValue, option) => {
              if (typeof option?.label === 'string') {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(inputValue.toLowerCase());
              }

              return false;
            }}
            getPopupContainer={() => parentRef.current as HTMLElement}
          />
        </FormItem>
      ) : null}
    </div>
  );
};

export default ByBrand;
