import React, { FC, Suspense, useEffect, useMemo } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { Row, Col, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Container, Loading } from '@stylique/core';
import { useAuth } from 'auth';
import routesConfig from 'configs/routesConfig';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import Globus from 'assets/icons/Globus';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Wrapper, StyledCard, LanguageSwitcher } from './styles';
import Navigation from 'components/Navigation';
import useSettings from 'hooks/useSettings';
import Header from './Header';
import { GET_SETTINGS } from 'pages/vendor/ProfileSettings/ClickAndCollect/graphql/queries';
import Feedback from 'components/Feedback';
import NoStripeConnected from './Messages/NoStripeConnected';
import NoDrivingCost from './Messages/NoDrivingCost';
import useUser from 'hooks/useUser';
import { GET_DRIVING_COSTS_SETTINGS } from 'pages/vendor/CraftsmanSettings/graphql/queries';

const BoxLayout: FC = () => {
  const { t, i18n } = useTranslation('common');
  const { pathname } = useLocation();
  const appRoutes = useRoutes(routesConfig);
  const user = useAuth(state => state.user);
  const adminRole = useAuth(state => state.adminRole);
  const { isCraftsman } = useUser();
  const layoutSettings = useSettings(state => state.settings.layout);
  const navigation = useSettings(state => state.navigation);
  const selectedLanguage = useSettings(state => state.language);
  const setLanguage = useSettings(state => state.setLanguage);
  const logout = useAuth(state => state.logout);

  const isProVendor = useMemo(() => {
    const vendor_type = user?.vendor_type as string;

    return (
      vendor_type === AUTH_ROLES_ENUM.VENDOR ||
      (vendor_type === AUTH_ROLES_ENUM.CRAFTSMAN &&
        user?.account_type === AUTH_ROLES_ENUM.ACCOUNT_PRO) ||
      (vendor_type === AUTH_ROLES_ENUM.ARCHITECT &&
        user?.account_type === AUTH_ROLES_ENUM.ACCOUNT_PRO)
    );
  }, [user]);

  const {
    data: {
      getVendorDrivingCostSettings: {
        data: craftsmanDrivingCostSettings = []
      } = {}
    } = {}
  } = useQuery(GET_DRIVING_COSTS_SETTINGS, {
    fetchPolicy: 'no-cache',
    skip: !isCraftsman
  });

  const [
    getVendorSettigns,
    {
      data: { vendorSettings: { data: settings = {} } = {} } = {},
      called: vendorSettingsCalled,
      loading: vendorSettingsLoading
    }
  ] = useLazyQuery(GET_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (isProVendor) {
      getVendorSettigns();
    }
  }, [getVendorSettigns, isProVendor]);

  const showStripeWarningMsg = useMemo(() => {
    return (
      isProVendor &&
      layoutSettings?.navigation?.display &&
      vendorSettingsCalled &&
      !vendorSettingsLoading &&
      !settings?.payment &&
      pathname !== '/configurations/payment-configuration'
    );
  }, [
    pathname,
    settings,
    vendorSettingsLoading,
    vendorSettingsCalled,
    isProVendor,
    layoutSettings
  ]);

  const showDrivingCostWarningMsg = useMemo(() => {
    const isDrivingCostPage = pathname === '/craftsman-settings/driving-costs';

    return (
      isCraftsman && !isDrivingCostPage && !craftsmanDrivingCostSettings.length
    );
  }, [craftsmanDrivingCostSettings.length, isCraftsman, pathname]);

  const headerUserMenu = useMemo(() => {
    if (!user) {
      return [];
    }

    const items: MenuProps['items'] = [];

    items.push({
      key: 'logout',
      label: t('profile.log_out'),
      onClick: logout
    });

    return items;
  }, [user, t, logout]);

  const rightMenu = useMemo(() => {
    return (
      <>
        <LanguageSwitcher
          onClick={() => {
            const lng = selectedLanguage === 'en' ? 'de' : 'en';
            setLanguage(lng);
            i18n.changeLanguage(lng);
          }}
        >
          <Globus />
          <span>{selectedLanguage.toUpperCase()}</span>
        </LanguageSwitcher>
      </>
    );
  }, [i18n, selectedLanguage, setLanguage]);

  return (
    <>
      <Header menu={headerUserMenu} rightMenuItems={rightMenu} />
      <Wrapper>
        <Container>
          {showStripeWarningMsg && <NoStripeConnected />}
          {showDrivingCostWarningMsg && <NoDrivingCost />}

          <Row gutter={[8, 0]} wrap={false}>
            {layoutSettings?.navigation?.display && (
              <Col md="216px" style={{ maxWidth: 216 }}>
                <StyledCard>
                  <Navigation navigation={navigation} />
                </StyledCard>
              </Col>
            )}
            <Col flex={1}>
              <Suspense fallback={<Loading />}>{appRoutes}</Suspense>
            </Col>
          </Row>
        </Container>
      </Wrapper>

      {adminRole !== AUTH_ROLES_ENUM.ADMIN && <Feedback />}
    </>
  );
};

export default BoxLayout;
