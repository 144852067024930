import { gql } from '@apollo/client';

import { VARIATION_FRAGMENT, VARIATION_LIST_FRAGMENT } from './fragments';

export const GET_VARIATIONS = gql`
  query variations($query: SearchProductsInput) {
    variations(query: $query) {
      data {
        results {
          ...ProductVariationsListFragment
        }
        total
      }
      message
      status
    }
  }
  ${VARIATION_LIST_FRAGMENT}
`;

export const GET_VARIATIONS_BY_ID = gql`
  query variationById($id: ID!) {
    variationById(id: $id) {
      data {
        ...ProductVariationFragment
      }
      message
      status
    }
  }
  ${VARIATION_FRAGMENT}
`;

export const GET_VARIATIONS_SERVICES = gql`
  query getVariationServices($id: ID!) {
    getVariationServices(id: $id) {
      data {
        services {
          id
          name
          duration
          groupId
        }
        serviceGroups {
          id
          name
          services {
            id
            name
            duration
            groupId
          }
        }
      }
      message
      status
    }
  }
`;

export const VARIATIONS_IMAGES = gql`
  query variationImages($id: Int) {
    variationImages(id: $id) {
      data {
        muster
        images {
          id
          pr_id
          variation_id
          filename
          originName
          main
          sortIndex
        }
      }
      message
      status
    }
  }
`;
