import React, { RefObject, useRef } from 'react';
import { FormItem, Select } from '@stylique/core';
import { useTranslation } from 'react-i18next';

const MetaKeywords = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);

  return (
    <div ref={parentRef}>
      <FormItem
        name={['seo_data', 'meteKeywords']}
        label={t('website_menu.meta_keywords')}
      >
        <Select
          getPopupContainer={() => parentRef.current as HTMLElement}
          mode="tags"
          style={{ fontSize: '14px', margin: '0' }}
          placeholder={t('website_menu.meta_keywords')}
          options={[]}
          size="middle"
        />
      </FormItem>
    </div>
  );
};

export default MetaKeywords;
