import { gql } from '@apollo/client';
import { VARIATION_FRAGMENT } from 'graphql/variationsProducts/fragments';

import {
  NETWORK_REQUEST_FRAGMENT,
  NETWORK_VENDOR_PRODUCTS_FRAGMENT
} from './fragments';

export const GET_SENT_REQUESTS = gql`
  query getSentRequests($query: SearchSentRequestsInput) {
    getSentRequests(query: $query) {
      data {
        total
        results {
          ...NetworkRequestFragment
        }
      }
      status
    }
  }
  ${NETWORK_REQUEST_FRAGMENT}
`;

export const GET_RECEIVED_REQUESTS = gql`
  query getReceivedRequests($query: SearchSentRequestsInput) {
    getReceivedRequests(query: $query) {
      data {
        total
        results {
          ...NetworkRequestFragment
        }
      }
      status
    }
  }
  ${NETWORK_REQUEST_FRAGMENT}
`;

export const GET_APPROVED_REQUESTS = gql`
  query getApprovedRequests($query: SearchSentRequestsInput) {
    getApprovedRequests(query: $query) {
      data {
        total
        results {
          ...NetworkRequestFragment
        }
      }
    }
  }
  ${NETWORK_REQUEST_FRAGMENT}
`;

export const GET_MANUFACTURERS = gql`
  query getManufacturers($query: SearchNetworkInput) {
    getManufacturers(query: $query) {
      data {
        total
        results {
          id
          email
          company_name
          sellerType
          avatar
          canSendRequest
          profile {
            id
            short_name
            company_logo
            description
          }
        }
      }
      status
    }
  }
`;

export const GET_ARCHITECTS = gql`
  query getArchitects($query: SearchNetworkInput) {
    getArchitects(query: $query) {
      data {
        total
        results {
          id
          email
          name
          sellerType
          canSendRequest
          profile {
            id
            short_name
            company_logo
            description
          }
        }
      }
      status
    }
  }
`;

export const GET_CRAFTSMEN = gql`
  query getCraftsmen($query: SearchNetworkInput) {
    getCraftsmen(query: $query) {
      data {
        total
        results {
          id
          email
          name
          sellerType
          canSendRequest
          profile {
            id
            short_name
            company_logo
            description
          }
        }
      }
      status
    }
  }
`;

export const GET_VENDORS = gql`
  query getVendors($query: SearchNetworkInput) {
    getVendors(query: $query) {
      data {
        total
        results {
          id
          email
          name
          sellerType
          userRole
          canSendRequest
          profile {
            id
            short_name
            company_logo
            description
          }
        }
      }
      status
    }
  }
`;

export const GET_NETWORK_MANUFACTURER_PRODUCTS = gql`
  query getNetworkManufacturerProducts($query: SearchNetworkProductsInput) {
    getNetworkManufacturerProducts(query: $query) {
      data {
        total
        results {
          ...ProductVariationFragment
        }
      }
      status
    }
  }
  ${VARIATION_FRAGMENT}
`;

export const GET_NETWORK_VENDOR_PRODUCTS = gql`
  query getNetworkVendorProducts($query: SearchNetworkVendorProductsInput) {
    getNetworkVendorProducts(query: $query) {
      data {
        total
        results {
          ...NetworkVendorProductsFragment
        }
      }
      status
    }
  }
  ${NETWORK_VENDOR_PRODUCTS_FRAGMENT}
`;
