import React from 'react';

import ForgotPassword from './index';

const ForgotPasswordConfig = {
  settings: {
    layout: {
      navigation: {
        display: false
      }
    }
  },
  auth: [],
  routes: [
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    }
  ]
};

export default ForgotPasswordConfig;
