import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormItem, Loading, Select } from '@stylique/core';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { paginate } from '@stylique/tools';
import { variationAtributeValueGroups_variationAtributeValueGroups_data_results as AttrValueGroups } from '__generated__/variationAtributeValueGroups';
import { VARIATION_ATTRIBUTE_VALUE_GROUPS } from 'graphql/attributeGroups/queries';

import debounce from 'utils/debounce';

const groupLimit = 2000; // TODO: if performance issue occurs, change this value

const ByAttributeValueGroup = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const isPagingStopped = useRef(false);
  const form = Form.useFormInstance();
  const isChecked = Form.useWatch('attributeValuesCheckbox', form);
  const [currentPage, setCurrentPage] = useState(0);
  const [name, setName] = useState('');

  console.log(form.getFieldValue('attributeValues'));

  const [
    getVariationAttributeValueGroups,
    {
      data: {
        variationAtributeValueGroups: {
          data: { results: attributeValueGroups = [] } = {}
        } = {}
      } = {},
      loading: loading,
      fetchMore
    }
  ] = useLazyQuery(VARIATION_ATTRIBUTE_VALUE_GROUPS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    getVariationAttributeValueGroups({
      variables: {
        query: {
          ...paginate(groupLimit, 0),
          key: ''
        }
      }
    });
  }, []);

  const handleSearch = debounce((name: string) => {
    getVariationAttributeValueGroups({
      variables: {
        query: {
          ...paginate(groupLimit, 0),
          key: name
        }
      }
    });
    setCurrentPage(1);
    setName(name);
  }, 400);

  const onPopupScroll = useCallback(
    async e => {
      e.persist();
      const target = e.target;

      if (
        !isPagingStopped.current &&
        !loading &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        await fetchMore({
          variables: {
            query: {
              ...paginate(groupLimit, currentPage + 1),
              key: name
            }
          },
          updateQuery(prevResult, { fetchMoreResult }) {
            // if last page data stop paging
            if (fetchMoreResult.length < groupLimit) {
              isPagingStopped.current = true;
            }

            if (!fetchMoreResult) return prevResult;

            return {
              ...fetchMoreResult,
              variationAtributeValueGroups: {
                ...fetchMoreResult?.variationAtributeValueGroups,
                data: {
                  ...fetchMoreResult?.variationAtributeValueGroups?.data,
                  results: [
                    ...prevResult?.variationAtributeValueGroups?.data?.results,
                    ...fetchMoreResult?.variationAtributeValueGroups?.data
                      ?.results
                  ]
                }
              }
            };
          }
        }).then(() => {
          setCurrentPage(page => page + 1);
        });
      }
    },
    [currentPage, fetchMore, loading, name]
  );

  const options = useMemo(() => {
    return attributeValueGroups
      .filter(Boolean)
      .map((group: AttrValueGroups) => ({
        value: group.id,
        label: group.name
      }));
  }, [attributeValueGroups]);

  return (
    <div ref={parentRef}>
      <FormItem valuePropName="checked" name="attributeValuesCheckbox">
        <Checkbox style={{ fontSize: '14px' }}>
          {t('website_menu.by_attribute_value_groups')}
        </Checkbox>
      </FormItem>

      {!!isChecked && (
        <FormItem name="attributeValues">
          <Select
            loading={loading}
            allowClear
            mode="multiple"
            style={{ fontSize: '14px', margin: '0' }}
            placeholder={t('website_menu.by_attribute_value_placeholder')}
            options={options}
            onSearch={handleSearch}
            size="middle"
            getPopupContainer={() => parentRef.current as HTMLElement}
            onPopupScroll={onPopupScroll}
            optionFilterProp="label"
          />
        </FormItem>
      )}
    </div>
  );
};

export default ByAttributeValueGroup;
