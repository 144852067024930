import { gql } from '@apollo/client';

export const VARIATION_LIST_FRAGMENT = gql`
  fragment ProductVariationsListFragment on ProductVariations {
    id
    sku
    name
    status
    image {
      filename
    }

    combinations {
      id
      option {
        id
        name
      }
      attribute {
        id
        name
      }
    }
    category {
      id
      data {
        id
        slug
        name
      }
    }
    parent {
      id
      name
    }
  }
`;

export const VARIATION_FRAGMENT = gql`
  fragment ProductVariationFragment on ProductVariations {
    id
    name
    price
    sku
    slug
    status
    is_simple_product
    images {
      filename
      main
    }
    image {
      filename
    }
    combinations {
      id
      option {
        id
        name
      }
      attribute {
        id
        name
      }
    }
    category {
      id
      data {
        id
        slug
        name
      }
    }
    parent {
      id
      name
      family_id
      type
    }
    variationAttributes {
      id
      group_id
      attr_id
      value
      attribute {
        id
        name
        is_required
      }
      group {
        id
        slug
      }
    }
    tags {
      id
      data {
        id
        name
      }
    }
  }
`;
