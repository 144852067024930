import React from 'react';
import { Outlet } from 'react-router-dom';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import StylesProvider from 'providers/Styles';

const Sections = React.lazy(() => import('../admin/Sections'));
const Styles = React.lazy(() => import('./index'));
const StylesDetails = React.lazy(() => import('./StyleDetails'));
const StylesDetailsProducts = React.lazy(
  () => import('./StylesDetailsProducts')
);

const StylesAffiliateProducts = React.lazy(() => import('./AffiliateProducts'));

const StylesConfig = {
  settings: {
    layout: {}
  },
  auth: [
    AUTH_ROLES_ENUM.ADMIN,
    AUTH_ROLES_ENUM.VENDOR,
    AUTH_ROLES_ENUM.CRAFTSMAN,
    AUTH_ROLES_ENUM.ARCHITECT,
    AUTH_ROLES_ENUM.ACCOUNT_PRO,
    AUTH_ROLES_ENUM.ACCOUNT_BASIC,
    AUTH_ROLES_ENUM.MANUFACTURE
  ],
  routes: [
    {
      path: 'styles',
      element: <Outlet />,
      children: [
        {
          path: 'list',
          element: <Styles />
        },
        {
          path: 'details',
          element: (
            <StylesProvider>
              <Outlet />
            </StylesProvider>
          ),
          children: [
            {
              path: '',
              element: <StylesDetails />
            },
            {
              path: ':id',
              element: <StylesDetails />
            },
            {
              path: ':id/products',
              element: <StylesDetailsProducts />
            },
            {
              path: ':id/affiliate-products',
              element: <StylesAffiliateProducts />
            },
            {
              path: 'products',
              element: <StylesDetailsProducts />
            }
          ]
        },
        {
          auth: [AUTH_ROLES_ENUM.ADMIN],
          path: 'sections',
          element: <Sections />
        }
      ]
    }
  ]
};

export default StylesConfig;
