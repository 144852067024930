import React, { FC, useMemo } from 'react';
import { Checkbox, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import client from 'apolloClient';
import { AttributeFamilyFragment_family } from '__generated__/AttributeFamilyFragment';
import { FormItem, ImageUpload, Input } from '@stylique/core';
import {
  useProductDispatchContext,
  useProductStateContext
} from 'providers/Product';
import { ATTRIBUTE_FAMILY_FRAGMENT } from 'graphql/attributeFamilies/fragments';

import RenderField from './RenderField';
import TagSelect from 'components/TagSelect';

const { TabPane } = Tabs;

interface Props {
  isMultiVariation?: boolean;
}

const TabContainer = styled.div<{ inPopup?: boolean }>`
  ${({ inPopup }) =>
    inPopup
      ? css`
          padding: 32px 88px;
          background-color: --var(gray-8);
        `
      : ''}
`;

const TabsComponent: FC<Props> = ({ isMultiVariation = false }) => {
  // translations
  const { t } = useTranslation('admin');
  // context
  const { attributeFamily, variationImages } = useProductStateContext();
  const { setVariationImages } = useProductDispatchContext();

  // gql
  const family = client.readFragment({
    id: client.cache.identify({
      id: attributeFamily,
      __typename: 'AttributeFamily'
    }),
    fragment: ATTRIBUTE_FAMILY_FRAGMENT,
    fragmentName: 'AttributeFamilyFragment'
  });

  const handleChange = (images: string | string[]) => {
    setVariationImages(
      (images as string[]).map((image, index) => ({
        filename: image,
        main: !index
      }))
    );
  };

  const onMainImageSelect = (selected: number) => {
    const newImages = variationImages.map(({ filename }, index) => {
      if (index === selected) {
        return { filename, main: true };
      }

      return { filename };
    });

    setVariationImages(newImages);
  };

  const defaultSelectedImage = useMemo(() => {
    if (variationImages) {
      return variationImages.findIndex(item => item?.main);
    }

    return -1;
  }, [variationImages]);

  return (
    <Tabs style={{ margin: '0 -10px' }}>
      <TabPane
        tab={t('products.product_details')}
        key="tab-item-details"
        forceRender
      >
        <TabContainer inPopup={isMultiVariation}>
          {isMultiVariation && (
            <FormItem
              name="is_simple_product"
              valuePropName="checked"
              rules={[{ required: false }]}
            >
              <Checkbox>{t('products.as_simple_product')}</Checkbox>
            </FormItem>
          )}
          <FormItem
            label={t('products.name_title')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label={t('slug_name', { ns: 'common' })}
            name="slug"
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <FormItem label="sku" name="sku" rules={[{ required: true }]}>
            <Input />
          </FormItem>
          <FormItem
            name="price"
            label={t('products.price')}
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <TagSelect label={t('products.tags')} name="tags" type="product" />
        </TabContainer>
      </TabPane>
      {family?.family?.map((item: AttributeFamilyFragment_family) => {
        if (!item.groupAttributes?.length) {
          return null;
        }

        return (
          <TabPane
            tab={item.familyGroup?.name}
            key={`tab-item-${item.id}`}
            forceRender
          >
            <TabContainer inPopup={isMultiVariation}>
              {item.groupAttributes.map(attribute => {
                return (
                  <RenderField
                    key={`product-section-field-${item.id}-${attribute?.id}`}
                    id={item.familyGroup?.id || 0}
                    name={attribute?.attribute?.id || ''}
                    label={attribute?.attribute?.name || ''}
                    type={attribute?.attribute?.type || ''}
                    isRequired={attribute?.attribute?.is_required || false}
                    options={attribute?.attribute?.options?.map(item => ({
                      label: item?.name || '',
                      value: item?.id || ''
                    }))}
                  />
                );
              })}
            </TabContainer>
          </TabPane>
        );
      })}
      <TabPane tab={t('variations.images')} key="images">
        <TabContainer inPopup={isMultiVariation}>
          <ImageUpload
            multiple
            onChange={handleChange}
            initialValues={variationImages.map(image => image.filename)}
            selectedData={{
              isSelectable: true,
              onItemSelect: onMainImageSelect,
              defaultSelected: defaultSelectedImage
            }}
          />
        </TabContainer>
      </TabPane>
    </Tabs>
  );
};

export default TabsComponent;
