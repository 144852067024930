import React, { FC } from 'react';
import { Row, Col, Grid } from 'antd';
import styled, { css } from 'styled-components';

interface Props {
  children?: React.ReactNode;
  topSpace?: number;
  fluid?: boolean;
}

const Wrapper = styled.div<{
  isMobile?: boolean;
  topSpace?: number;
  fluid?: boolean;
}>`
  padding: ${({ isMobile, fluid }) =>
    fluid ? 0 : isMobile ? '0 16px' : '0 12px'};
  width: 100%;
  overflow-x: hidden;
  ${({ topSpace }) =>
    topSpace &&
    css`
      padding-top: ${topSpace}px !important;
    `}
  .ant-col {
    &:hover {
      z-index: 1;
    }
  }
`;

const { useBreakpoint } = Grid;

const Container: FC<Props> = ({ children, topSpace, fluid }) => {
  const screens = useBreakpoint();

  if (fluid) {
    return (
      <Wrapper topSpace={topSpace} fluid>
        <Row justify="center" wrap={false}>
          <Col flex={1} style={{ width: 'calc(100vw - 60px)' }}>
            {children}
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (screens.xxl || screens.xl) {
    return (
      <Wrapper topSpace={topSpace}>
        <Row justify="center" wrap={false} gutter={24}>
          <Col flex="1444px" style={{ width: '1480px' }}>
            {children}
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (screens.lg) {
    return (
      <Wrapper topSpace={topSpace}>
        <Row justify="center" wrap={false} gutter={24}>
          <Col flex="992px" style={{ width: '992px' }}>
            {children}
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (screens.sm) {
    return (
      <Wrapper topSpace={topSpace}>
        <Row justify="center" wrap={false} gutter={24}>
          <Col flex="762px" style={{ width: '762px' }}>
            {children}
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (screens.xs) {
    return (
      <Wrapper isMobile={true}>
        <Row justify="center" wrap={false} gutter={32}>
          <Col flex="auto" style={{ width: '100%' }}>
            {children}
          </Col>
        </Row>
      </Wrapper>
    );
  }

  return (
    <Wrapper topSpace={topSpace}>
      <Row justify="center" wrap={false}>
        <Col>{children}</Col>
      </Row>
    </Wrapper>
  );
};

export default Container;
