import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Light: FC<Props> = props => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_5187_5016)">
      <path
        d="M7.60742 11.0357H4.39314C4.33421 11.0357 4.28599 11.0839 4.28599 11.1429V11.5714C4.28599 11.8085 4.47751 12 4.71457 12H7.28599C7.52305 12 7.71457 11.8085 7.71457 11.5714V11.1429C7.71457 11.0839 7.66635 11.0357 7.60742 11.0357ZM6.00028 0C3.57483 0 1.60742 1.96741 1.60742 4.39286C1.60742 6.01875 2.49135 7.43839 3.80385 8.19777V9.75C3.80385 9.98705 3.99537 10.1786 4.23242 10.1786H7.76814C8.00519 10.1786 8.19671 9.98705 8.19671 9.75V8.19777C9.50921 7.43839 10.3931 6.01875 10.3931 4.39286C10.3931 1.96741 8.42573 0 6.00028 0ZM7.71323 7.36339L7.23242 7.64196V9.21429H4.76814V7.64196L4.28733 7.36339C3.23331 6.75402 2.57171 5.6317 2.57171 4.39286C2.57171 2.49911 4.10653 0.964286 6.00028 0.964286C7.89403 0.964286 9.42885 2.49911 9.42885 4.39286C9.42885 5.6317 8.76724 6.75402 7.71323 7.36339Z"
        fill="black"
        fillOpacity="0.85"
      />
    </g>
    <defs>
      <clipPath id="clip0_5187_5016">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </SVG>
);

export default Light;
