import React, { FC } from 'react';
import { Space } from 'antd';
import { Button } from '@stylique/core';
import { useTranslation } from 'react-i18next';

import { StyledBulkActionBar, StyledCount } from '../styles';
import { TPropsBulkActions } from '../types';
import useUser from 'hooks/useUser';

const BulkActionBar: FC<TPropsBulkActions> = ({
  selectedRowCount,
  onBulkActionSubmit,
  loading
}) => {
  const { t } = useTranslation('common');
  const { isManufacturer } = useUser();

  const handleApplyClick = (value: string) => {
    onBulkActionSubmit(value as string);
  };

  return (
    <StyledBulkActionBar>
      <Space size={20} direction="horizontal">
        <StyledCount>{`${selectedRowCount} products Selected`}</StyledCount>
        {!isManufacturer ? (
          <Button
            loading={loading}
            size="small"
            onClick={() => handleApplyClick('applyService')}
            disabled={!selectedRowCount}
            style={{
              border: '1px solid var(--normal-color)',
              color: 'var(--text-color)',
              padding: '2px 16px',
              borderRadius: '4px',
              height: 26,
              fontSize: '14px'
            }}
          >
            {t('apply_craftsman_service')}
          </Button>
        ) : null}
        <Button
          loading={loading}
          size="small"
          onClick={() => handleApplyClick('publish')}
          disabled={!selectedRowCount}
          style={{
            border: '1px solid var(--normal-color)',
            color: 'var(--text-color)',
            padding: '2px 16px',
            borderRadius: '4px',
            height: 26,
            fontSize: '14px'
          }}
        >
          {t('publish_selected')}
        </Button>
        <Button
          loading={loading}
          size="small"
          onClick={() => handleApplyClick('draft')}
          disabled={!selectedRowCount}
          style={{
            border: '1px solid var(--normal-color)',
            color: 'var(--text-color)',
            padding: '2px 16px',
            borderRadius: '4px',
            height: 26,
            fontSize: '14px'
          }}
        >
          {t('set_draft')}
        </Button>
        <Button
          loading={loading}
          size="small"
          onClick={() => handleApplyClick('delete')}
          disabled={!selectedRowCount}
          style={{
            border: '1px solid var(--normal-color)',
            color: 'var(--text-color)',
            padding: '2px 16px',
            borderRadius: '4px',
            height: 26,
            fontSize: '14px'
          }}
        >
          {t('delete_selected')}
        </Button>
      </Space>
    </StyledBulkActionBar>
  );
};

export default BulkActionBar;
