import { PlusOutlined } from '@ant-design/icons';
import { PageHeader, Button, Space } from 'antd';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
  productTypeKeys: {
    translation: string;
    route: string;
  };
}

const Header: FC<IProps> = ({ productTypeKeys }) => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();

  const title = useMemo(
    () =>
      productTypeKeys.translation === 'products'
        ? t('navigation.parents', { ns: 'common' })
        : t(`${productTypeKeys.translation}.title`),
    [productTypeKeys.translation, t]
  );

  return (
    <PageHeader
      ghost={false}
      style={{ padding: '38px 0 22px 0' }}
      title={title}
      extra={[
        <Button
          key="add_new_settings"
          type="primary"
          size="large"
          style={{ fontSize: '16px', borderRadius: '2px' }}
          form="inputs"
          htmlType="submit"
          loading={false}
          onClick={() => {
            if (productTypeKeys.route === 'sample-boxes') {
              navigate(`/products/sample-boxes/details`);
            } else {
              navigate(`/${productTypeKeys.route}/parents/details`);
            }
          }}
        >
          <Space size={10}>
            <span>{t(`${productTypeKeys.translation}.add_new`)}</span>
            <PlusOutlined />
          </Space>
        </Button>
      ]}
    />
  );
};

export default Header;
