import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { paginate } from '@stylique/tools';
import { UNLINK_FROM_SAMPLE_BOX } from 'graphql/products/mutations';
import { GET_LINKED_PRODUCTS } from 'graphql/products/queries';
import { getSampleBoxProducts as SampleBoxType } from '__generated__/getSampleBoxProducts';
import { useAuth } from 'auth';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { UserResponse } from 'auth/types';
import { variationById_variationById_data } from '__generated__/variationById';

import useQueryParams from 'hooks/useQueryParams';
import { GET_VARIATIONS } from '../../graphql/variationsProducts/queries';
import {
  DELETE_VARIATION,
  ALL_DELETE_VARIATION,
  LINK_TO_SAMPLE_BOX,
  PUBLISH_VARIATIONS,
  APPLY_VARIATIONS_SERVICES,
  UPDATE_PRODUCT_VARIATIONS_STATUS,
  UPDATE_PRODUCT_VARIATION_IMAGES
} from '../../graphql/variationsProducts/mutations';
import { IVariationProps, VariationQueryType } from './types';
import Filters from './components/Filters';
import ServicesPopup from './components/ServicesPopup';
import BulkActionBar from './components/BulkActionBar';
import { StyledCard } from './styles';
import { genUserType } from 'pages/ParentsProducts/utils';
import VariationsTable from './components/VariationsTable';
import VariationsHeader from './components/VariationsHeader';
import AddImagePopup from './components/AddImagePopup';
import useVariationsTable from './hooks/useVariationsTableColumns';

const Products: FC<IVariationProps> = () => {
  const { t } = useTranslation('admin');
  const user = useAuth(state => state.user);
  const userType = useMemo(() => genUserType(user as UserResponse), [user]);
  const { setParams, getParams, getParam } = useQueryParams();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isLinkingProducts = pathname.includes('variations/link-samples');
  const [linked, setLinked] = useState<number[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [variationId, setVariationId] = useState<number | null>(0);
  const [addImagePopup, setAddImagePopup] = useState({
    isVisible: false,
    data: null
  });

  const [isVisibleServicesPopup, setIsVisibleServicesPopup] =
    useState<boolean>(false);

  const {
    skuParam,
    nameParam,
    parentNameParam,
    statusParam,
    manufacturerIdParam,
    categoriesParam,
    currentPageParam,
    currentPageSizeParam,
    variationTypeParam,
    serviceInfoParam,
    serviceFilterParam
  } = useMemo(() => {
    return {
      skuParam: getParam('sku') || undefined,
      nameParam: getParam('name') || undefined,
      parentNameParam: getParam('parent_name') || '',
      statusParam: getParam('status') || '',
      serviceFilterParam: getParam('serviceFilter') || 'all',
      manufacturerIdParam: getParam('manufacturer_id') || '',
      categoriesParam: getParam('categories')
        ?.split(',')
        ?.filter(item => item),
      currentPageParam: Number(getParams(['page'])[0].page || 0),
      currentPageSizeParam: Number(getParams(['pageSize'])[0].pageSize || 10),
      variationTypeParam: getParam('variationType'),
      serviceInfoParam: getParam('serviceInfo') || null
    };
  }, [getParam, getParams]);

  const closePopup = () => {
    setIsVisibleServicesPopup(false);
    setVariationId(0);
    setAddImagePopup({
      isVisible: false,
      data: null
    });
  };

  const [
    getVariations,
    {
      data: {
        variations: { data: { results = [], total = 0 } = {} } = {}
      } = {},
      loading
    }
  ] = useLazyQuery<VariationQueryType>(GET_VARIATIONS, {
    fetchPolicy: 'no-cache'
  });

  const fetchData = useCallback(async () => {
    await getVariations({
      variables: {
        query: {
          ...paginate(currentPageSizeParam, currentPageParam),
          name: nameParam,
          sku: skuParam,
          parent_name: parentNameParam,
          status: statusParam,
          serviceFilter: serviceFilterParam,
          manufacturer_id: manufacturerIdParam,
          categories: categoriesParam,
          variationType: variationTypeParam || 'all',
          serviceInfo: serviceInfoParam || undefined
        }
      }
    });
  }, [
    categoriesParam,
    currentPageParam,
    currentPageSizeParam,
    getVariations,
    manufacturerIdParam,
    nameParam,
    parentNameParam,
    serviceInfoParam,
    skuParam,
    statusParam,
    serviceFilterParam,
    variationTypeParam
  ]);

  useEffect(() => {
    if (userType === AUTH_ROLES_ENUM.MANUFACTURE) {
      setParams([
        {
          key: 'manufacturer_id',
          value: String(user?.id)
        }
      ]);
    }
  }, [setParams, user?.id, userType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [deleteVariation, { loading: isDeleteVariation }] = useMutation(
    DELETE_VARIATION,
    {
      onError() {
        closePopup();
      }
    }
  );

  const [variationDeleteByIds, { loading: isAllDeleteVariation }] =
    useMutation(ALL_DELETE_VARIATION);

  const [linkProduct] = useMutation(LINK_TO_SAMPLE_BOX);
  const [unlinkProduct] = useMutation(UNLINK_FROM_SAMPLE_BOX);
  const [applyVariationsServices] = useMutation(APPLY_VARIATIONS_SERVICES);
  const [
    updateProductVariationsStatus,
    { loading: isUpdateProductVariationsStatus }
  ] = useMutation(UPDATE_PRODUCT_VARIATIONS_STATUS);

  const [
    updateProductVariationImages,
    { loading: isUpdateProductVariationImages }
  ] = useMutation(UPDATE_PRODUCT_VARIATION_IMAGES);

  const [publishMultiple, { loading: isPublishing }] = useMutation(
    PUBLISH_VARIATIONS,
    {
      onCompleted() {
        message.success(
          t('messages.successfully_product_published', { ns: 'common' })
        );
        setSelected([]);
        fetchData();
      }
    }
  );

  useQuery<SampleBoxType>(GET_LINKED_PRODUCTS, {
    variables: {
      id
    },
    skip: !isLinkingProducts,
    onCompleted(data) {
      if (data.getSampleBoxProducts?.data) {
        setLinked(
          data.getSampleBoxProducts?.data.map(item => item?.parent_id || 0)
        );
      }
    }
  });

  const allDeletePressed = (ids: number[]) => {
    Modal.confirm({
      title: t('confirm', { ns: 'common' }),
      content: t('sure', { ns: 'common' }),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await variationDeleteByIds({
          variables: {
            ids
          }
        }).catch(() => {
          setSelected([]);
          closePopup();
        });

        fetchData();
      }
    });
  };

  const deletePressed = useCallback(
    (id: number) => {
      Modal.confirm({
        title: t('confirm', { ns: 'common' }),
        content: t('sure', { ns: 'common' }),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        onOk: async () => {
          await deleteVariation({
            variables: {
              id
            }
          });
          fetchData();
        }
      });
    },
    [deleteVariation, fetchData, t]
  );

  const handleLinkProduct = useCallback(
    async (parentId: number) => {
      if (linked.includes(parentId)) {
        await unlinkProduct({
          variables: {
            input: { sample_box_id: Number(id), parent_id: parentId }
          }
        });

        return setLinked(linked.filter(item => item !== parentId));
      }

      await linkProduct({
        variables: { input: { sample_box_id: Number(id), parent_id: parentId } }
      });
      setLinked([...linked, parentId]);
    },
    [linked, linkProduct, id, unlinkProduct]
  );

  const onPopupSubmit = useCallback(
    async (
      input,
      serviceGroupIds?: string[] | number[],
      serviceIds?: string[] | number[]
    ) => {
      await applyVariationsServices({
        variables: {
          input: {
            groupIds: serviceGroupIds,
            serviceIds: serviceIds,
            ids: variationId ? [variationId] : selected
          }
        }
      });

      await fetchData();
      setSelected([]);

      closePopup();
    },
    [applyVariationsServices, fetchData, selected, variationId]
  );

  const onBulkActionSubmit = async (bulkAction: string) => {
    if (bulkAction === 'applyService') {
      setIsVisibleServicesPopup(true);
    }

    if (bulkAction === 'publish') {
      await publishMultiple({ variables: { input: { ids: selected } } });
      setSelected([]);
      await fetchData();
    }

    if (bulkAction === 'delete') {
      await allDeletePressed(selected);
    }

    if (bulkAction === 'draft') {
      await updateProductVariationsStatus({
        variables: {
          input: {
            ids: selected,
            status: '2'
          }
        }
      }).then(() =>
        message.success(
          t('messages.successfully_product_set_draft', { ns: 'common' })
        )
      );
      setSelected([]);
      await fetchData();
    }
  };

  const onSubmitAddImage = useCallback(
    async data => {
      await updateProductVariationImages({
        variables: {
          id: data.id,
          input: {
            images: data.images,
            updateMuster: data.muster
          }
        }
      });
      closePopup();
      await fetchData();
    },
    [fetchData, updateProductVariationImages]
  );

  const columns = useVariationsTable(
    setIsVisibleServicesPopup,
    setVariationId,
    setAddImagePopup,
    updateProductVariationsStatus,
    fetchData,
    deletePressed,
    isLinkingProducts,
    linked,
    handleLinkProduct
  );

  return (
    <StyledCard>
      <VariationsHeader
        userType={userType as AUTH_ROLES_ENUM}
        isLinkingProducts={isLinkingProducts}
      />
      <Filters userType={userType as AUTH_ROLES_ENUM} />
      <BulkActionBar
        selectedRowCount={selected.length}
        onBulkActionSubmit={onBulkActionSubmit}
        loading={false}
      />
      <VariationsTable
        columns={columns}
        results={results as variationById_variationById_data[]}
        total={total}
        loading={
          loading ||
          isPublishing ||
          isDeleteVariation ||
          isAllDeleteVariation ||
          isUpdateProductVariationsStatus ||
          isUpdateProductVariationImages
        }
        selected={selected}
        setSelected={setSelected}
      />
      {userType === AUTH_ROLES_ENUM.ADMIN && isVisibleServicesPopup && (
        <ServicesPopup
          id={variationId}
          isVisible={isVisibleServicesPopup}
          serviceData={[]}
          onClose={closePopup}
          onSubmit={onPopupSubmit}
        />
      )}

      <AddImagePopup
        isVisible={addImagePopup.isVisible}
        data={addImagePopup.data}
        onClose={closePopup}
        onSubmit={onSubmitAddImage}
      />
    </StyledCard>
  );
};

export default Products;
