import React, { FC, FormEvent, useMemo, useRef } from 'react';
import { Col, Row, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItem, Input, Select } from '@stylique/core';
import { SearchOutlined } from '@ant-design/icons';
import { GET_CATEGORIES_WITH_SUBS } from 'graphql/categories/queries';
import { GET_MANUFACTURERS } from 'graphql/manufacturers/queries';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { useQuery } from '@apollo/client';
import { SERVICE_INFO } from 'graphql/services/queries';
import { ServiceFilterEnum } from '__generated__/globalTypes';

import { formatThreeSelectCategories } from 'utils/helpers';
import useQueryParams from 'hooks/useQueryParams';
import useManufacturersOptions from 'hooks/useManufacturersOptions';

interface IProps {
  userType: AUTH_ROLES_ENUM;
}

const Filters: FC<IProps> = ({ userType }) => {
  const { setParams, getParam, deleteParams } = useQueryParams();
  const { t } = useTranslation('common');
  const timer = useRef<NodeJS.Timeout | null>(null);
  const isAdmin = userType === AUTH_ROLES_ENUM.ADMIN;

  const { data: { categories: { data: categories = [] } = {} } = {} } =
    useQuery(GET_CATEGORIES_WITH_SUBS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const { data: { manufacturers: { data: manufacturers = [] } = {} } = {} } =
    useQuery(GET_MANUFACTURERS, {
      fetchPolicy: 'no-cache'
    });

  const { data: { serviceInfo: { data: serviceInfoData = [] } = {} } = {} } =
    useQuery(SERVICE_INFO, {
      fetchPolicy: 'no-cache'
    });

  const manufacturersData = useManufacturersOptions(manufacturers);

  const serviceInfoOptions = useMemo(() => {
    return serviceInfoData.map((serviceInfo: string) => {
      return {
        value: serviceInfo,
        label: serviceInfo
      };
    });
  }, [serviceInfoData]);

  const handleSearchByName = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setParams([
        { key: 'name', value },
        { key: 'page', value: '0' }
      ]);
    }, 700);
  };

  const handleSearchByParent = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!value.length) {
      deleteParams(['parent_name']);
    } else {
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setParams([
          { key: 'parent_name', value },
          { key: 'page', value: '0' }
        ]);
      }, 700);
    }
  };

  const handleChangeCategory = (values: string[], _: any, c: any) => {
    if (c.preValue.length === 1 && values.length === 0) {
      deleteParams(['categories']);
    } else {
      setParams([
        { key: 'categories', value: values.join() },
        { key: 'page', value: '0' }
      ]);
    }
  };

  const handleChangeStatus = (value: '1' | '2' | '') => {
    setParams([
      { key: 'status', value: value || '' },
      { key: 'page', value: '0' }
    ]);
  };

  const handleChangeServiceFilter = (value: ServiceFilterEnum) => {
    setParams([
      { key: 'serviceFilter', value: value || 'all' },
      { key: 'page', value: '0' }
    ]);
  };

  const handleChangevariationType = (value: 'simple' | 'notSimple' | '') => {
    setParams([
      { key: 'variationType', value: value || '' },
      { key: 'page', value: '0' }
    ]);
  };

  const handleChangeServiceInfo = (value: string) => {
    setParams([
      { key: 'serviceInfo', value: value || '' },
      { key: 'page', value: '0' }
    ]);
  };

  const handleChangeManufacturer = (value: string | number) => {
    if (value !== undefined && value !== 'undefined') {
      setParams([
        { key: 'manufacturer_id', value: value ? String(value) : '' },
        { key: 'page', value: '0' }
      ]);
    }
  };

  const selectedCategories = getParam('categories')
    ?.split(',')
    ?.filter(item => item);

  const selectedserviceFilter = getParam('serviceFilter');
  const selectedStatus = getParam('status');
  const selectedManufacturer = getParam('manufacturer_id');
  const variationType = getParam('variationType') || null;
  const serviceInfoParam = getParam('serviceInfo') || null;
  const SIZE_SM = 6;

  return (
    <Row gutter={[16, 0]}>
      <Col sm={SIZE_SM}>
        <FormItem>
          <Input
            size="middle"
            placeholder={t('filters.search_by_name_and_art_number')}
            prefix={
              <SearchOutlined
                style={{
                  color: '#00000073',
                  fontSize: '14px'
                }}
              />
            }
            onChange={handleSearchByName}
            defaultValue={getParam('name') || ''}
            style={{
              fontSize: '14px'
            }}
          />
        </FormItem>
      </Col>

      <Col sm={SIZE_SM}>
        <FormItem>
          <Input
            placeholder={t('filters.filter_by_parent')}
            prefix={
              <SearchOutlined
                style={{
                  color: '#00000073',
                  fontSize: '14px'
                }}
              />
            }
            style={{
              fontSize: '14px'
            }}
            onChange={handleSearchByParent}
            defaultValue={getParam('parent_name') || ''}
          />
        </FormItem>
      </Col>

      <Col sm={SIZE_SM}>
        <FormItem style={{ minWidth: 250 }}>
          <TreeSelect
            key="categories"
            treeData={formatThreeSelectCategories(categories)}
            placeholder={t('filters.filter_by_category')}
            onChange={handleChangeCategory}
            onClear={() => {
              deleteParams(['categories']);
            }}
            value={categories.length ? selectedCategories : []}
            defaultValue={selectedCategories || []}
            treeNodeFilterProp="title"
            showArrow
            style={{
              fontSize: '14px'
            }}
            size="large"
            multiple
            bordered
            allowClear
          />
        </FormItem>
      </Col>

      <Col sm={SIZE_SM}>
        <FormItem style={{ minWidth: 250 }}>
          <Select
            key="status"
            options={[
              {
                label: t('all', { ns: 'common' }),
                value: ''
              },
              {
                label: t('draft', { ns: 'common' }),
                value: '2'
              },
              {
                label: t('publish', { ns: 'common' }),
                value: '1'
              }
            ]}
            size="large"
            onChange={value => handleChangeStatus(value as '1' | '2' | '')}
            placeholder="Status"
            onClear={() => {
              deleteParams(['status']);
            }}
            defaultValue={selectedStatus}
            allowClear
            bordered
            style={{
              fontSize: '14px'
            }}
          />
        </FormItem>
      </Col>

      {isAdmin && (
        <Col sm={SIZE_SM}>
          <FormItem style={{ minWidth: 250 }}>
            <Select
              placeholder={t('filters.filter_by_brand')}
              key="manufacturers"
              options={manufacturersData}
              size="large"
              onChange={value => handleChangeManufacturer(value as string)}
              value={
                manufacturers?.length ? Number(selectedManufacturer) || [] : []
              }
              onClear={() => {
                deleteParams(['manufacturer_id']);
              }}
              allowClear
              bordered
              style={{
                fontSize: '14px'
              }}
            />
          </FormItem>
        </Col>
      )}

      <Col sm={SIZE_SM}>
        <FormItem style={{ minWidth: 250 }}>
          <Select
            key="variationType"
            options={[
              {
                label: t('sample', { ns: 'common' }),
                value: 'muster'
              },
              {
                label: t('variation', { ns: 'common' }),
                value: 'notMuster'
              }
            ]}
            size="large"
            onChange={value =>
              handleChangevariationType(value as 'simple' | 'notSimple' | '')
            }
            placeholder={t('filters.filter_by_variation_type', {
              ns: 'common'
            })}
            onClear={() => {
              deleteParams(['variationType']);
            }}
            defaultValue={variationType}
            allowClear
            bordered
            style={{
              fontSize: '14px'
            }}
          />
        </FormItem>
      </Col>

      {isAdmin && (
        <Col sm={SIZE_SM}>
          <FormItem style={{ minWidth: 250 }}>
            <Select
              key="servicesInfo"
              options={serviceInfoOptions}
              size="large"
              onChange={value => handleChangeServiceInfo(value as string)}
              placeholder={t('filters.filter_by_service_info', {
                ns: 'common'
              })}
              onClear={() => {
                deleteParams(['serviceInfo']);
              }}
              defaultValue={serviceInfoParam}
              allowClear
              bordered
              style={{
                fontSize: '14px'
              }}
            />
          </FormItem>
        </Col>
      )}

      <Col sm={SIZE_SM}>
        <FormItem style={{ minWidth: 250 }}>
          <Select
            key="serviceFilter"
            options={[
              {
                label: t('all_services', { ns: 'common' }),
                value: 'all'
              },
              {
                label: t('hasService', { ns: 'common' }),
                value: 'hasService'
              },
              {
                label: t('hasNotService', { ns: 'common' }),
                value: 'hasNotService'
              }
            ]}
            size="large"
            onChange={value =>
              handleChangeServiceFilter(value as ServiceFilterEnum)
            }
            placeholder={t('filters.filter_by_service', { ns: 'common' })}
            onClear={() => {
              deleteParams(['serviceFilter']);
            }}
            defaultValue={selectedserviceFilter}
            allowClear
            bordered
            style={{
              fontSize: '14px'
            }}
          />
        </FormItem>
      </Col>
    </Row>
  );
};

export default Filters;
