import { gql } from '@apollo/client';

export const CRAFTSMAN_SETTINGS_FRAGMENT = gql`
  fragment CraftsmanSettingsFragment on ServiceSetting {
    id
    name
    isDefault
    defaultHourlyPrice
    serviceDuration
    serviceDurationType
    minServiceDuration
    maximumDeployableRadius
    minOrderValue
    serviceIds
    groupIds
    userId
    adjustmentType
    adjustmentAmount
    services {
      id
      name
      duration
      groupId
    }
    groups {
      id
      name
    }
  }
`;

export const SERVICE_GROUP_FRAGMENT = gql`
  fragment ServiceGroupFragment on ServiceGroupWithServices {
    id
    name
    services {
      id
      name
      duration
      groupId
    }
  }
`;
