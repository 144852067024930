import * as React from 'react';

function DraggableIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={18}
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 7a2 2 0 100 4 2 2 0 000-4zm0 7a2 2 0 100 4 2 2 0 000-4zm7-10a2 2 0 100-4 2 2 0 000 4zm-7-4a2 2 0 100 4 2 2 0 000-4zm7 14a2 2 0 100 4 2 2 0 000-4zm0-7a2 2 0 100 4 2 2 0 000-4z"
        fill="#999"
      />
    </svg>
  );
}

export default DraggableIcon;
