import AUTH_ROLES_ENUM from 'auth/authRoles';
import { NavigationType } from 'types/navigation';

const MENU_ITEMS: NavigationType[] = [
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/collections',
    label: 'navigation.collections',
    sortIndex: 2,
    children: [
      {
        key: '/collections/categories',
        label: 'navigation.categories'
      },
      {
        key: '/collections/tags',
        label: 'navigation.tags'
      },
      {
        key: 'collections/attribute-families',
        label: 'navigation.attributes_families'
      },
      {
        key: 'collections/attribute-groups',
        label: 'navigation.attributes_groups'
      },
      {
        key: 'collections/attributes',
        label: 'navigation.attributes'
      }
    ]
  },
  // {
  //   auth: [AUTH_ROLES_ENUM.ADMIN],
  //   key: '/attributes/attributes',
  //   label: 'navigation.attributes',
  //   sortIndex: 2,
  //   children: [

  //   ]
  // },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/products',
    label: 'navigation.products',
    sortIndex: 3,
    children: [
      {
        key: '/products/parents',
        label: 'navigation.parents'
      },
      {
        key: '/products/variations',
        label: 'navigation.variations'
      },
      {
        key: '/products/sample-boxes',
        label: 'navigation.sample_boxes'
      }
    ]
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: 'external-product-sources',
    label: 'navigation.affiliates.title',
    sortIndex: 4,
    children: [
      {
        key: '/external-product-sources/networks',
        label: 'navigation.affiliates.networks'
      },
      {
        key: '/external-product-sources/affiliates/products',
        label: 'navigation.affiliates.products'
      },
      {
        key: 'external-product-sources/affiliates/categories',
        label: 'navigation.affiliates.categories'
      }
    ]
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/services',
    label: 'navigation.services',
    sortIndex: 5
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/discounts',
    label: 'navigation.discounts',
    sortIndex: 6
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/blog-list',
    label: 'navigation.blog',
    sortIndex: 7
  },
  {
    auth: [
      AUTH_ROLES_ENUM.ADMIN,
      AUTH_ROLES_ENUM.VENDOR,
      AUTH_ROLES_ENUM.CRAFTSMAN,
      AUTH_ROLES_ENUM.ACCOUNT_PRO,
      AUTH_ROLES_ENUM.ACCOUNT_BASIC,
      AUTH_ROLES_ENUM.ARCHITECT,
      AUTH_ROLES_ENUM.MANUFACTURE
    ],
    key: '/styles',
    label: 'navigation.styles',
    sortIndex: 8,
    children: [
      {
        auth: [AUTH_ROLES_ENUM.ADMIN],
        key: '/styles/sections',
        label: 'navigation.sections',
        to: '/styles/sections'
      },
      {
        auth: [
          AUTH_ROLES_ENUM.ADMIN,
          AUTH_ROLES_ENUM.VENDOR,
          AUTH_ROLES_ENUM.ARCHITECT,
          AUTH_ROLES_ENUM.CRAFTSMAN,
          AUTH_ROLES_ENUM.ACCOUNT_PRO,
          AUTH_ROLES_ENUM.ACCOUNT_BASIC,
          AUTH_ROLES_ENUM.MANUFACTURE
        ],
        key: '/styles/list',
        label: 'navigation.styles',
        to: '/styles'
      }
    ]
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/user-management',
    label: 'navigation.user_management',
    sortIndex: 9,
    children: [
      {
        key: '/user-management/manufacturer',
        label: 'navigation.manufacturer',
        to: '/user-management/manufacturer'
      },
      {
        key: '/user-management/vendor',
        label: 'navigation.vendor',
        to: '/user-management/vendor'
      },
      {
        key: '/user-management/customers',
        label: 'navigation.customers',
        to: '/user-management/customers'
      }
    ]
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/orders',
    label: 'navigation.orders',
    sortIndex: 10
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/website-banners',
    label: 'Hero Section',
    sortIndex: 11
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/website-menu',
    label: 'Website Menu',
    sortIndex: 12
  },
  {
    auth: [AUTH_ROLES_ENUM.ADMIN],
    key: '/user-feedback',
    label: 'navigation.feedback',
    sortIndex: 13
  }
];

export default MENU_ITEMS;
