import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { useAuth } from 'auth';
import { CUSTOM_ERRORS_MESSAGE_KEYS } from 'constants/apiErrors';

import i18n from './i18n';
import { setFileInstanceToken } from 'utils/uploadExcel';

const { REACT_APP_BASE_URL } = process.env;

const cache = new InMemoryCache({
  typePolicies: {
    Category: {
      fields: {
        children: {
          merge(_, incoming) {
            return incoming?.length ? incoming : null;
          }
        }
      }
    },
    ArchitectWithProfile: {
      keyFields: false
    },
    StyleUserType: {
      keyFields: false
    },
    Query: {
      fields: {}
    }
  }
});

const stylique_uri = REACT_APP_BASE_URL?.includes('/graphql')
  ? REACT_APP_BASE_URL
  : REACT_APP_BASE_URL + '/graphql';

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = useAuth.getState().token;
  setFileInstanceToken(token);
  operation.setContext(() => ({
    headers: token
      ? {
          authorization: `Bearer ${token}`
        }
      : {}
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const logout = useAuth.getState().logout;

  if (networkError) {
    message.error(networkError?.message);
  } else if (graphQLErrors) {
    const { errors } = graphQLErrors?.[0]?.extensions?.response?.body || {
      errors: {}
    };

    errors &&
      Object.values(errors).forEach((error: any) => {
        if (error.msg === 'error.TOKEN_NOT_VALID') {
          logout();
        }

        if (!CUSTOM_ERRORS_MESSAGE_KEYS.includes(error.msg)) {
          message.error(error.msg || 'Something went wrong');
        }
      });

    if (graphQLErrors[0]?.message === '401: Unauthorized') {
      message.error(i18n.t('messages.UNAUTHENTICATED', { ns: 'common' }));
      logout();
    }
  }
});

const link = from([
  errorLink,
  authMiddleware,
  new HttpLink({ uri: stylique_uri })
]);

const client = new ApolloClient({
  cache,
  link
});

export default client;
