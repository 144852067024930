import { gql } from '@apollo/client';

export const SERVICE_INFO = gql`
  query serviceInfo {
    serviceInfo {
      data
      message
      status
    }
  }
`;
