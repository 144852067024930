import { gql } from '@apollo/client';

export const CHECK_KEY = gql`
  query checkKey($input: CheckKeyInput) {
    checkKey(input: $input) {
      data
      message
      status
    }
  }
`;
