import { FormItem, Input } from '@stylique/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SeoTitle = () => {
  const { t } = useTranslation('admin');

  return (
    <FormItem name={['seo_data', 'title']} label={t('title', { ns: 'common' })}>
      <Input
        size="middle"
        style={{ fontSize: '16px', padding: '6px 12px' }}
        placeholder={t('website_menu.seo_title_placeholder')}
      />
    </FormItem>
  );
};

export default SeoTitle;
