import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BackToLogin = () => {
  const { t } = useTranslation('common');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      <ArrowLeftOutlined style={{ fontSize: '13px' }} />
      <Link
        to="/login"
        style={{
          fontSize: '13px',
          color: 'var(--gray-3)',
          fontWeight: '500'
        }}
      >
        {t('forgot_password.back_to_login')}
      </Link>
    </div>
  );
};

export default BackToLogin;
