import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AddNewProductButton: FC<{ type: 'ghost' | 'primary' }> = ({ type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Button
      key="add_new"
      onClick={() => navigate('/products/parents/details')}
      type={type}
      style={{ fontSize: '16px', borderRadius: '2px' }}
    >
      {t('products.add_new', { ns: 'admin' })}
      <PlusOutlined />
    </Button>
  );
};

export default AddNewProductButton;
