type AttributeObjectType = {
  [key: string]: { [key: string]: string | null };
};

export const convertGroupsData = (groups: {
  [key: string]: { [key: string]: string };
}) => {
  const result: any = [];

  Object.keys(groups).map(key => {
    if (key.includes('groups')) {
      const [, group] = key.split('-');
      const groupValues = groups[key];

      const values = Object.keys(groupValues).map(valueKey => ({
        attribute: valueKey,
        value: groupValues[valueKey] || '-'
      }));

      result.push({
        group,
        values
      });
    }
  });

  return result;
};

export const createGroupsData = (data: any): AttributeObjectType => {
  const result = data.reduce((acc: any, val: any) => {
    // group field key is the "groups" string, "-" and the id of the group
    const groupKey = `groups-${val.group}`;

    return {
      ...acc,
      [groupKey]: val.values.reduce(
        (acc: any, val: any) => ({
          ...acc,
          [val.attribute]: val.value
        }),
        {}
      )
    };
  }, {});

  return result;
};

export const convertSimpleAttributeValues = (
  data?: any
): AttributeObjectType => {
  if (!data) return {};
  const result = data.reduce((acc: AttributeObjectType, val: any) => {
    if (!val?.attr_id) return acc;

    // attribute field key is the id of attribute
    const attIdKey = String(val.attr_id);
    // group field key is the "groups" string, "-" and the id of the group
    const groupKey = `groups-${val.group_id}`;

    // if the group data is already exits in the accumulator than add NEXT one
    if (acc[groupKey]) {
      return {
        ...acc,
        [groupKey]: {
          ...acc[groupKey],
          [attIdKey]: val.value
        }
      };
    }

    // if the group data is NOT exits in the accumulator than add FIRST one
    return {
      ...acc,
      [groupKey]: {
        [attIdKey]: val.value
      }
    };
  }, {});

  return result;
};

export const generateVariationAttributeValues = (data: any) => {
  if (!data) return [];
  const result = data.reduce((acc: any, val: any) => {
    if (!val?.attr_id || !val?.value) return acc;

    const index = acc.findIndex(
      (item: any) => item.group === String(val.group_id)
    );

    const value: any = {
      attribute: String(val.attr_id),
      value: val.value || '-'
    };

    if (index > -1) {
      acc[index].values?.push(value);

      return [...acc];
    }

    return [
      ...acc,
      {
        group: String(val.group_id),
        values: [value]
      }
    ];
  }, []);

  return result;
};

export const generateConfigurableAttributes = (data: any) => {
  const variations = data
    .map((variation: any) => variation?.combinations)
    .flat();

  const result = variations.reduce(
    (acc: any, val: any, variationIndex: any) => {
      const index = acc.findIndex(
        (item: any) => item.attribute === String(val?.attr_id)
      );

      if (index > -1) {
        const newData = [...acc];
        const { values } = newData[index];
        const isExisting = values.includes(String(val?.option_id));

        if (!isExisting) {
          newData[index].values.push(String(val?.option_id));
        }

        return newData;
      }

      return [
        ...acc,
        {
          attribute: String(val?.attr_id),
          index: variationIndex,
          values: [String(val?.option_id)]
        }
      ];
    },
    []
  );

  return result;
};

export const findProductAttributeByName = (family: any, name: string) => {
  const attributes = family.flatMap((family: any) => {
    if (!family) return [];
    const { groupAttributes, familyGroup } = family;

    return groupAttributes?.map((attr: any) => ({
      ...attr,
      groupId: familyGroup?.id
    }));
  });

  const attribute = attributes.find(
    (item: any) => item?.attribute?.name === name
  );

  return attribute;
};
