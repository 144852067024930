import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button, FormItem, Input, Paragraph } from '@stylique/core';
import { useAuth } from 'auth';

import { Container, LoginWrap, Titles, Welcome } from '../Login/styles';
import { FORGOT_PASSWORD } from '../../graphql/resetPassword/mutation';
import { StyledButtonResend } from './styles';
import BackToLogin from 'components/BackToLogin';

const ForgotPassword: FC = () => {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();
  const [checkEmail, setCheckEmail] = useState<string | null>(null);
  const [resendTimeout, setResendTimeout] = useState<number>(0);
  const adminRole = useAuth(state => state.adminRole);

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const onFinish = async ({ email }: { email: string }) => {
    await forgotPassword({
      variables: {
        input: {
          email: email,
          type: adminRole
        }
      },
      onCompleted() {
        setCheckEmail(email);
      }
    });
  };

  useEffect(() => {
    let resendTime: ReturnType<typeof setTimeout>;

    if (resendTimeout > 0) {
      resendTime = setTimeout(() => {
        setResendTimeout((prev: number) => prev - 1);
      }, 1000);
    } else {
      setResendTimeout(0);
    }

    return () => clearTimeout(resendTime);
  }, [resendTimeout]);

  return (
    <LoginWrap>
      {!checkEmail ? (
        <Container>
          <Titles>
            <Welcome>{t('forgot_password.forgot_your_password')}</Welcome>
            <Paragraph
              style={{ fontSize: '16px', marginBottom: '24px' }}
              color="var(--gray-1)"
            >
              {t('forgot_password.about_1')}
            </Paragraph>
          </Titles>

          <Form form={form} layout="vertical" onFinish={onFinish}>
            <FormItem
              label={t('email_address')}
              name="email"
              rules={[
                { required: true },
                {
                  type: 'email',
                  message: t('valid_email')
                }
              ]}
              style={{ marginBottom: '24px' }}
            >
              <Input />
            </FormItem>

            <Form.Item style={{ marginTop: 16, marginBottom: 29 }}>
              <Button
                style={{
                  backgroundColor: 'var(--gray-10)',
                  borderColor: 'var(--gray-10)'
                }}
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                {t('forgot_password.reset_password')}
              </Button>
            </Form.Item>

            <BackToLogin />
          </Form>
        </Container>
      ) : (
        <Container>
          <Titles>
            <Welcome
              style={{
                fontWeight: '700',
                marginBottom: '29px'
              }}
            >
              {t('forgot_password.check_your_email')}
            </Welcome>
            <Paragraph
              style={{ fontSize: '16px', marginBottom: '24px' }}
              color="var(--gray-3)"
            >
              <span>{t('forgot_password.about_2.p1')}</span>{' '}
              <span style={{ fontWeight: 700 }}>{checkEmail} </span>
              <span>{t('forgot_password.about_2.p2')}</span>
            </Paragraph>
          </Titles>
          <BackToLogin />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              marginTop: 29
            }}
          >
            <Paragraph style={{ fontSize: '16px' }} color="var(--gray-9)">
              {t('forgot_password.not_receive')}
            </Paragraph>
            <StyledButtonResend
              disabled={!!resendTimeout}
              type="link"
              loading={loading}
              onClick={async () => {
                await forgotPassword({
                  variables: {
                    input: {
                      email: checkEmail,
                      type: adminRole
                    }
                  }
                });
                setResendTimeout(29);
              }}
            >
              {t('forgot_password.resend_link')}
            </StyledButtonResend>
            {resendTimeout ? (
              <span
                style={{
                  color: 'var(--gray-12)',
                  fontSize: '16px',
                  lineHeight: '1'
                }}
              >
                ({resendTimeout}s)
              </span>
            ) : (
              ''
            )}
          </div>
        </Container>
      )}
    </LoginWrap>
  );
};

export default ForgotPassword;
