import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, de } from 'locales';

import useSettings from 'hooks/useSettings';

const resources = { en, de };

i18n.use(initReactI18next).init({
  resources,
  ns: ['common', 'admin', 'vendor', 'manufacturer'],
  lng: useSettings.getState().language,
  fallbackLng: 'de',
  react: {
    useSuspense: true
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

// const locizeOptions = {
//   projectId: process.env.REACT_APP_LOCIZE_PROJECTID as string,
//   apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
//   referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
//   version: process.env.REACT_APP_LOCIZE_VERSION,
//   allowedAddOrUpdateHosts: [
//     'admin-local.stylique.am',
//     'partner-local.stylique.am',
//     'manufacturer-local.stylique.am'
//   ]
// };
