import { message } from 'antd';
import { ConfigProviderProps } from 'antd/lib/config-provider';

import useSettings from 'hooks/useSettings';
import i18n from '../i18n';

message.config({
  duration: 2,
  maxCount: 3
});

export const config: ConfigProviderProps = {
  locale: {
    locale: useSettings.getState().language,
    Empty: { description: i18n.t('emptyDesc', { ns: 'common' }) },
    Form: {
      defaultValidateMessages: {
        required: i18n.t('required', { ns: 'common' }),
        string: {
          max: function (_: string, count: number) {
            return i18n.t('maxCount', { ns: 'common', max: count });
          } as any
        }
      }
    }
  }
};
