import { useLazyQuery } from '@apollo/client';
import { Button, FormItem, ImageUpload } from '@stylique/core';
import { Checkbox, Divider, Form, Modal, Space } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { VARIATIONS_IMAGES } from '../../../graphql/variationsProducts/queries';
import { StyledAddImagePopupText } from '../styles';
import { IPropsAddImagePopup } from '../types';

const AddImagePopup: FC<IPropsAddImagePopup> = ({
  isVisible = false,
  data,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const { id, sku } = useMemo(() => {
    return {
      id: data?.id,
      sku: data?.sku
    };
  }, [data?.id, data?.sku]);

  const imageRef = useRef<{
    reset: () => void;
    setValues: (images: string[]) => void;
  }>(null);

  const [
    variationImages,
    {
      data: {
        variationImages: { data: { images = [], muster = null } = {} } = {}
      } = {},
      loading: isVariationImages
    }
  ] = useLazyQuery(VARIATIONS_IMAGES, {
    fetchPolicy: 'no-cache'
  });

  const getVariationImages = useCallback(async () => {
    await variationImages({
      variables: {
        id
      }
    });
  }, [id, variationImages]);

  useEffect(() => {
    if (id) {
      getVariationImages();
    }
  }, [getVariationImages, id]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        images: images.map((i: { filename: string }) => i.filename),
        muster: Boolean(false)
      });
    }
  }, [form, id, images, muster]);

  return (
    <Modal
      title={
        <Space size={0}>
          <StyledAddImagePopupText type="title">
            {t('styles.add_images', { ns: 'admin' })}
          </StyledAddImagePopupText>{' '}
          <Divider type="vertical" />{' '}
          <StyledAddImagePopupText type="1">{sku}</StyledAddImagePopupText>
        </Space>
      }
      open={isVisible}
      onCancel={onClose}
      afterClose={() => {
        onClose();
        form.resetFields();
      }}
      footer={() => null}
      style={{
        backgroundColor: 'var(--white)'
      }}
      centered
      destroyOnClose
      width={843}
    >
      {isVariationImages ? (
        ''
      ) : (
        <Form
          form={form}
          layout="vertical"
          size="large"
          style={{
            padding: '8px 64px 0 64px'
          }}
          onFinish={async formData => {
            const images = formData.images.map(
              (image: string, index: number) => ({
                filename: image,
                originName: null,
                main: !index,
                sortIndex: index
              })
            );

            await onSubmit({
              id: data?.id as number,
              images,
              muster: formData.muster
            });

            form.resetFields();
          }}
        >
          <FormItem name="images">
            <ImageUpload
              ref={imageRef}
              multiple={true}
              initialValues={images.map(
                (i: { filename: string }) => i.filename
              )}
            />
          </FormItem>

          {muster && (
            <Space align="center" size={8}>
              <FormItem
                name="muster"
                style={{
                  margin: '0'
                }}
              >
                <Checkbox
                  onChange={e => {
                    form.setFieldValue('muster', e.target.checked);
                  }}
                />
              </FormItem>{' '}
              <StyledAddImagePopupText type="3">
                {t('use_img_sample_product')}
              </StyledAddImagePopupText>
              <Divider type="vertical" />
              <StyledAddImagePopupText type="2">
                {muster}
              </StyledAddImagePopupText>
            </Space>
          )}

          <Space
            style={{
              width: '100%',
              justifyContent: 'flex-end'
            }}
            size={8}
            direction="horizontal"
          >
            <FormItem style={{ marginBottom: '0' }}>
              <Button
                type="default"
                onClick={onClose}
                style={{
                  fontSize: '16px',
                  border: 'none',
                  boxShadow: 'none'
                }}
              >
                {t('cancel')}
              </Button>
            </FormItem>
            <FormItem style={{ marginBottom: '0' }}>
              <Button
                inverse={true}
                type="primary"
                htmlType="submit"
                loading={false}
                style={{
                  fontSize: '16px'
                }}
              >
                {t('save')}
              </Button>
            </FormItem>
          </Space>
        </Form>
      )}
    </Modal>
  );
};

export default AddImagePopup;
