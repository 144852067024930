import { Button, FormItem } from '@stylique/core';
import { Row, Space } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
  isLoading: boolean;
}

const Save: FC<IProps> = ({ onClose, isLoading }) => {
  const { t } = useTranslation('common');

  return (
    <FormItem>
      <Row style={{ marginTop: '16px', marginBottom: 0 }} justify="center">
        <Space align="center" size={32} direction="horizontal">
          <Button type="default" onClick={onClose}>
            {t('cancel', { ns: 'common' })}
          </Button>
          <Button
            inverse={true}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {t('save', { ns: 'common' })}
          </Button>
        </Space>
      </Row>
    </FormItem>
  );
};

export default Save;
