import React, { FC } from 'react';
import { FormItem, Input } from '@stylique/core';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import TagSelect from 'components/TagSelect';

type FormValues = {
  name: string;
  description: string;
  tags: { value: string }[];
};

type SuggestionPopupProps = {
  open: boolean;
  initialValues: FormValues;
  onClose: () => void;
  onSubmit: (values: Omit<FormValues, 'tags'> & { tags: number[] }) => void;
};

const SuggestionPopup: FC<SuggestionPopupProps> = ({
  open,
  initialValues,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const handleValidSubmit = (values: FormValues) => {
    onSubmit({ ...values, tags: values.tags.map(tag => Number(tag.value)) });
  };

  return (
    <Modal
      title={'Ai powered product info suggestion'}
      open={open}
      onCancel={onClose}
      okButtonProps={{
        htmlType: 'submit',
        form: 'product-info-suggestion-form'
      }}
      afterClose={() => {
        onClose();
        form.resetFields();
      }}
      centered
      destroyOnClose
      okText={'Use this suggestions'}
      className="product-info-suggestion-modal"
    >
      <Form
        form={form}
        id="product-info-suggestion-form"
        layout="vertical"
        size="middle"
        initialValues={initialValues}
        onFinish={handleValidSubmit}
      >
        <FormItem label={'Product Name'} name="name">
          <Input />
        </FormItem>
        <FormItem label={'Product Description'} name="description">
          <Input />
        </FormItem>
        <FormItem label={'Product Material Type'} name="materialType">
          <Input />
        </FormItem>
        <FormItem label={'Product Color'} name="color">
          <Input />
        </FormItem>
        <FormItem label={'Product pattern'} name="pattern">
          <Input />
        </FormItem>

        <TagSelect labelWidth="100%" label="Tags" name="tags" type="product" />
      </Form>
    </Modal>
  );
};

export default SuggestionPopup;
