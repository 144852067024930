import React, { FC, useCallback, useState } from 'react';
import { Loading } from '@stylique/core';
import { Dropdown, Modal, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import DraggableIcon from 'assets/icons/DraggableIcon';
import { Dots } from '@stylique/core/icons';
import { useMutation } from '@apollo/client';
import {
  CREATE_MENU_ITEM,
  DELETE_MENU_ITEM,
  UPDATE_MENU_ITEM,
  UPDATE_MENU_ITEM_STATUS
} from 'graphql/websiteMenu/mutations';
import { getMenuItems_getMenuItems_data as IMenuItem } from '__generated__/getMenuItems';

import DraggableList from 'pages/Styles/components/DraggableList';
import { StyledChip } from 'components/CommonStyles';
import { StyledCard } from '../styles';
import {
  StyledSectionName,
  StyledSectionRow,
  StyledSectionsInfo
} from 'pages/admin/Sections/styles';
import { getMenuRowBackground } from '../utils';
import SectionHeader from './SectionHeader';
import Level2Modal from './Modals/Level2Modal';
import getMenuActions from '../utils/getMenuActions';
import useQueryParams from 'hooks/useQueryParams';
import Level3Modal from './Modals/Level3Modal';
import {
  ILevelSectionProps,
  IModalState,
  ISubmitLevel2,
  ISubmitLevel3,
  ISubmitLevel4
} from '../types';
import { MODAL_INITIAL } from '../constants';
import submitLevel2 from '../handlers/submitLevel2';
import submitLevel3 from '../handlers/submitLevel3';
import Level4Modal from './Modals/Level4Modal';
import submitLevel4 from '../handlers/submitLevel4';

const LevelSection: FC<ILevelSectionProps> = ({
  level,
  getCurrentMenuData,
  moveRow,
  isGetCurrentMenuData,
  menuItems,
  currentItemId,
  parentItemId
}) => {
  const { t } = useTranslation('admin');
  const { setParams, deleteParams } = useQueryParams();
  const [modal, setModal] = useState<IModalState>(MODAL_INITIAL);
  const isLevel2 = level === '2';
  const isLevel3 = level === '3';
  const isLevel4 = level === '4';

  const [createMenuItem, { loading: isCreateMenuItem }] =
    useMutation(CREATE_MENU_ITEM);

  const [deleteMenuItem, { loading: isDeleteMenuItem }] =
    useMutation(DELETE_MENU_ITEM);

  const [updateMenuItemStatus, { loading: isUpdateMenuItemStatus }] =
    useMutation(UPDATE_MENU_ITEM_STATUS);

  const [updateMenuItem, { loading: isUpdateMenuItem }] =
    useMutation(UPDATE_MENU_ITEM);

  const isLoadingMenu =
    isGetCurrentMenuData ||
    isDeleteMenuItem ||
    isCreateMenuItem ||
    isUpdateMenuItemStatus ||
    isUpdateMenuItem;

  const onClickDropdown = async (props: { key: string }, item: IMenuItem) => {
    if (props.key === 'edit') {
      setModal({
        isVisible: true,
        data: item
      });
    }

    if (props.key === 'delete') {
      Modal.confirm({
        title: t('website_menu.menu_item_delete_modal.title'),
        content: t('website_menu.menu_item_delete_modal.content'),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        onOk: async () => {
          await deleteMenuItem({ variables: { id: Number(item.id) } }).then(
            async () => {
              message.success(t('messages.item_deleted'));

              if (isLevel2) deleteParams(['level2', 'level3', 'level4']);
              if (isLevel3) deleteParams(['level3', 'level4']);
              if (isLevel4) deleteParams(['level4']);

              await getCurrentMenuData({
                variables: {
                  id: parentItemId ? Number(parentItemId) : undefined
                }
              });
            }
          );
        }
      });
    }

    if (props.key === 'unpublished') {
      await updateMenuItemStatus({
        variables: {
          id: Number(item.id),
          status: 'published'
        }
      }).then(async () => {
        message.success(t('messages.item_published', { ns: 'common' }));

        await getCurrentMenuData({
          variables: {
            id: parentItemId ? Number(parentItemId) : undefined
          }
        });
      });
    }

    if (props.key === 'published') {
      await updateMenuItemStatus({
        variables: {
          id: Number(item.id),
          status: 'unpublished'
        }
      }).then(async () => {
        message.success(t('messages.item_unpublished', { ns: 'common' }));
        await getCurrentMenuData({
          variables: {
            id: parentItemId ? Number(parentItemId) : undefined
          }
        });
      });
    }
  };

  const createNewItemHandler = () => {
    setModal({
      isVisible: true,
      data: null
    });
  };

  const onClickMenuItem = async (item: IMenuItem) => {
    const menuItemId = Number(item.id);
    setParams([{ key: `level${level}`, value: String(menuItemId) }]);

    if (isLevel2) deleteParams(['level3', 'level4']);
    if (isLevel3) deleteParams(['level4']);
  };

  const onCloseHandler = () => {
    setModal(MODAL_INITIAL);
  };

  const onSubmitLevel2Handler = useCallback(
    (data: ISubmitLevel2, type: 'create' | 'update') => {
      submitLevel2(
        data,
        type,
        parentItemId,
        createMenuItem,
        getCurrentMenuData,
        updateMenuItem,
        currentItemId,
        t
      );
    },
    [
      createMenuItem,
      currentItemId,
      getCurrentMenuData,
      parentItemId,
      t,
      updateMenuItem
    ]
  );

  const onSubmitLevel3Handler = useCallback(
    (data: ISubmitLevel3, type: 'create' | 'update') => {
      submitLevel3(
        data,
        createMenuItem,
        parentItemId,
        getCurrentMenuData,
        type,
        updateMenuItem,
        currentItemId,
        t
      );
    },
    [
      createMenuItem,
      currentItemId,
      getCurrentMenuData,
      parentItemId,
      t,
      updateMenuItem
    ]
  );

  const onSubmitLevel4Handler = useCallback(
    async (data: ISubmitLevel4, type: 'create' | 'update') => {
      submitLevel4(
        data,
        type,
        createMenuItem,
        parentItemId,
        getCurrentMenuData,
        updateMenuItem,
        currentItemId,
        t
      );
    },
    [
      createMenuItem,
      currentItemId,
      getCurrentMenuData,
      parentItemId,
      t,
      updateMenuItem
    ]
  );

  return (
    <StyledCard>
      <Space direction="vertical" style={{ width: '100%' }}>
        <SectionHeader
          createHandler={createNewItemHandler}
          level={level}
          count={menuItems.length}
        />
        {!isLoadingMenu ? (
          <>
            <StyledSectionsInfo>{t('affiliates.drag_info')}</StyledSectionsInfo>

            {menuItems.map((item: IMenuItem, index: number) => {
              const isCurrentItem: boolean = currentItemId === String(item?.id);

              return (
                <DraggableList
                  index={index}
                  key={`section-${index}`}
                  moveRow={(dragIndex: number, hoverIndex: number) => {
                    moveRow(dragIndex, hoverIndex, level);

                    return null;
                  }}
                >
                  <StyledSectionRow
                    style={getMenuRowBackground(isCurrentItem)}
                    onClick={() => onClickMenuItem(item)}
                  >
                    <div className="row-children">
                      <DraggableIcon style={{ marginRight: '14px' }} />
                      <StyledSectionName ellipsis={{ tooltip: item?.name }}>
                        {item?.name}
                      </StyledSectionName>
                    </div>

                    <div className="row-children">
                      <StyledChip $published={!!item?.isPublished}>
                        {item?.isPublished
                          ? t('statuses.published', { ns: 'common' })
                          : t('statuses.unpublished', { ns: 'common' })}
                      </StyledChip>
                      <Dropdown
                        menu={{
                          items: getMenuActions(t, !!item?.isPublished, level),
                          onClick: props => {
                            onClickDropdown(props, item);
                          }
                        }}
                        trigger={['click']}
                      >
                        <Dots style={{ cursor: 'pointer' }} />
                      </Dropdown>
                    </div>
                  </StyledSectionRow>
                </DraggableList>
              );
            })}
          </>
        ) : (
          <Loading />
        )}
      </Space>

      {modal.isVisible && isLevel2 && (
        <Level2Modal
          isVisible={modal.isVisible}
          data={modal.data}
          onClose={onCloseHandler}
          onSubmit={onSubmitLevel2Handler}
          isLoading={isCreateMenuItem}
        />
      )}

      {modal.isVisible && isLevel3 && (
        <Level3Modal
          isVisible={modal.isVisible}
          data={modal.data}
          onClose={onCloseHandler}
          onSubmit={onSubmitLevel3Handler}
          isLoading={isCreateMenuItem}
        />
      )}

      {modal.isVisible && isLevel4 && (
        <Level4Modal
          isVisible={modal.isVisible}
          data={modal.data}
          onClose={onCloseHandler}
          onSubmit={onSubmitLevel4Handler}
          isLoading={isCreateMenuItem}
        />
      )}
    </StyledCard>
  );
};

export default LevelSection;
