import React from 'react';
import { FormItem } from '@stylique/core';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { StyledImageLink } from '../../styles';

const ImageLink = () => {
  const { t } = useTranslation('admin');
  const form = Form.useFormInstance();
  const promoText = form.getFieldValue(['promo_block', 'promo_text']);
  Form.useWatch(['promo_block', 'promo_text'], form);

  return (
    <FormItem
      name={['promo_block', 'link']}
      label={t('website_menu.image_link')}
    >
      <StyledImageLink
        size="middle"
        addonBefore="http://"
        placeholder={t('website_menu.image_link_placeholder')}
        onChange={e => {
          if (!e.target.value?.length && !promoText.length) {
            form.setFields([{ name: ['promo_block', 'image'], errors: [''] }]);
          }
        }}
      />
    </FormItem>
  );
};

export default ImageLink;
