import React, { FC, CSSProperties, SyntheticEvent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

interface Props {
  onClick?: (e: SyntheticEvent) => void;
  style?: CSSProperties;
  type?: 'button' | 'submit';
  disabled?: boolean;
}

const TransparentButton: FC<Props> = props => {
  const { children, ...rest } = props;

  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default TransparentButton;
