import { Typography, Card } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const LoginWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 106px);
`;

export const Container = styled(Card)`
  padding: 64px 76px;
  width: 522px;
`;

export const Titles = styled.div`
  text-align: center;
`;

export const Welcome = styled(Paragraph)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 16px;
  white-space: nowrap;
`;
