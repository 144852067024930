import { gql } from '@apollo/client';

export const DELETE_USER_FEEDBACK = gql`
  mutation deleteUserFeedback($input: DeleteUserFeedbackInput!) {
    deleteUserFeedback(input: $input) {
      data
      message
      status
    }
  }
`;

export const CREATE_USER_FEEDBACK = gql`
  mutation createUserFeedback($input: UserFeedbackInput!) {
    createUserFeedback(input: $input) {
      data
      message
      status
    }
  }
`;
