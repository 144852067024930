import React, { FC } from 'react';
import { ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'apolloClient';
import { config } from 'configs/antdConfig';
import GlobalStyles from 'styles/globalStyles';

import { isDev } from 'utils/envHandler';
import analytics from './services/analytics';
import Auth from './auth/Auth';
import Layout from 'components/Layout';
import Authorization from 'components/Authorization';
import './App.less';

if (!isDev) {
  analytics.init();
}

const App: FC = () => {
  return (
    <ConfigProvider {...config}>
      <GlobalStyles />

      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <Auth>
            <BrowserRouter>
              <Authorization>
                <Layout />
              </Authorization>
            </BrowserRouter>
          </Auth>
        </DndProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
};

export default App;
