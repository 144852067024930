import { AdminRoles, AuthRoleName } from 'auth/types';
import _ from 'lodash';
import { NavigationType } from 'types/navigation';
import { AppRoutes, RouteConfigs, AuthType } from 'types/routes';

class StUtils {
  static hasAllPermissions(
    authArr: AuthType | undefined,
    userRole: AuthRoleName[] | null
  ) {
    if (authArr === null || authArr === undefined) {
      return true;
    }

    if (authArr.length === 0) {
      return !userRole || userRole.length === 0;
    }

    if (userRole && userRole.length) {
      return authArr.every((r: any) => userRole.indexOf(r) >= 0);
    }

    return false;
  }

  static hasOneOfPermissions(
    authArr: AuthType | undefined,
    userRole: AuthRoleName[] | null
  ) {
    if (authArr === null || authArr === undefined) {
      return true;
    }

    if (authArr.length === 0) {
      return !userRole || userRole.length === 0;
    }

    if (userRole) {
      return userRole.every((r: string) => authArr.indexOf(r as any) >= 0);
    }

    return false;
  }

  static getAdminRoleFromLocation() {
    let role = 'admin';
    const match = window.location.host.match(/^(\w+)/);

    if (match) {
      role = match[1];
      if (match[1] === 'partner') role = 'vendor';
    }

    return role as AdminRoles;
  }

  static generateNavigationConfig(configs: NavigationType[]): NavigationType[] {
    const navigation = configs.reduce<NavigationType[]>((acc, config) => {
      const auth = config.auth;
      const children = config.children
        ? config.children.map(child =>
            child.auth ? child : { ...child, auth }
          )
        : undefined;

      acc.push({ ...config, auth, children });

      return acc;
    }, []);

    return [...navigation].sort((a, b) =>
      (a.sortIndex || 1) < (b?.sortIndex || 1) ? -1 : 1
    );
  }

  static setRoutes(config: RouteConfigs) {
    let routes = [...config.routes];

    routes = routes.map(route => {
      let auth = config.auth;

      if (route.children) {
        route.children = this.setRoutes({
          auth: route.auth || config.auth,
          settings: route.settings || config.settings,
          routes: route.children
        });
      }

      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs: RouteConfigs[]) {
    let allRoutes: AppRoutes[] = [];
    configs.forEach((config: RouteConfigs) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config)];
    });

    return allRoutes;
  }
}

export default StUtils;
