import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  useCallback,
  forwardRef,
  useState,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone, DropzoneProps, FileRejection } from 'react-dropzone';
import styled, { CSSProperties } from 'styled-components';
import { dragItemInArray, uploadFile } from '@stylique/tools';
import { ColProps, Row, Spin } from 'antd';

import { Paragraph } from '..';
import { Upload } from '../icon';
import UploadedItem from './UploadedItem';
import { dropzoneValidator } from 'utils/dropzoneValidator';

const StyledMainImage = styled.div`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  background: var(--stylique-colors-stylique-black, #191919);
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  max-width: 100px;
`;

const UploadsWrapper = styled.div`
  padding: 12px 0 0 0;
`;

const DropContainer = styled.div<{
  isDragActive: boolean;
  $isFieldView: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  ${props =>
    !props.$isFieldView
      ? `padding: 19px; border: 1px dashed ${
          props.isDragActive ? 'var(--gray-4)' : 'var(--primary-color)'
        }`
      : `padding: 0`}
`;

interface Props {
  multiple?: boolean;
  initialValues?: string[];
  directory?: boolean;
  input?: React.ReactNode;
  itemColProps?: ColProps;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  dropZoneProps?: DropzoneProps;
  webkitdirectory?: boolean;
  mozdirectory?: boolean;
  showUploads?: boolean;
  uploadsWrapperStyles?: CSSProperties;
  onChange?: (data: string[] | string) => void;
  selectedData?: {
    onItemSelect: (index: number) => void;
    isSelectable: boolean;
    defaultSelected?: number;
  };
  setCropVisible?: any;
}

const ImageUpload: ForwardRefRenderFunction<unknown, Props> = (
  {
    multiple,
    onChange,
    initialValues = [],
    selectedData,
    input,
    inputProps,
    dropZoneProps = {},
    itemColProps,
    showUploads = true,
    uploadsWrapperStyles
  },
  ref
) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [uploads, setUploaded] = useState<string[]>([]);
  const [selected, setSelected] = useState(selectedData?.defaultSelected ?? -1);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.map(({ file }) => {
        return dropzoneValidator(file);
      });

      setLoading(true);

      const res = await Promise.all(
        acceptedFiles.map(file => uploadFile(file))
      ).finally(() => setLoading(false));

      const images = res
        .map((item: { data: { image: string[] } }) => item.data.image)
        .flat();

      const data = multiple ? [...images, ...uploads] : [...images];

      if (onChange) {
        onChange(multiple ? [...data] : data[0]);
      }

      setSelected(-1);
      setUploaded(data);
    },
    [multiple, uploads, onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    ...dropZoneProps
  });

  const removeItem = (index: number) => {
    const filtered = uploads.filter((_, prevIndex) => prevIndex !== index);
    setUploaded(filtered);

    if (onChange) {
      onChange(multiple ? [...filtered] : filtered[0]);
    }
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const reorderedUploads = dragItemInArray(
        uploads,
        dragIndex,
        hoverIndex,
        null
      );

      setSelected(-1);
      setUploaded(reorderedUploads);

      if (onChange) {
        onChange(multiple ? [...reorderedUploads] : reorderedUploads[0]);
      }
    },
    [multiple, onChange, uploads]
  );

  useImperativeHandle(ref, () => ({
    value: multiple ? uploads : uploads[0],
    reset: () => setUploaded([]),
    setValues: (images: string[] | null) => setUploaded(images || [])
  }));

  useEffect(() => {
    if (selectedData && selected > -1) {
      selectedData.onItemSelect(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (initialValues) {
      setUploaded(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DropContainer
        {...getRootProps()}
        isDragActive={isDragActive}
        $isFieldView={!!input}
      >
        <input {...getInputProps(inputProps)} />

        {input ? (
          input
        ) : (
          <>
            {loading ? <Spin /> : <Upload />}
            <Paragraph
              color="text-color"
              style={{ fontSize: 14, paddingTop: 8 }}
            >
              {t('image.drag')}
            </Paragraph>
          </>
        )}
      </DropContainer>

      {showUploads && !!uploads?.length && (
        <UploadsWrapper style={uploadsWrapperStyles}>
          {!input && (
            <Paragraph
              color="text-color"
              style={{ fontSize: 16, padding: '12px 0', fontWeight: 500 }}
            >
              {t('image.uploaded_images')}
            </Paragraph>
          )}
          {uploads.length > 1 ? (
            <>
              <Paragraph
                color="text-color"
                style={{
                  fontSize: 14,
                  marginBottom: '12px',
                  fontWeight: 400,
                  color: '#999'
                }}
              >
                {t('drag_images_info')}
              </Paragraph>

              <StyledMainImage>{t('main_image')}</StyledMainImage>
            </>
          ) : null}

          <Row gutter={8}>
            {uploads.map((file, index) => (
              <UploadedItem
                key={file}
                file={file}
                index={index}
                moveRow={moveRow}
                removeItem={removeItem}
                colProps={itemColProps}
              />
            ))}
          </Row>
        </UploadsWrapper>
      )}
    </div>
  );
};

export default forwardRef(ImageUpload);
