import React from 'react';
import { FormItem, TextArea } from '@stylique/core';
import { useTranslation } from 'react-i18next';

const SeoDescription = () => {
  const { t } = useTranslation('admin');

  return (
    <FormItem
      name={['seo_data', 'description']}
      label={t('description', { ns: 'common' })}
      extra={t('website_menu.seo_desc_extra')}
    >
      <TextArea
        size="middle"
        style={{ fontSize: '16px', padding: '6px 12px' }}
        maxLength={300}
        placeholder={t('website_menu.seo_desc_placeholder')}
        autoSize={{ minRows: 2, maxRows: 5 }}
      />
    </FormItem>
  );
};

export default SeoDescription;
