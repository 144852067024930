import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
  useEffect
} from 'react';
import {
  productById_productById_data,
  productById_productById_data_variations_combinations as CombinationType
} from '__generated__/productById';

import {
  State,
  Dispatch,
  ActionTypes,
  DispatchContext,
  AttributeFormOptions
} from './types';
import reducer from './reducers';
import { createVariations, createVariationValues } from '../../utils/helpers';
import { generateVariationAttributeValues } from 'utils/products';

const initialState: State = {
  isAutomatic: false,
  variations: [],
  attributes: [],
  isVariationPopupVisible: false,
  indexToEdit: -1,
  indexToFillProps: -1,
  attributeFamily: -1,
  variationImages: [],
  type: 1,
  status: 1
};

const ProductStateContext = createContext<State | undefined>(undefined);
const ProductDispatchContext = createContext<Dispatch | undefined>(undefined);

const ProductProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.isAutomatic) {
      const selected = state.attributes.map((item: AttributeFormOptions) => ({
        ...item,
        values: createVariationValues(item)
      }));

      dispatch({
        type: ActionTypes.SET_VARIATIONS,
        data: createVariations(selected)
      });
    }
  }, [state.attributes, state.isAutomatic]);

  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
};

const useProductStateContext = (): State => {
  const context = useContext(ProductStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useProductStateContext must be used within a useProductStateContext'
    );
  }

  return context;
};

const useProductDispatchContext = (): DispatchContext => {
  const dispatch = useContext(ProductDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useProductDispatchContext must be used within a useProductDispatchContext'
    );
  }

  const toggleParam = useCallback(
    data => {
      dispatch({ type: ActionTypes.TOGGLE_PARAM, data });
    },
    [dispatch]
  );

  const setAttributes = useCallback(
    data => {
      dispatch({ type: ActionTypes.SET_ATTRIBUTES, data });
    },
    [dispatch]
  );

  const removeAttribute = useCallback(
    index => {
      dispatch({ type: ActionTypes.REMOVE_ATTRIBUTE, data: index });
    },
    [dispatch]
  );

  const deleteVariation = useCallback(
    index => {
      dispatch({ type: ActionTypes.DELETE_VARIATION, data: index });
    },
    [dispatch]
  );

  const deleteVariationByOption = useCallback(
    id => {
      dispatch({ type: ActionTypes.DELETE_VARIATION_BY_OPTION, data: id });
    },
    [dispatch]
  );

  const createManualVariation = useCallback(
    data => {
      dispatch({ type: ActionTypes.ADD_VARIATION, data });
    },
    [dispatch]
  );

  const setIndexToEdit = useCallback(
    index => {
      dispatch({ type: ActionTypes.SET_INDEX_TO_EDIT, data: index });
    },
    [dispatch]
  );

  const updateVariation = useCallback(
    (index, data) => {
      dispatch({ type: ActionTypes.EDIT_VARIATION, data: { index, data } });
    },
    [dispatch]
  );

  const setIndexToFillProps = useCallback(
    index => {
      dispatch({ type: ActionTypes.SET_INDEX_TO_FILL_PROPS, data: index });
    },
    [dispatch]
  );

  const setVariationProperties = useCallback(
    (index, data) => {
      dispatch({
        type: ActionTypes.SET_VARIATION_PROPERTIES,
        data: { index, data }
      });
    },
    [dispatch]
  );

  const setAttributeFamily = useCallback(
    id => {
      dispatch({
        type: ActionTypes.SET_ATTRIBUTE_FAMILY,
        data: id
      });
    },
    [dispatch]
  );

  const setType = useCallback(
    type => {
      dispatch({
        type: ActionTypes.SET_TYPE,
        data: type
      });
    },
    [dispatch]
  );

  const setVariations = useCallback(
    data => {
      dispatch({
        type: ActionTypes.SET_VARIATIONS,
        data
      });
    },
    [dispatch]
  );

  const setVariationImages = useCallback(
    data => {
      dispatch({
        type: ActionTypes.SET_VARIATION_IMAGES,
        data
      });
    },
    [dispatch]
  );

  const setInitialAttributes = useCallback(
    (combinations: (CombinationType | null)[]) => {
      const attributes: AttributeFormOptions[] = [];

      combinations.forEach(comb => {
        if (!comb) return;

        const index = attributes.findIndex(
          item => +item.attribute === comb.attr_id
        );

        if (index > -1) {
          const optionExists = attributes[index].values.includes(
            String(comb.option_id)
          );

          if (!optionExists) {
            attributes[index].values.push(String(comb.option_id));
          }
        } else {
          attributes.push({
            attribute: String(comb.attr_id),
            values: [String(comb.option_id)]
          });
        }
      });

      dispatch({ type: ActionTypes.SET_ALL_ATTRIBUTES, data: attributes });
    },
    [dispatch]
  );

  const setInitialData = useCallback(
    (data: productById_productById_data) => {
      const hasVariations = data.type === '2';
      let combinations: (CombinationType | null)[] = [];

      const variations =
        hasVariations && data.variations
          ? data.variations.map(variation => {
              if (!variation) return {};

              // save combinations in order to create initial configurable options
              if (variation.combinations) {
                combinations = [...combinations, ...variation.combinations];
              }

              return {
                id: variation.id,
                combinations: variation.combinations?.map(item => item?.option),
                name: variation.name,
                is_simple_product: variation.is_simple_product,
                slug: variation.slug,
                sku: variation.sku,
                price: variation.price,
                tags: variation.tags?.map(tag => ({
                  value: tag?.data?.id,
                  label: tag?.data?.name
                })),
                images: variation.images?.map(item => ({
                  filename: item?.filename,
                  main: item?.main
                })),
                inputs: generateVariationAttributeValues(
                  variation.variationAttributes
                )
              };
            })
          : [];

      const product = {
        attributeFamily: data.family_id,
        variations,
        type: Number(data.type),
        status: Number(data.status)
      };

      if (hasVariations) {
        setInitialAttributes(combinations);
      }

      dispatch({
        type: ActionTypes.SET_INITIAL_VALUES,
        data: product
      });
    },
    [dispatch, setInitialAttributes]
  );

  const setStatus = useCallback(
    data => {
      dispatch({
        type: ActionTypes.SET_STATUS,
        data
      });
    },
    [dispatch]
  );

  return {
    toggleParam,
    setAttributes,
    removeAttribute,
    deleteVariation,
    deleteVariationByOption,
    createManualVariation,
    updateVariation,
    setIndexToEdit,
    setIndexToFillProps,
    setVariationProperties,
    setAttributeFamily,
    setType,
    setVariations,
    setVariationImages,
    setInitialData,
    setStatus
  };
};

export default ProductProvider;
export { useProductDispatchContext, useProductStateContext };
