export const colorsRadio = [
  '#E5324B',
  '#E5324B',
  '#E5324B',
  '#E5324B',
  '#E5324B',
  '#E5324B',
  '#FBC815',
  '#FBC815',
  '#73D13D',
  '#73D13D'
];
