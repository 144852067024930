import React, { FC, useMemo } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Popup } from '@stylique/core';

import { FormItemsTitle, ModalTitle } from '../../styles';
import { IModal2, ISubmitLevel2 } from '../../types';
import Save from '../FormItems/Save';
import SeoDescription from '../FormItems/SeoDescription';
import SeoTitle from '../FormItems/SeoTitle';
import Name from '../FormItems/Name';
import MetaKeywords from '../FormItems/MetaKeywords';
import Image from '../FormItems/Image';
import ImageText from '../FormItems/ImageText';
import ImageLink from '../FormItems/ImageLink';
import Brands from '../FormItems/Brands';
import { getInitialValuesModal2 } from '../../utils/getInputValues';
import MarketingTextTitle from '../FormItems/MarketingTextTitle';
import MarketingText from '../FormItems/MarketingText';

const Level2Modal: FC<IModal2> = ({
  isVisible,
  data,
  onClose,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();
  const type = data ? 'update' : 'create';

  const initialValues = useMemo(() => {
    if (!data) return {};

    return getInitialValuesModal2(data);
  }, [data]);

  const onCloseHandler = () => {
    onClose();
    form.resetFields();
  };

  const onFinishHandler = (values: ISubmitLevel2) => {
    onSubmit(values, type);
    onCloseHandler();
  };

  return (
    <Popup
      isVisible={isVisible}
      afterClose={onClose}
      onCancel={onCloseHandler}
      onClose={onCloseHandler}
      destroyOnClose
      width={600}
    >
      <ModalTitle>
        {type === 'create'
          ? t('website_menu.create_level_2')
          : t('website_menu.update_level_2')}
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishHandler}
        initialValues={initialValues}
      >
        <Name label={t('website_menu.name_level2')} />
        <Image type="mobile_image" image={data?.mobile_image} />
        <FormItemsTitle>{t('website_menu.seo_block')}</FormItemsTitle>
        <MarketingTextTitle />
        <MarketingText />
        <SeoTitle />
        <SeoDescription />
        <MetaKeywords />
        <FormItemsTitle>{t('website_menu.promo_block')}</FormItemsTitle>
        <Image type="image" image={data?.image} />
        <ImageLink />
        <ImageText />
        <FormItemsTitle>
          {t('brands', { ns: 'common' })}{' '}
          <span>({t('website_menu.up_to_4')})</span>
        </FormItemsTitle>
        <Brands />
        <Save onClose={onClose} isLoading={isLoading} />
      </Form>
    </Popup>
  );
};

export default Level2Modal;
