import { DocumentNode, gql } from '@apollo/client';
import { AdminRoles } from 'auth/types';

import authRoles from '../authRoles';
import {
  GET_ADMIN_USER_FRAGMENT,
  GET_VENDOR_USER_FRAGMENT,
  GET_MANUFACTURER_USER_FRAGMENT
} from './fragments';

export const GET_ADMIN_USER_DATA = gql`
  query me {
    me {
      data {
        ...AdminMeFragment
      }
      message
      status
    }
  }
  ${GET_ADMIN_USER_FRAGMENT}
`;

const GET_MANUFACTURER_USER_DATA = gql`
  query manufacturer {
    manufacturer {
      data {
        ...ManufacturerMeFragment
      }
      message
      status
    }
  }
  ${GET_MANUFACTURER_USER_FRAGMENT}
`;

const GET_VENDOR_USER_DATA = gql`
  query vendorMe {
    vendorMe {
      data {
        ...VendorMeFragment
      }
      message
      status
    }
  }
  ${GET_VENDOR_USER_FRAGMENT}
`;

export default {
  [authRoles.ADMIN]: GET_ADMIN_USER_DATA,
  [authRoles.VENDOR]: GET_VENDOR_USER_DATA,
  [authRoles.MANUFACTURE]: GET_MANUFACTURER_USER_DATA
} as Record<AdminRoles, DocumentNode>;
