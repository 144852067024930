import { gql } from '@apollo/client';

export const NETWORK_VENDOR_PRODUCTS_FRAGMENT = gql`
  fragment NetworkVendorProductsFragment on NetworkProductVariation {
    id
    name
    price
    sku
    slug
    status
    type
    file
    products_vendor_id
    variation_id
    keepShowroom
    keepDelivery
    priceType
    adjustmentType
    adjustmentAmount
    parentId
    manufacturer {
      company_name
      profile {
        company_name
      }
    }
    images {
      filename
      main
    }
    image {
      filename
    }
    combinations {
      id
      option {
        id
        name
      }
      attribute {
        id
        name
      }
    }
    product {
      id
      vendor_id
      parent_id
      parent {
        name
        category {
          id
          data {
            id
            slug
            name
          }
        }
      }
    }
  }
`;

export const NETWORK_REQUEST_FRAGMENT = gql`
  fragment NetworkRequestFragment on NetworkRequest {
    id
    status
    senderRole
    senderVendorId
    userRole
    vendorId
    manufacturerId
    notes
    regions
    createdAt
    updatedAt
    senderManufacturer {
      id
      company_name
      short_name
      email
      createdAt
      updatedAt
      profile {
        id
        short_name
        company_logo
      }
    }
    senderVendor {
      id
      email
      company_name
      vendor_type
      name
      createdAt
      updatedAt
      profile {
        company_logo
      }
    }
    receiverManufacturer {
      id
      company_name
      short_name
      email
      createdAt
      updatedAt
      profile {
        company_logo
        company_name
        short_name
      }
    }
    receiverVendor {
      id
      company_name
      email
      vendor_type
      avatar
      name
      createdAt
      updatedAt
      profile {
        company_logo
        short_name
      }
    }
  }
`;
