import AUTH_ROLES_ENUM from 'auth/authRoles';
import { NavigationType } from 'types/navigation';

const MENU_ITEMS: NavigationType[] = [
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/manufacturer-profile',
    label: 'navigation.profile',
    sortIndex: 1,
    children: [
      {
        auth: [AUTH_ROLES_ENUM.MANUFACTURE],
        key: '/manufacturer-profile/profile-information',
        label: 'navigation.profile_info'
      },
      {
        auth: [AUTH_ROLES_ENUM.MANUFACTURE],
        key: '/manufacturer-profile/settings',
        label: 'profile.profile_settings'
      }
    ]
  },
  // {
  //   auth: [AUTH_ROLES_ENUM.MANUFACTURE],
  //   key: '/exhibitions-events',
  //   label: 'navigation.exhibitions_events',
  //   sortIndex: 1
  // },
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/representatives',
    label: 'navigation.representatives',
    sortIndex: 2
  },
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/showrooms',
    label: 'navigation.showrooms',
    sortIndex: 3
  },
  // TODO
  // {
  //   auth: [AUTH_ROLES_ENUM.MANUFACTURE],
  //   key: '/news',
  //   label: 'navigation.news',
  //   sortIndex: 4
  // },
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/manufacturer-partners',
    label: 'navigation.partners',
    sortIndex: 5,
    children: [
      {
        auth: [AUTH_ROLES_ENUM.MANUFACTURE],
        key: '/manufacturer-partners/my-network',
        label: 'navigation.myNetwork'
      },
      {
        auth: [AUTH_ROLES_ENUM.MANUFACTURE],
        key: '/manufacturer-partners/stylique-network',
        label: 'navigation.styliqueNetwork'
      }
    ]
  },
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/blog/list',
    label: 'navigation.blog',
    sortIndex: 7
  },
  {
    auth: [AUTH_ROLES_ENUM.MANUFACTURE],
    key: '/products',
    label: 'navigation.products',
    sortIndex: 3,
    children: [
      {
        key: '/products/parents',
        label: 'navigation.parents'
      },
      {
        key: '/products/variations',
        label: 'navigation.variations'
      }
    ]
  }
];

export default MENU_ITEMS;
