import { State } from './types';

const inputs = {
  title: '',
  slug: '',
  description: '',
  short_desc: '',
  tags: [],
  networks: [],
  collaborators: [],
  status: 2
};

export const initialState: State = {
  inputs,
  images: [],
  products: [],
  affiliates_products: [],
  isImagePopupVisible: false,
  imageToEdit: -1,
  searchByName: '',
  searchBySku: '',
  searchByStatus: 'all',
  linkTypeClickeds: [],
  currentStep: 0
};
