import { getAccessToken } from 'auth/useAuth';
import axios, { AxiosInstance } from 'axios';

const accessToken = getAccessToken();

export const adminApiInstance: AxiosInstance = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_ADMIN_API_URL
});

if (accessToken) {
  adminApiInstance.defaults.headers!['Authorization'] = `Bearer ${accessToken}`;
}
