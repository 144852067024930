import { gql } from '@apollo/client';

import { MANUFACTURES_FRAGMENT } from './fragments';

export const CHECK_MANUFACTURE_KEY = gql`
  mutation manufacturerCheck($input: ManufactureCheck!) {
    manufacturerCheck(input: $input) {
      data {
        ...ManufacturerFragment
      }
      message
    }
  }
  ${MANUFACTURES_FRAGMENT}
`;
