import { message } from 'antd';
import { ApolloError, MutationFunction } from '@apollo/client';
import { TFunction } from 'react-i18next';

import {
  ISubmitLevel4,
  TCurrentItemId,
  TGetMenuItems,
  TParentItemId
} from '../types';

const submitLevel4 = async (
  data: ISubmitLevel4,
  type: 'create' | 'update',
  createMenuItem: MutationFunction,
  parentItemId: TParentItemId,
  getCurrentMenuData: TGetMenuItems,
  updateMenuItem: MutationFunction,
  currentItemId: TCurrentItemId,
  t: TFunction
) => {
  const {
    name,
    seo_data,
    manufacturersCheckbox,
    manufacturers,
    tagsCheckbox,
    tags,
    categoriesCheckbox,
    categories,
    attributeValuesCheckbox,
    attributeValues,
    exactProductCheckbox,
    products,
    marketing
  } = data;

  const input = {
    name,
    level: 4,
    seo_data: {
      title: seo_data.title || undefined,
      description: seo_data.description || undefined,
      meteKeywords: seo_data.meteKeywords || []
    },
    manufacturers: manufacturersCheckbox ? manufacturers : [],
    tags: tagsCheckbox ? tags : [],
    categories: categoriesCheckbox
      ? categories?.map(item => Number(item)) || []
      : [],
    attributes_value_groups: attributeValuesCheckbox ? attributeValues : [],
    products: exactProductCheckbox ? products : [],
    marketing: {
      title: marketing.title,
      text: marketing.text
    }
  };

  const longAttributeError = (error: ApolloError) => {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      error.graphQLErrors.forEach(graphQLError => {
        const errorMessage = graphQLError?.extensions?.response?.body?.message;

        if (
          errorMessage.includes(
            'value too long for type character varying(255)'
          )
        ) {
          message.destroy();
          message.error(
            t('messages.long_attribute_value_error', { ns: 'common' })
          );
        }
      });
    }
  };

  if (type === 'create') {
    await createMenuItem({
      variables: {
        input: {
          ...input,
          parent_id: parentItemId ? Number(parentItemId) : undefined
        }
      },
      onCompleted: async () => {
        message.success(t('messages.item_saved', { ns: 'common' }));
        await getCurrentMenuData({
          variables: {
            id: parentItemId ? Number(parentItemId) : undefined
          }
        });
      },
      onError(error) {
        longAttributeError(error);
      }
    });
  }

  if (type === 'update') {
    await updateMenuItem({
      variables: {
        id: Number(currentItemId),
        input
      },
      onCompleted: async () => {
        message.success(t('messages.item_saved', { ns: 'common' }));
        await getCurrentMenuData({
          variables: {
            id: parentItemId ? Number(parentItemId) : undefined
          }
        });
      },
      onError(error) {
        longAttributeError(error);
      }
    });
  }
};

export default submitLevel4;
