import React, { FC } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import AntSelect from 'antd/lib/select';
import { attributeOptions_attributeOptions_data } from '__generated__/attributeOptions';
import { useQuery } from '@apollo/client';
import { FormItem, Select } from '@stylique/core';
import { GET_ATTRIBUTE_BY_ID } from 'graphql/attributes/queries';

const { Option } = AntSelect;

interface Props {
  id: string;
  values: attributeOptions_attributeOptions_data[];
}

const Color = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const VariationSelect: FC<Props> = ({ id, values }) => {
  const { data: { attributeById: { data = {} } = {} } = {}, loading } =
    useQuery(GET_ATTRIBUTE_BY_ID, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  if (loading) return null;

  return (
    <FormItem label={data.name} name={id} rules={[{ required: true }]}>
      <Select optionFilterProp="name" size="large" bordered>
        {values.map(item => {
          const isColor = item.switchType === 'color' && !!item.value;

          return (
            <Option
              key={`attribute-option-${item.id}`}
              value={item.id}
              name={item.name}
            >
              <Space>
                {isColor && <Color color={item.value || ''} />}
                {item.name}
                {isColor && `<${item.value}>`}
              </Space>
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );
};

export default VariationSelect;
