import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Upload: FC<Props> = props => (
  <SVG
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.9991 37.0625C9.79721 37.0625 6.94912 35.0124 5.91491 31.9604L5.84496 31.7304C5.60106 30.9221 5.49888 30.2424 5.49888 29.5623V15.9258L0.646837 32.1222C0.0227978 34.5045 1.44483 36.9743 3.83075 37.6324L34.7577 45.9148C35.1437 46.0148 35.5297 46.0628 35.9098 46.0628C37.9017 46.0628 39.7218 44.7407 40.2319 42.7924L42.0337 37.0625H12.9991Z"
        fill="#ECEFF1"
      />
      <path
        d="M48.5001 6.56066V29.5615C48.5001 32.0415 46.4801 34.0616 44 34.0616H12.9991C12.7992 34.0616 12.5992 34.0414 12.419 34.0213C10.3191 33.7616 8.6792 32.0415 8.51917 29.9215C8.49902 29.8013 8.49902 29.6812 8.49902 29.5615V6.56066C8.49902 4.08062 10.5191 2.06055 12.9991 2.06055H44C46.4801 2.06055 48.5001 4.08062 48.5001 6.56066Z"
        fill="#ECEFF1"
      />
      <path
        d="M22.4996 12.0608C22.4996 14.2698 20.7088 16.061 18.4997 16.061C16.2903 16.061 14.4995 14.2698 14.4995 12.0608C14.4995 9.85173 16.2903 8.06055 18.4997 8.06055C20.7088 8.06055 22.4996 9.85173 22.4996 12.0608Z"
        fill="#FFC107"
      />
      <path
        d="M48.5 23.9425V29.5629C48.5 32.0429 46.48 34.063 43.9999 34.063H12.999C12.7991 34.063 12.5991 34.0428 12.4189 34.0227L33.0195 13.4224C34.3797 12.0623 36.6195 12.0623 37.9796 13.4224L48.5 23.9425Z"
        fill="#388E3C"
      />
      <path
        d="M36.6195 34.0627H12.999C12.7991 34.0627 12.5991 34.0426 12.4189 34.0225C10.319 33.7628 8.67908 32.0427 8.51904 29.9226L18.0192 20.4222C19.3793 19.0624 21.6191 19.0624 22.9792 20.4222L36.6195 34.0627Z"
        fill="#4CAF50"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="48" height="48" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SVG>
);

export default Upload;
