import { StUtils } from '@stylique/utils';

import adminConfig from './adminConfig';
import manufacturerConfig from './manufacturerConfig';
import vendorConfigs from './vendorConfigs';

const navigation = StUtils.generateNavigationConfig([
  ...adminConfig,
  ...vendorConfigs,
  ...manufacturerConfig
]);

export default navigation;
