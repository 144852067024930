import React, { FC, CSSProperties, SyntheticEvent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

interface Props {
  onClick?: (e: SyntheticEvent) => void;
  style?: CSSProperties;
  type?: 'button' | 'submit';
}

const TransparentButton: FC<Props> = ({ children, onClick, style, type }) => (
  <StyledButton onClick={onClick} style={style} type={type}>
    {children}
  </StyledButton>
);

export default TransparentButton;
