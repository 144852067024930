import { gql } from '@apollo/client';

import {
  CRAFTSMAN_SETTINGS_FRAGMENT,
  SERVICE_GROUP_FRAGMENT
} from './fragments';

export const GET_SERVICE_SETTINGS = gql`
  query getServiceSettings {
    getServiceSettings {
      data {
        ...CraftsmanSettingsFragment
      }
      message
      status
    }
  }
  ${CRAFTSMAN_SETTINGS_FRAGMENT}
`;

export const GET_SERVICE_GROUPS = gql`
  query getServiceGroups {
    getServiceGroups {
      data {
        ...ServiceGroupFragment
      }
    }
  }
  ${SERVICE_GROUP_FRAGMENT}
`;

export const GET_SUGGESTED_DURATION = gql`
  query getSuggestedDurations {
    getSuggestedDurations {
      data {
        id
        name
        duration
        groupId
        serviceGroup {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const GET_DRIVING_COSTS_SETTINGS = gql`
  query getVendorDrivingCostSettings {
    getVendorDrivingCostSettings {
      data {
        id
        regions {
          type
          value
        }
        cost
      }
    }
  }
`;
