import { DocumentNode, gql } from '@apollo/client';
import { authRoles } from 'auth';
import { AdminRoles } from 'auth/types';
import { GET_ADMIN_USER_DATA } from 'auth/graphql/queries';

import {
  VENDOR_PROFILE_FRAGMENT,
  MANUFATURER_PROFILE_FRAGMENT
} from './fragments';

export const GET_VENDOR_PROFILE = gql`
  query vendorProfile {
    vendorProfile {
      data {
        ...profileFragment
      }
      message
      status
    }
  }
  ${VENDOR_PROFILE_FRAGMENT}
`;

export const GET_MANUFACTURER_PROFILE = gql`
  query manufacturerProfile {
    manufacturerProfile {
      data {
        ...manufactureProfileFragment
      }
      message
      status
    }
  }
  ${MANUFATURER_PROFILE_FRAGMENT}
`;

export default {
  [authRoles.ADMIN]: GET_ADMIN_USER_DATA,
  [authRoles.VENDOR]: GET_VENDOR_PROFILE,
  [authRoles.MANUFACTURE]: GET_MANUFACTURER_PROFILE
} as Record<AdminRoles, DocumentNode>;
