import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { Col, ColProps, Image, Row, Space } from 'antd';
import { getImageUrl } from '@stylique/tools';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Paragraph, TransparentButton } from '..';

const type = 'DraggableBodyRow';

const ImageItem = styled.div`
  background-color: var(--white);
  border: 1px solid var(--gray-6);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 8px;
  &.drop-over-downward {
    background-color: var(--gray-6);
  }
  &.drop-over-upward {
    background-color: var(--gray-6);
  }
`;

interface Props {
  index: number;
  file: string;
  isSelectable?: boolean;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  removeItem: (index: number) => void;
  onSelect?: () => void;
  selected?: boolean;
  colProps?: ColProps;
}

const UploadedItem: FC<Props> = ({
  index,
  file,
  moveRow,
  removeItem,
  colProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = (monitor.getItem() || { index: 0 }) as {
        index: number;
      };

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const fileExtension = file.split('.').pop();

  drop(drag(ref));

  return (
    <Col ref={ref} style={{ cursor: 'move' }} span={24} {...colProps}>
      <ImageItem
        key={`uploaded-image-item=${index}`}
        className={isOver ? dropClassName : ''}
      >
        <Row
          align="middle"
          wrap={false}
          gutter={12}
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Col>
            <Space size={8} align="center" style={{ position: 'relative' }}>
              {fileExtension !== 'pdf' && (
                <Image
                  width={48}
                  height={48}
                  src={getImageUrl(file)}
                  style={{ borderRadius: 4, position: 'relative' }}
                  preview={true}
                  wrapperClassName="antd-image-relative"
                />
              )}
            </Space>
          </Col>
          <Col flex="2">
            <Paragraph
              color="text-color"
              style={{ fontSize: 14 }}
              paragraphProps={{ ellipsis: { tooltip: file, rows: 2 } }}
            >
              {fileExtension === 'pdf' ? (
                <Link
                  to={getImageUrl(file, null)}
                  target="_blank"
                  style={{ color: 'currentColor' }}
                >
                  <Space size={4}>
                    <PaperClipOutlined style={{ color: 'currentColor' }} />
                    {file}
                  </Space>
                </Link>
              ) : (
                file
              )}
            </Paragraph>
          </Col>
          <Col>
            <TransparentButton
              style={{ width: 24, height: 24 }}
              onClick={() => removeItem(index)}
              type="button"
            >
              <DeleteOutlined />
            </TransparentButton>
          </Col>
        </Row>
      </ImageItem>
    </Col>
  );
};

export default UploadedItem;
