import React, { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { IDraggableListProps } from '../types';
import { DRAG_TYPE } from '../constants';

const DraggableList: FC<IDraggableListProps> = ({
  index,
  className = '',
  style,
  moveRow,
  isDraging = true,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    canDrop() {
      return isDraging;
    },
    accept: DRAG_TYPE,
    collect: monitor => {
      const { index: dragIndex } = (monitor.getItem() || { index: 0 }) as {
        index: number;
      };

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type: DRAG_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <div
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: isDraging ? 'move' : 'default', ...style }}
      {...restProps}
    />
  );
};

export default DraggableList;
