import React, { FC } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const Loading: FC = () => {
  return (
    <Container>
      <Spin tip="Loading" />
    </Container>
  );
};

export default Loading;
