import React from 'react';

import AUTH_ROLES_ENUM from 'auth/authRoles';
import { Outlet } from 'react-router-dom';

const Discounts = React.lazy(() => import('./index'));
const DiscountDetails = React.lazy(() => import('./DiscountDetails'));

const DiscountsConfig = {
  settings: {
    layout: {}
  },
  auth: [AUTH_ROLES_ENUM.ADMIN],
  routes: [
    {
      path: 'discounts',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Discounts />
        },
        {
          path: 'details/:id',
          element: <DiscountDetails />
        }
      ]
    }
  ]
};

export default DiscountsConfig;
