import { Card, Typography } from 'antd';
import styled from 'styled-components';

export const StyledSectionsInfo = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: #999999;
`;
export const StyledSectionRow = styled.div`
  padding: 16px;
  border: 1px solid #999999;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .row-children {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledSectionName = styled(Typography.Text)`
  font-weight: 500;
  font-size: 16px;
  width: 150px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
`;
export const StyledCard = styled(Card)`
  padding: 24px;
`;
export const BlockHeadings = styled.div`
  display: inline-flex;
  width: calc(100% - 230px);
  align-items: center;
`;
export const StyledTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 10px;
`;

export const StyledStyleNamePopover = styled.p`
  color: #464646;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 0;
  text-decoration: underline;
  max-width: 400px;
  word-break: break-all;
  a {
    color: currentColor;
  }
  &.style-names-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
  gap: 34px;
  & .empty-data-blocks__text {
    color: #1e2229;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const StyledButtonOtherStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #191919;
  cursor: pointer;
`;

export const StylesListPopoverContent = styled.div`
  max-height: 300px;
  max-width: 400px;
  overflow-y: auto;
`;
