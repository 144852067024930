import React, { FC } from 'react';
import { FormItem, Input } from '@stylique/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
}

const Name: FC<IProps> = ({ label }) => {
  const { t } = useTranslation('admin');

  return (
    <FormItem
      name="name"
      rules={[{ required: true, message: t('required', { ns: 'common' }) }]}
      label={label}
      extra={t('website_menu.name_extra')}
    >
      <Input
        size="middle"
        style={{ fontSize: '16px', padding: '6px 12px' }}
        placeholder={t('website_menu.name_placeholder')}
      />
    </FormItem>
  );
};

export default Name;
