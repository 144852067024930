import { gql } from '@apollo/client';

import { SERVICE_GROUPS_FRAGMENT } from './fragments';

export const GET_SERVICE_GROUPS = gql`
  query serviceGroups {
    serviceGroups {
      data {
        ...ServiceGroupsFragment
      }
    }
  }
  ${SERVICE_GROUPS_FRAGMENT}
`;
