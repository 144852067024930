import { gql } from '@apollo/client';

import { ATTRIBUTE_FAMILY_FRAGMENT } from './fragments';

export const GET_ATTRIBUTE_FAMILIES = gql`
  query attributeFamilies($query: SearchAttributeFamilyInput) {
    attributeFamilies(query: $query) {
      data {
        id
        name
      }
      message
      status
    }
  }
`;

export const GET_ATTRIBUTE_FAMILY_BY_ID = gql`
  query attributeFamilyById($id: ID!) {
    attributeFamilyById(id: $id) {
      data {
        ...AttributeFamilyFragment
      }
      message
      status
    }
  }
  ${ATTRIBUTE_FAMILY_FRAGMENT}
`;
