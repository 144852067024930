import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  className?: string;
};

const SVG: React.FC<Props> = ({ children, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      {children}
    </svg>
  );
};

export default SVG;
