import React, { useState, FC, useEffect } from 'react';
import { Button, PageHeader as Header } from '@stylique/core';
import { useTranslation } from 'react-i18next';
import { RedoOutlined } from '@ant-design/icons';

import { PageHeaderTitleWrapper } from '../styles';
import { WebsiteMenuStatus } from '../constants';

interface IProps {
  reassignHandler: () => Promise<void>;
  status?: WebsiteMenuStatus;
}

const PageHeader: FC<IProps> = ({ reassignHandler, status }) => {
  const { t } = useTranslation('admin');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (status === WebsiteMenuStatus.PROCESSING) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [status]);

  const handleReassign = async () => {
    await reassignHandler();
    setIsButtonDisabled(true);
  };

  return (
    <Header
      ghost={false}
      extra={
        <Button
          htmlType="button"
          type="primary"
          onClick={handleReassign}
          disabled={isButtonDisabled}
          icon={<RedoOutlined style={{ transform: 'rotate(-90deg)' }} />}
        >
          {isButtonDisabled ? 'Processing' : t('website_menu.reassign')}
        </Button>
      }
      title={
        <PageHeaderTitleWrapper>
          <span className="part1">{t('website_menu.title')}</span>
          <span className="part2">{t('website_menu.subtitle')}</span>
        </PageHeaderTitleWrapper>
      }
    />
  );
};

export default PageHeader;
