/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable padding-line-between-statements */
/* eslint-disable prettier/prettier */
import {
  ActionTypes,
  State,
  Action,
  ImageItem,
  ImageItemUpdate,
  StyleStateInputs,
  ProductItem,
  NetworkInputs
} from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.ADD_IMAGE: {
      const images = [...state.images];

      images.push(action.data as ImageItem);

      return { ...state, images };
    }

    case ActionTypes.SET_MAIN_IMAGE: {
      const images = [...state.images];

      return { ...state, images: [...(action.data as ImageItem[])] };
    }

    case ActionTypes.EDIT_IMAGE: {
      const images = [...state.images];

      images[state.imageToEdit] = action.data as ImageItem;

      return { ...state, images };
    }

    case ActionTypes.UPDATE_IMAGE: {
      const { index, data } = action.data as {
        index: number;
        data: ImageItemUpdate;
      };

      const images = [...state.images];

      images[index] = { ...images[index], ...data };

      return { ...state, images };
    }

    case ActionTypes.DELETE_IMAGE:
      return {
        ...state,
        images: state.images.filter((_, i) => i !== action.data)
      };

    case ActionTypes.LINK_PRODUCT: {
      const products = [...state.products];

      products.push(action.data as ProductItem);

      return { ...state, products };
    }

    case ActionTypes.UPDATE_PRODUCT: {
      const { id, data } = action.data as {
        id: number;
        data: 1 | 2;
      };

      const products = [...state.products];
      const index = products.findIndex(
        item => item.variation_id === id || item.id === id
      );

      if (index > -1) {
        products[index] = { ...products[index], type: data };
      }

      return { ...state, products };
    }

    case ActionTypes.UPDATE_AFFILIATE_PRODUCT: {
      const { id, type } = action.data as any;

      const affiliates_products = [...state.affiliates_products];
      const index = affiliates_products.findIndex(
        item => item.product_id === id
      );

      if (index > -1) {
        affiliates_products[index] = {
          ...affiliates_products[index],
          type
        };
      }

      return { ...state, affiliates_products };
    }

    case ActionTypes.UNLINK_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          item => item.variation_id !== action.data
        )
      };

    case ActionTypes.UNLINK_AFFILIATE_PRODUCT:
      return {
        ...state,
        affiliates_products: state.affiliates_products.filter(
          item => item.product_id !== action.data
        )
      };

    case ActionTypes.SET_IMAGE_POPUP:
      return { ...state, isImagePopupVisible: action.data as boolean };

    case ActionTypes.SET_STYLE_VALUES:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...(action.data as Partial<StyleStateInputs>)
        }
      };

    case ActionTypes.SET_VALUES:
      return {
        ...state,
        ...(action.data as State)
      };

    case ActionTypes.ADD_NETWORK: {
      const networks = [...(state.inputs?.networks || [])];

      networks.push({ name: '', link: '' });

      return { ...state, inputs: { ...state.inputs, networks } };
    }

    case ActionTypes.UPDATE_NETWORK: {
      const { index, data } = action.data as {
        index: number;
        data: NetworkInputs;
      };

      const networks = [...(state.inputs?.networks || [])];

      networks[index] = { ...networks[index], ...data };

      return { ...state, inputs: { ...state.inputs, networks } };
    }

    case ActionTypes.DELETE_NETWORK: {
      const networks = (state.inputs?.networks || []).filter(
        (_, i) => i !== action.data
      );

      return { ...state, inputs: { ...state.inputs, networks } };
    }

    case ActionTypes.SET_STATUS:
      return {
        ...state,
        inputs: { ...state.inputs, status: action.data as number }
      };

    case ActionTypes.SET_IMAGE_TO_EDIT:
      return {
        ...state,
        isImagePopupVisible: Number(action.data) > -1,
        imageToEdit: action.data as number
      };

    case ActionTypes.SET_SEARCH_BY_NAME:
      return {
        ...state,
        searchByName: action.data
      };

    case ActionTypes.SET_SEARCH_BY_SKU:
      return {
        ...state,
        searchBySku: action.data
      };

    case ActionTypes.SET_SEARCH_BY_STATUS:
      return {
        ...state,
        searchByStatus: action.data
      };

    case ActionTypes.SET_LINK_TYPE_CLICKEDS:
      const products = [...state.linkTypeClickeds];

      products.push(action.data as any);

      return { ...state, linkTypeClickeds: products };

    case ActionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.data as number
      };
    default:
      return state;
  }
};

export default reducer;
