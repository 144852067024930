import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import storageApi from 'services/storage';
import { StUtils } from '@stylique/utils';
import client from 'apolloClient';
import { useMemo } from 'react';

import { AdminRoles, UserResponse } from './types';
import AUTH_ROLES_ENUM from './authRoles';

type State = {
  adminRole: AdminRoles;
  token: string | null;
  user: UserResponse | null;
};

type Actions = {
  setAdminRole: (role: AdminRoles) => void;
  setData: (data: Partial<State>) => void;
  setWholesalerVisible: (visible: boolean) => void;
  logout: () => void;
};

type Store = State & Actions;

const initialState: State = {
  adminRole: StUtils.getAdminRoleFromLocation(),
  token: null,
  user: null
};

const useAuth = create<Store>()(
  persist(
    (set, get) => ({
      ...initialState,
      setWholesalerVisible: (isVisible: boolean) =>
        set({ user: { ...(get().user as UserResponse), isVisible } }),

      setData: (data: Partial<State>) => {
        const { user } = data;
        const vendorType = user?.vendor_type;
        const accountType = user?.account_type;

        const roleNames = new Set([user?.roles.name]) as any;

        if (vendorType) {
          roleNames.add(vendorType);
        }

        if (accountType) {
          roleNames.add(accountType);
        }

        return set({
          ...data,
          user: {
            ...user,
            roles: {
              name: [...roleNames.values()],
              value: user?.roles.value as number
            }
          } as any
        });
      },
      setAdminRole: (adminRole: AdminRoles) => set({ adminRole }),
      logout: () => {
        client.cache.reset();
        set(initialState);
      }
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => storageApi),
      version: 1,
      partialize(state) {
        return {
          token: state.token,
          user: state.user
        };
      }
    }
  )
);

export const useAuthUserRoles = () => {
  const roles = useAuth(state => state.user!.roles.name);

  const isAdminUser = useMemo(
    () => roles.includes(AUTH_ROLES_ENUM.ADMIN),
    [roles]
  );

  return { isAdminUser, roles };
};

export const getAccessToken = () => useAuth.getState().token;

export default useAuth;
