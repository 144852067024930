import { gql } from '@apollo/client';

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    slug
    parentId
    avatar
  }
`;

export const CATEGORIES_SUBS_FRAGMENT = gql`
  fragment CategoriesSubFragment on Category {
    ...CategoryFragment
    children {
      ...CategoryFragment
      children {
        ...CategoryFragment
        children {
          ...CategoryFragment
        }
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;
