import { gql } from '@apollo/client';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';

const LOGIN_ADMIN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      data {
        token
        user {
          id
          firstname
          roles {
            id
            value
            name
          }
          lastname
          email
        }
      }
      message
      status
    }
  }
`;

const LOGIN_MANUFACTURER = gql`
  mutation manufacturerSignIn($input: SignInInput!) {
    manufacturerSignIn(input: $input) {
      data {
        token
        user {
          id
          email
          roles {
            id
            value
            name
          }
        }
      }
      message
      status
    }
  }
`;

const LOGIN_VENDOR = gql`
  mutation vendorSignIn($input: SignInInput!) {
    vendorSignIn(input: $input) {
      data {
        token
        user {
          id
          email
          name
          vendor_type
          account_type
          roles {
            id
            value
            name
          }
        }
      }
      message
      status
    }
  }
`;

export default {
  [AUTH_ROLES_ENUM.ADMIN]: LOGIN_ADMIN,
  [AUTH_ROLES_ENUM.VENDOR]: LOGIN_VENDOR,
  [AUTH_ROLES_ENUM.MANUFACTURE]: LOGIN_MANUFACTURER
};

export const queryPaths = {
  [AUTH_ROLES_ENUM.ADMIN]: 'signIn',
  [AUTH_ROLES_ENUM.MANUFACTURE]: 'manufacturerSignIn',
  [AUTH_ROLES_ENUM.VENDOR]: 'vendorSignIn'
};
