import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Avatar, Col, Divider, Dropdown, Row, Space } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import logo from 'assets/images/logo.svg';
import styliqueShortLogo from 'assets/images/logo_short.svg';
import vendorProfileQueries from 'graphql/profile/queries';
import { useAuth } from 'auth';
import { useLazyQuery } from '@apollo/client';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { getImageUrl } from '@stylique/tools';
import { Paragraph } from '@stylique/core';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import UserRole from 'hooks/useUser';

const Wrap = styled.header`
  padding: 14px 40px 14px;
  background-color: var(--white);
  box-shadow: 0px 3px 4px -2px rgba(138, 133, 198, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 980;
`;

const StyledText = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

const Logo = styled.img`
  width: 158px;
  height: 26px;
`;

const dataPaths = {
  [AUTH_ROLES_ENUM.ADMIN]: '',
  [AUTH_ROLES_ENUM.VENDOR]: 'vendorProfile',
  [AUTH_ROLES_ENUM.MANUFACTURE]: 'manufacturerProfile'
};

interface Props {
  menu: ItemType[];
  rightMenuItems?: ReactElement;
}

const Header: FC<Props> = ({ menu, rightMenuItems }) => {
  const adminRole = useAuth(state => state.adminRole);
  const user = useAuth(state => state.user);

  const userRole = UserRole();

  const [getProfile, { data }] = useLazyQuery(vendorProfileQueries[adminRole], {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (user && adminRole !== AUTH_ROLES_ENUM.ADMIN) {
      getProfile();
    }
  }, [adminRole, user, getProfile]);

  const profileData = useMemo(() => {
    if (adminRole === AUTH_ROLES_ENUM.ADMIN) {
      return {
        name: 'Stylique',
        logo: styliqueShortLogo
      };
    }

    const profile = data?.[dataPaths[adminRole]]?.data;

    if (!profile) {
      return null;
    }

    return {
      name: profile.short_name,
      logo: getImageUrl(profile.company_logo)
    };
  }, [adminRole, data]);

  return (
    <Wrap>
      <Row justify="space-between" align="middle">
        <Col>
          <Space size={16}>
            <Link to={'/'}>
              <Logo src={logo} alt="" />
            </Link>
            <Divider
              type="vertical"
              style={{ color: '#0000000F', height: '30px' }}
            />
            <StyledText>
              {userRole?.userRoleTranslationWithAccauntType}
            </StyledText>
          </Space>
        </Col>
        <Col>
          <Space direction="horizontal" size={24}>
            {rightMenuItems}
            {!!menu.length && (
              <Dropdown menu={{ items: menu }}>
                <Space size={8}>
                  <Paragraph>{profileData?.name}</Paragraph>
                  <Avatar
                    size={32}
                    icon={<UserOutlined />}
                    src={profileData?.logo}
                  />
                  <DownOutlined style={{ fontSize: 11 }} />
                </Space>
              </Dropdown>
            )}
          </Space>
        </Col>
      </Row>
    </Wrap>
  );
};

export default Header;
