import React from 'react';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';
import { Outlet } from 'react-router-dom';

// const ExhibitionsEvents = React.lazy(() => import('./ExhibitionsEvents'));
const Representatives = React.lazy(() => import('./Representatives'));
const Showrooms = React.lazy(() => import('./Showrooms'));
const News = React.lazy(() => import('./News'));
const Profile = React.lazy(() => import('./Profile'));
const Settings = React.lazy(() => import('./Settings'));

const MyNetwork = React.lazy(() => import('../vendor/Partners/MyNetwork'));
const StyliqueNetwork = React.lazy(
  () => import('../vendor/Partners/StyliqueNetwork')
);

const NetworkProducts = React.lazy(
  () => import('../vendor/Partners/components/Products')
);

const manufacturerConfig = {
  settings: {
    layout: {}
  },
  auth: [AUTH_ROLES_ENUM.MANUFACTURE],
  routes: [
    {
      path: 'manufacturer-profile',
      element: <Outlet />,
      children: [
        {
          auth: [AUTH_ROLES_ENUM.MANUFACTURE],
          path: 'profile-information',
          element: <Profile />
        },
        {
          auth: [AUTH_ROLES_ENUM.MANUFACTURE],
          path: 'settings',
          element: <Settings />
        }
      ]
    },
    // {
    //   path: 'exhibitions-events',
    //   element: <ExhibitionsEvents />
    // },
    {
      path: 'representatives',
      element: <Representatives />
    },
    {
      path: 'showrooms',
      element: <Showrooms />
    },
    {
      path: 'news',
      element: <News />
    },
    {
      path: 'manufacturer-partners',
      element: <Outlet />,
      children: [
        {
          path: 'my-network',
          element: <MyNetwork />
        },
        {
          path: 'stylique-network',
          element: <StyliqueNetwork />
        },
        {
          path: 'stylique-network/products/:id',
          element: <NetworkProducts />
        }
      ]
    }
  ]
};

export default manufacturerConfig;
