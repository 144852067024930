import React from 'react';

import * as Styled from './styles';

type Props = {
  dataSource?: any[];
};

const EnhancedTable: React.FC<Props> = () => {
  return <Styled.Wrapper>EnhancedTable</Styled.Wrapper>;
};

export default EnhancedTable;
