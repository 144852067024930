import { gql } from '@apollo/client';

export const VENDOR_PROFILE_FRAGMENT = gql`
  fragment profileFragment on VendorProfile {
    id
    short_name
    company_logo
    profile_image_crop {
      width
      height
      x
      y
    }
    description
    certificates
    factory_pictures
    brochures
    terms
    imprints
    email
    street
    post_code
    city
    address
    phone_number
    street
    category
    service_offering
    awards
    social_media_links {
      facebook
      instagram
      youtube
      linkedin
      pinterest
      website
      tiktok
    }
  }
`;

export const MANUFATURER_PROFILE_FRAGMENT = gql`
  fragment manufactureProfileFragment on ManufacturesProfile {
    id
    short_name
    company_name
    company_logo
    description
    certificates
    factory_pictures
    brochures
    terms
    imprints
    profile_image_crop {
      width
      height
      x
      y
    }
  }
`;
