import { Form, Input, Modal, Radio, Space } from 'antd';
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { Button, FormItem, TextArea } from '@stylique/core';
import { useAuth } from 'auth';
import { CREATE_USER_FEEDBACK } from 'graphql/feedback/mutation';
import AUTH_ROLES_ENUM from 'auth/authRoles';

import { colorsRadio } from './constants';
import {
  StyledCheckIconWrapper,
  StyledPopupWrapper,
  StyledRadioWrapper,
  StyledRate,
  StyledRateWrapper,
  StyledText,
  StyledTitle
} from './styles';
import { IPropsFeedbackPopup } from './types';

const FeedbackPopup: FC<IPropsFeedbackPopup> = ({ isVisible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('common');
  const [submitted, setSubmitted] = useState(false);
  const userEmail = useAuth(state => state?.user?.email);
  const userId = useAuth(state => state?.user?.id);
  const userRole = useAuth(state => state?.user?.roles?.name?.[0]);

  const [createUserFeedback, { loading }] = useMutation(CREATE_USER_FEEDBACK, {
    onCompleted() {
      form.resetFields();
      setSubmitted(true);
    }
  });

  const submitHandler = async (data: { rate: number; text: string }) => {
    const { rate, text } = data;
    await createUserFeedback({
      variables: {
        input: {
          rate,
          text: text || '',
          email: userEmail,
          page: window.location.href,
          vendorId:
            userRole !== AUTH_ROLES_ENUM.MANUFACTURE ? userId : undefined,
          manufacturerId:
            userRole === AUTH_ROLES_ENUM.MANUFACTURE ? userId : undefined,
          userType: userRole
        }
      }
    });
  };

  const onCloseHandler = () => {
    form.resetFields();
    setSubmitted(false);
  };

  return (
    <StyledPopupWrapper>
      <Modal
        open={isVisible}
        afterClose={onCloseHandler}
        onCancel={onClose}
        footer={() => null}
        style={{
          backgroundColor: 'var(--white)',
          borderRadius: 0
        }}
        centered
        destroyOnClose
        width={484}
      >
        {submitted ? (
          <div>
            <Space
              align="center"
              direction="vertical"
              style={{ width: '100%' }}
            >
              <StyledTitle>{t('feedback.success.title')}</StyledTitle>
              <StyledText weight={400}>{t('feedback.success.text')}</StyledText>
              <StyledCheckIconWrapper>
                <CheckOutlined style={{ fontSize: 23 }} />
              </StyledCheckIconWrapper>
            </Space>
            <Button
              style={{ width: '100%' }}
              type={'primary'}
              onClick={onClose}
            >
              {t('close')}
            </Button>
          </div>
        ) : (
          <div style={{ padding: '0 20px' }}>
            <StyledTitle>{t('feedback.title')}</StyledTitle>
            <StyledText weight={500}>{t('feedback.text')}</StyledText>
            <Form layout="vertical" onFinish={submitHandler} form={form}>
              {() => (
                <>
                  <StyledRateWrapper>
                    <StyledRate>
                      <span className="rate-zero">1</span>
                      <Form.Item name="rate" noStyle>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            marginBottom: 0
                          }}
                        >
                          {colorsRadio.map((color, index) => (
                            <StyledRadioWrapper
                              color={color}
                              key={index}
                              value={index + 1}
                              style={index === 9 ? { marginRight: 0 } : {}}
                            />
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      <span className="rate-ten">10</span>
                    </StyledRate>
                  </StyledRateWrapper>

                  <Form.Item
                    style={{
                      width: '100%'
                    }}
                    name="text"
                    label={t('feedback.label1')}
                  >
                    <TextArea
                      className="textarea"
                      style={{ fontSize: '14px', borderRadius: 0 }}
                      placeholder={t('feedback.placeholder1')}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>

                  <FormItem
                    style={{ marginBottom: ' 32px' }}
                    label={t('feedback.label2')}
                  >
                    <Input
                      style={{ borderRadius: 0 }}
                      type="email"
                      value={userEmail}
                      disabled={true}
                    />
                  </FormItem>
                  <Button
                    loading={loading}
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={'primary'}
                    htmlType="submit"
                    disabled={!form.getFieldValue('rate')}
                  >
                    {t('submit')}
                  </Button>
                </>
              )}
            </Form>
          </div>
        )}
      </Modal>
    </StyledPopupWrapper>
  );
};

export default FeedbackPopup;
