import React from 'react';

import ResetPassword from './index';

const ResetPasswordConfig = {
  settings: {
    layout: {
      navigation: {
        display: false
      }
    }
  },
  auth: [],
  routes: [
    {
      path: '/reset-password/:id',
      element: <ResetPassword />
    }
  ]
};

export default ResetPasswordConfig;
