import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { message } from 'antd';
import { Button } from '@stylique/core';
import { useTranslation } from 'react-i18next';
import client from 'apolloClient';
import { AxiosError } from 'axios';
import { useLazyQuery } from '@apollo/client';
import { UPLOAD_AVALABILATY } from 'graphql/products/queries';

import uploadExcel from 'utils/uploadExcel';
import { GET_VARIATIONS } from '../../../graphql/variationsProducts/queries';

const UploadButton: FC = () => {
  const { t } = useTranslation('admin');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const uploadClicked = () => {
    fileRef.current?.click();
  };

  const [
    uploadAvalabilaty,
    {
      data: {
        uploadAvalabilaty: { data: uploadAvalabilatyData = null } = {}
      } = {}
    }
  ] = useLazyQuery(UPLOAD_AVALABILATY, {
    fetchPolicy: 'no-cache'
  });

  const handleUploadFile = useCallback(
    async (event: Event) => {
      try {
        await uploadExcel(event, percent => {
          setUploadPercent(percent);
        });
        message.success(t('messages.successfully_uploaded', { ns: 'common' }));
        client.refetchQueries({ include: [GET_VARIATIONS] });
      } catch (error) {
        const {
          response: {
            data: {
              message: msg = t('messages.something_went_wrong', {
                ns: 'common'
              })
            } = {}
          } = {}
        } = error as AxiosError;

        message.error(msg);
      } finally {
        if (fileRef.current) {
          fileRef.current.value = '';
        }

        setUploadPercent(0);
      }
    },
    [t]
  );

  useEffect(() => {
    const file = fileRef.current;

    file?.addEventListener('change', handleUploadFile);

    return () => {
      file?.removeEventListener('change', handleUploadFile);
    };
  }, [handleUploadFile]);

  useEffect(() => {
    uploadAvalabilaty();
  }, [uploadAvalabilaty]);

  useEffect(() => {
    let requestTime: ReturnType<typeof setInterval> | undefined;

    if (uploadPercent >= 0) {
      uploadAvalabilaty();
    }

    if (uploadAvalabilatyData === false) {
      requestTime = setInterval(() => {
        uploadAvalabilaty();
      }, 10000);
    } else {
      clearInterval(requestTime);
    }

    return () => clearInterval(requestTime);
  }, [uploadAvalabilaty, uploadAvalabilatyData, uploadPercent]);

  return (
    <Button
      type="primary"
      onClick={uploadClicked}
      loading={!uploadAvalabilatyData || uploadPercent >= 100}
      disabled={!uploadAvalabilatyData || uploadPercent >= 100}
    >
      <input
        ref={fileRef}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        hidden
      />
      {t(uploadPercent >= 100 ? 'processing' : 'upload_excel', {
        ns: 'common'
      })}
      {!!uploadPercent && uploadPercent < 100 && ` ${uploadPercent}%`}
    </Button>
  );
};

export default memo(UploadButton);
