import { Table } from 'antd';
import React, { FC, useMemo } from 'react';
import { variations_variations_data_results as VariationType } from '__generated__/variations';
import { PAGE_SIZE_OPTIONS } from 'constants/pageSizeOptions';
import { variationById_variationById_data } from '__generated__/variationById';

import useQueryParams from 'hooks/useQueryParams';
import { TABLE_SCROLL_SIZE } from 'constants/ui';

interface IProps {
  columns: any;
  results: variationById_variationById_data[] | null;
  total: number;
  loading: boolean;
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  selected: number[];
}

const VariationsTable: FC<IProps> = ({
  columns,
  results,
  total,
  loading,
  setSelected,
  selected
}) => {
  const { setParams, getParams } = useQueryParams();

  const currentPage = useMemo(
    () => Number(getParams(['page'])[0].page || 0),
    [getParams]
  );

  const currentPageSize = useMemo(
    () => Number(getParams(['pageSize'])[0].pageSize || 10),
    [getParams]
  );

  return (
    <Table
      columns={columns}
      dataSource={results as VariationType[]}
      loading={loading}
      bordered={true}
      scroll={{ y: TABLE_SCROLL_SIZE }}
      pagination={{
        total,
        pageSize: currentPageSize,
        current: currentPage + 1,
        onChange: (page: number, pageSize: number) =>
          setParams([
            { key: 'page', value: String(page - 1) },
            { key: 'pageSize', value: String(pageSize) }
          ]),
        showSizeChanger: true,
        pageSizeOptions: PAGE_SIZE_OPTIONS
      }}
      rowSelection={{
        hideSelectAll: false,
        onChange: (_, selectedRows) => {
          const allSelected = selectedRows.map((itm: VariationType) =>
            Number(itm.id)
          );

          setSelected(allSelected);
        },
        onSelect: row => {
          setSelected((prev: number[]) => {
            if (row.id && prev.includes(row.id)) {
              return prev.filter((id: number) => id !== row.id);
            }

            return [...prev, row.id || 0];
          });
        },
        selectedRowKeys: selected
      }}
      rowKey="id"
    />
  );
};

export default VariationsTable;
