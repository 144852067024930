import { gql } from '@apollo/client';

import { VARIATION_LIST_FRAGMENT } from './fragments';

export const ALL_DELETE_VARIATION = gql`
  mutation variationDeleteByIds($ids: [ID!]!) {
    variationDeleteByIds(ids: $ids) {
      message
    }
  }
`;

export const DELETE_VARIATION = gql`
  mutation variationDelete($id: ID!) {
    variationDelete(id: $id) {
      message
    }
  }
`;

export const UPDATE_VARIATION = gql`
  mutation variationUpdate(
    $id: ID!
    $input: ProductsVariationCombinationsInput!
  ) {
    variationUpdate(id: $id, input: $input) {
      data {
        ...ProductVariationsListFragment
      }
      status
      message
    }
  }
  ${VARIATION_LIST_FRAGMENT}
`;

export const LINK_TO_SAMPLE_BOX = gql`
  mutation linkSampleBox($input: SampleBoxInput!) {
    linkSampleBox(input: $input) {
      data {
        id
        parent_id
        sample_box_id
      }
      status
      message
    }
  }
`;

export const PUBLISH_VARIATIONS = gql`
  mutation publishVariations($input: PublishVariationsInput!) {
    publishVariations(input: $input) {
      message
    }
  }
`;

export const APPLY_VARIATIONS_SERVICES = gql`
  mutation applyVariationsServices($input: VariationsServicesInput!) {
    applyVariationsServices(input: $input) {
      data
      status
      message
    }
  }
`;

export const UPDATE_PRODUCT_VARIATIONS_STATUS = gql`
  mutation updateProductVariationsStatus($input: updateStatusVariationsInput!) {
    updateProductVariationsStatus(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_PRODUCT_VARIATION_IMAGES = gql`
  mutation updateProductVariationImages(
    $id: Int!
    $input: ProductVariationImages!
  ) {
    updateProductVariationImages(id: $id, input: $input) {
      status
      message
    }
  }
`;
