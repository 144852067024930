import React from 'react';
import { FormItem, Input } from '@stylique/core';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

const ImageText = () => {
  const { t } = useTranslation('admin');
  const form = Form.useFormInstance();
  const promoLink = form.getFieldValue(['promo_block', 'link']);
  Form.useWatch(['promo_block', 'link'], form);

  return (
    <FormItem
      name={['promo_block', 'promo_text']}
      label={t('website_menu.image_text')}
    >
      <Input
        size="middle"
        style={{ fontSize: '16px', padding: '6px 12px' }}
        placeholder={t('website_menu.image_text_placeholder')}
        onChange={e => {
          if (!e.target.value?.length && !promoLink.length) {
            form.setFields([{ name: ['promo_block', 'image'], errors: [''] }]);
          }
        }}
      />
    </FormItem>
  );
};

export default ImageText;
