import { PageHeader } from 'antd';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from './UploadButton';
import AddNewProductButton from './AddNewProductButton';
import { StyledVariationHeader } from '../styles';

interface IProps {
  userType: AUTH_ROLES_ENUM;
  isLinkingProducts: boolean;
}

const VariationsHeader: FC<IProps> = ({ userType, isLinkingProducts }) => {
  const { t } = useTranslation('common');
  const isManufacturer = userType === AUTH_ROLES_ENUM.ADMIN;

  const subTitle = useMemo(
    () =>
      isLinkingProducts
        ? ''
        : !isManufacturer
        ? ''
        : t('products.subtitle', { ns: 'admin' }),
    [isLinkingProducts, isManufacturer, t]
  );

  const genExtra = useCallback(() => {
    if (isManufacturer) {
      return [
        <AddNewProductButton key="add-products" type="ghost" />,
        <UploadButton key="products-upload-button" />
      ];
    }

    return [<AddNewProductButton key="add-products" type="primary" />];
  }, [isManufacturer]);

  return (
    <StyledVariationHeader>
      <PageHeader
        ghost={false}
        style={{ padding: '38px 0 22px 0' }}
        title={<div>{t('navigation.variations')}</div>}
        subTitle={
          <p style={{ fontSize: '13px', color: '#8692a6' }}>{subTitle}</p>
        }
        extra={isLinkingProducts ? [] : genExtra()}
      />
    </StyledVariationHeader>
  );
};

export default VariationsHeader;
