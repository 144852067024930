import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledFeedbackWrapper = styled.div`
  position: fixed;
  right: -51px;
  top: 50%;
  margin-top: -18px;
  z-index: 1000;
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
  &:hover {
    right: -48px;
    transform: scale(1.02) rotate(-90deg);
  }
`;

export const FeedBackBtn = styled.button`
  width: 138px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray-10);
  font-size: 16px;
  color: var(--white);
  line-height: 1.5;
  cursor: pointer;
  border: none;
  padding: 0;
`;

export const StyledPopupWrapper = styled.div`
  & .text {
    font-weight: 500;
    font-size: 16px;
    color: var(--gray-9);
    margin-bottom: 32px;
    line-height: 1.5;
    text-align: center;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: var(--gray-10);
  margin-bottom: 16px;
  line-height: 1.3;
  text-align: center;
  @media screen and (max-width: 764px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const StyledText = styled.p<{
  weight: number;
}>`
  max-width: 386px;
  font-weight: ${({ weight }) => weight};
  font-size: 16px;
  color: var(--gray-10);
  margin-bottom: 32px;
  line-height: 1.5;
  text-align: center;
  @media screen and (max-width: 764px) {
    font-weight: 400;
  }
`;

export const StyledCheckIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: var(--gray-8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const StyledRadioWrapper = styled(Radio)<{
  color: string;
}>`
  position: relative;
  margin-right: 16px;
  & .ant-radio-inner {
    border: 2px solid ${({ color }) => color};
  }
  & .ant-radio-checked::after {
    border: 2px solid ${({ color }) => color};
  }
  & .ant-radio-inner::after {
    background-color: ${({ color }) => color};
  }
  @media screen and (max-width: 764px) {
    margin-right: 12px;
  }
`;

export const StyledRateWrapper = styled.div`
  @media screen and (max-width: 764px) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledRate = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  & .rate-zero,
  & .rate-ten {
    font-size: 14px;
  }
  & .rate-zero {
    margin-right: 32px;
  }
  & .rate-ten {
    margin-left: 32px;
  }
  @media screen and (max-width: 764px) {
    justify-content: center;
    & .rate-zero,
    & .rate-ten {
      position: absolute;
    }
    & .rate-zero {
      top: 24px;
      left: 0;
    }
    & .rate-ten {
      top: 24px;
      right: 0;
    }
  }
`;
