import { message } from 'antd';
import { MutationFunction } from '@apollo/client';
import { TFunction } from 'react-i18next';

import {
  ISubmitLevel3,
  TCurrentItemId,
  TGetMenuItems,
  TParentItemId
} from '../types';

const submitLevel3 = async (
  data: ISubmitLevel3,
  createMenuItem: MutationFunction,
  parentItemId: TParentItemId,
  getCurrentMenuData: TGetMenuItems,
  type: 'create' | 'update',
  updateMenuItem: MutationFunction,
  currentItemId: TCurrentItemId,
  t: TFunction
) => {
  const { name, seo_data, marketing } = data;

  const input = {
    name,
    level: 3,
    seo_data: {
      title: seo_data.title || undefined,
      description: seo_data.description || undefined,
      meteKeywords: seo_data.meteKeywords || []
    },
    marketing: {
      title: marketing.title,
      text: marketing.text
    }
  };

  if (type === 'create') {
    await createMenuItem({
      variables: {
        input: {
          ...input,
          parent_id: parentItemId ? Number(parentItemId) : undefined
        }
      }
    }).then(async () => {
      message.success(t('messages.item_saved', { ns: 'common' }));
      await getCurrentMenuData({
        variables: {
          id: parentItemId ? Number(parentItemId) : undefined
        }
      });
    });
  }

  if (type === 'update') {
    await updateMenuItem({
      variables: {
        id: Number(currentItemId),
        input
      }
    }).then(async () => {
      message.success(t('messages.item_saved', { ns: 'common' }));
      await getCurrentMenuData({
        variables: {
          id: parentItemId ? Number(parentItemId) : undefined
        }
      });
    });
  }
};

export default submitLevel3;
