import client from 'apolloClient';
import { GET_ATTRIBUTE_OPTIONS } from 'graphql/attributes/queries';
import moment from 'moment';

import {
  AttributeConvertedOptions,
  Variation
} from '../providers/Product/types';

export const formatThreeSelectCategories = (categories?: any) => {
  if (!categories) return [];

  return categories.map((item: any) => {
    return {
      title: item.name,
      key: item.id,
      value: item.id,
      children: formatThreeSelectCategories(item.children)
    };
  });
};

export const createVariationValues = (data: any) => {
  if (!data.values) return [];

  return data.values.map((option: any) => {
    const { attributeOptions: { data: options = [] } = {} } = client.readQuery({
      query: GET_ATTRIBUTE_OPTIONS,
      variables: { id: data.attribute }
    });

    return options.find((item: any) => item.id === option);
  });
};

export const createVariations = (
  data: AttributeConvertedOptions[]
): Variation[] => {
  const parts: any = data
    .map(item => item.values)
    .filter(item => item && item.length);

  if (!parts.length) {
    return [];
  }

  const result: Variation[] = [];
  const max = parts.length - 1;

  const combine = (arr: Variation, i: number) => {
    for (let j = 0, l = parts[i].length; j < l; j++) {
      const current = {
        combinations: [...arr.combinations]
      };

      current.combinations.push(parts[i][j]);

      if (i == max) result.push(current);
      else combine(current, i + 1);
    }
  };

  combine({ combinations: [] }, 0);

  return result;
};

export const convertAttributeFamilyData = (data: any) => {
  if (!data.family) {
    return null;
  }

  const result = data.family.map((item: any) => ({
    name: item?.familyGroup?.name,
    id: item?.familyGroup?.id,
    group: item?.familyGroup?.group?.map((group: any) => {
      return {
        id: group?.id,
        attribute: {
          ...group?.attribute,
          checked: item?.groupAttributes?.some(
            (groupAttribute: any) =>
              groupAttribute?.attribute?.id === group?.attribute?.id
          )
        }
      };
    })
  }));

  return result;
};

export const generateGUID = (): string => {
  const S4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return S4() + S4();
};

export function getTLD() {
  const parts = window.location.hostname.split('.');

  return parts[parts.length - 1];
}

export const convertDiscountDate = (date: string, addOffset = true) => {
  const momentObj = moment(date);
  const convertedTime = addOffset ? momentObj.utcOffset(60) : momentObj;
  convertedTime.hour(0).minute(0).second(0).millisecond(0);

  return convertedTime.format('YYYY-MM-DD HH:mm:ss.SSS ZZ');
};
