import { gql } from '@apollo/client';

import { CATEGORIES_SUBS_FRAGMENT, CATEGORY_FRAGMENT } from './fragments';

export const GET_CATEGORIES_WITH_SUBS = gql`
  query categories {
    categories {
      data {
        ...CategoriesSubFragment
      }
      message
      status
    }
  }
  ${CATEGORIES_SUBS_FRAGMENT}
`;

export const GET_CATEGORIES = gql`
  query allCategories {
    allCategories {
      data {
        ...CategoryFragment
      }
      message
      status
    }
  }
  ${CATEGORY_FRAGMENT}
`;
