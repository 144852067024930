import { gql } from '@apollo/client';

import { ATTRIBUTE_FRAGMENT } from '../attributes/fragments';

export const ATTRIBUTE_FAMILY_FRAGMENT = gql`
  fragment AttributeFamilyFragment on AttributeFamily {
    id
    name
    slug
    unit
    product_type
    family {
      id
      groupAttributes {
        id
        attribute {
          ...AttributeFragment
          options {
            id
            name
          }
        }
      }
      familyGroup {
        id
        name
        slug
        place_use
        group {
          id
          attribute {
            ...AttributeFragment
            options {
              id
              name
            }
          }
        }
      }
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;
