import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement> & { pathFill?: string };

const Envelope: FC<Props> = props => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5 6H7.5C6.30653 6 5.16193 6.47411 4.31802 7.31802C3.47411 8.16193 3 9.30653 3 10.5V25.5C3 26.6935 3.47411 27.8381 4.31802 28.682C5.16193 29.5259 6.30653 30 7.5 30H28.5C29.6935 30 30.8381 29.5259 31.682 28.682C32.5259 27.8381 33 26.6935 33 25.5V10.5C33 9.30653 32.5259 8.16193 31.682 7.31802C30.8381 6.47411 29.6935 6 28.5 6V6ZM7.5 9H28.5C28.8978 9 29.2794 9.15804 29.5607 9.43934C29.842 9.72064 30 10.1022 30 10.5L18 17.82L6 10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9V9ZM30 25.5C30 25.8978 29.842 26.2794 29.5607 26.5607C29.2794 26.842 28.8978 27 28.5 27H7.5C7.10218 27 6.72064 26.842 6.43934 26.5607C6.15804 26.2794 6 25.8978 6 25.5V13.92L17.22 20.775C17.448 20.9067 17.7067 20.976 17.97 20.976C18.2333 20.976 18.492 20.9067 18.72 20.775L30 13.92V25.5Z"
      fill="#191919"
    />
  </SVG>
);

export default Envelope;
