import React, { RefObject, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormItem, Loading, Select } from '@stylique/core';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { paginate } from '@stylique/tools';
import { GET_NETWORK_VENDOR_PRODUCTS } from 'graphql/partners/queries';
import { getNetworkVendorProducts_getNetworkVendorProducts_data_results as IProduct } from '__generated__/getNetworkVendorProducts';

const ByExactProduct = () => {
  const { t } = useTranslation('admin');
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const form = Form.useFormInstance();
  const isChecked = Form.useWatch('exactProductCheckbox', form);

  const [
    getNetworkVendorProducts,
    {
      data: {
        getNetworkVendorProducts: { data: { results = [] } = {} } = {}
      } = {},
      loading: isGetVendorProducts
    }
  ] = useLazyQuery(GET_NETWORK_VENDOR_PRODUCTS, {
    fetchPolicy: 'no-cache'
  });

  const onSearchHandler = async (name: string) => {
    await getNetworkVendorProducts({
      variables: {
        query: {
          ...paginate(1000, 0),
          vendorId: '1',
          search: name
        }
      }
    });
  };

  const options = useMemo(() => {
    return results.map((product: IProduct) => ({
      label: product?.sku,
      value: product?.sku
    }));
  }, [results]);

  return (
    <div ref={parentRef}>
      <FormItem valuePropName="checked" name="exactProductCheckbox">
        <Checkbox style={{ fontSize: '14px' }}>
          {t('website_menu.exact_product')}
        </Checkbox>
      </FormItem>
      {isChecked ? (
        <FormItem name="products">
          <Select
            mode="multiple"
            size="middle"
            loading={isGetVendorProducts}
            allowClear
            style={{ fontSize: '14px', margin: '0' }}
            placeholder={t('website_menu.exact_product_placeholder')}
            filterOption={false}
            showSearch={true}
            onSearch={onSearchHandler}
            options={options}
            getPopupContainer={() => parentRef.current as HTMLElement}
            dropdownRender={menu => (
              <>{!isGetVendorProducts ? menu : <Loading />}</>
            )}
          />
        </FormItem>
      ) : null}
    </div>
  );
};

export default ByExactProduct;
