import React from 'react';
import styled, { keyframes } from 'styled-components';

type JumpingDotsProps = {
  light?: boolean;
};

const JumpingDots: React.FC<JumpingDotsProps> = ({ light }) => {
  return (
    <Container $light={light}>
      <Dot $delay="200ms" />
      <Dot $delay="300ms" />
      <Dot $delay="400ms" />
    </Container>
  );
};

const jumpAnimation = keyframes`
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40%, 100% {
    bottom: 0px;
  }
`;

const Dot = styled.span<{ $delay?: string }>`
  width: 3px;
  height: 3px;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 0.5px;
  position: relative;
  animation: ${jumpAnimation} 2s infinite;
  animation-delay: ${props => props.$delay};
  background-color: var(--sunrise-yellow);

  &:hover {
    animation: none;
  }
`;

const Container = styled.span<{ $light?: boolean }>`
  ${p =>
    p.$light
      ? `position: static;`
      : `position: absolute; right: 5px;bottom: 5px;`}

  ${Dot} {
    ${props => props.$light && 'background-color: var(--white);'}
  }
`;

export default JumpingDots;
