import React, { FC, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableBodyRow';

interface Props {
  index: number;
  className?: string;
  style: CSSProperties;
  moveRow: (dragIndex: number, hoverIndex: number) => null;
}

const DraggableRow: FC<Props> = ({
  index,
  className,
  style,
  moveRow,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = (monitor.getItem() || { index: 0 }) as {
        index: number;
      };

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DraggableRow;
