import { attributeOptions_attributeOptions_data } from '__generated__/attributeOptions';

import {
  ActionTypes,
  State,
  Action,
  Variation,
  AttributeWithOptions,
  ToggleParam,
  VariationInput,
  ImageType
} from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.TOGGLE_PARAM: {
      const { key, value } = action.data as ToggleParam;

      return { ...state, [key]: value };
    }

    case ActionTypes.SET_VARIATIONS:
      return { ...state, variations: action.data as Variation[] };

    case ActionTypes.SET_ATTRIBUTES: {
      const { data } = action as { data: AttributeWithOptions };

      const attributes = [...state.attributes];

      if (data.index > -1) {
        attributes[data.index] = {
          attribute: data.attribute,
          values: data.values
        };
      } else {
        attributes.push({
          attribute: data.attribute,
          values: data.values
        });
      }

      return { ...state, attributes };
    }

    case ActionTypes.SET_ALL_ATTRIBUTES:
      return { ...state, attributes: action.data as AttributeWithOptions[] };

    case ActionTypes.ADD_VARIATION: {
      const { data } = action as {
        data: attributeOptions_attributeOptions_data[];
      };

      const variations = [...state.variations];
      variations.unshift({ combinations: data });

      return { ...state, variations };
    }

    case ActionTypes.EDIT_VARIATION: {
      const { index, data } = action.data as {
        index: number;
        data: attributeOptions_attributeOptions_data[];
      };

      const variations = [...state.variations];
      variations[index] = { ...variations[index], combinations: data };

      return { ...state, variations };
    }

    case ActionTypes.DELETE_VARIATION:
      return {
        ...state,
        isAutomatic: false,
        variations: state.variations.filter((_, index) => index !== action.data)
      };
    case ActionTypes.REMOVE_ATTRIBUTE:
      return {
        ...state,
        attributes: state.attributes.filter((_, index) => index !== action.data)
      };
    case ActionTypes.SET_INDEX_TO_EDIT:
      return {
        ...state,
        isVariationPopupVisible: true,
        indexToEdit: action.data as number
      };
    case ActionTypes.DELETE_VARIATION_BY_OPTION:
      return {
        ...state,
        variations: state.variations.filter(variation =>
          variation.combinations.every(item => {
            if (Array.isArray(action.data)) {
              return !action.data.includes(item.id);
            }

            return item.id !== action.data;
          })
        )
      };

    case ActionTypes.SET_INDEX_TO_FILL_PROPS: {
      const index = action.data as number;

      return {
        ...state,
        indexToFillProps: index,
        variationImages: index > -1 ? state.variations[index].images || [] : []
      };
    }

    case ActionTypes.SET_VARIATION_PROPERTIES: {
      const { index, data } = action.data as {
        index: number;
        data: VariationInput;
      };

      const variations = [...state.variations];

      variations[index].inputs = data.inputs;
      variations[index].name = data.name;
      variations[index].is_simple_product = data.is_simple_product;
      variations[index].slug = data.slug;
      variations[index].sku = data.sku;
      variations[index].price = data.price;
      variations[index].tags = data.tags;
      variations[index].images = state.variationImages;

      return {
        ...state,
        variations,
        indexToFillProps: -1,
        variationImages: []
      };
    }

    case ActionTypes.SET_ATTRIBUTE_FAMILY:
      return { ...state, attributeFamily: action.data as number };
    case ActionTypes.SET_TYPE:
      return {
        ...state,
        type: action.data as 1 | 2
      };
    case ActionTypes.SET_VARIATION_IMAGES:
      return { ...state, variationImages: action.data as ImageType[] };
    case ActionTypes.SET_INITIAL_VALUES:
      return { ...state, ...(action.data as any) };
    case ActionTypes.SET_STATUS:
      return { ...state, status: action.data as 1 | 2 };
    default:
      return state;
  }
};

export default reducer;
