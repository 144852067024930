import React, { FC, Suspense, useEffect, useMemo, useState } from 'react';
import routesConfig from 'configs/routesConfig';
import { matchRoutes, useLocation } from 'react-router-dom';
import { PageSetting } from 'types/pageSettings';
import { Loading } from '@stylique/core';

import BoxLayout from './BoxLayout';
import useSettings from 'hooks/useSettings';
import { MainLayoutWrapper } from './styles';

const Layout: FC = () => {
  const location = useLocation();
  const [awaitRender, setawaitRender] = useState(true);
  const matches = matchRoutes(routesConfig, location.pathname);
  const setSettings = useSettings(state => state.setSettings);
  const setNavigation = useSettings(state => state.setNavigation);

  const routeSettings = useMemo(() => {
    return matches ? matches[matches.length - 1].route.settings : {};
  }, [matches]);

  useEffect(() => {
    setSettings(routeSettings as PageSetting);
    setawaitRender(false);
  }, [routeSettings, setNavigation, setSettings]);

  if (awaitRender) {
    return null;
  }

  return (
    <MainLayoutWrapper>
      <Suspense fallback={<Loading />}>
        <BoxLayout />
      </Suspense>
    </MainLayoutWrapper>
  );
};

export default Layout;
