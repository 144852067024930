import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Plus: FC<Props> = props => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="#D7D8DB"
    />
    <path
      d="M16.6667 11.3337H12.6667V7.33366C12.6667 7.15685 12.5964 6.98728 12.4714 6.86225C12.3464 6.73723 12.1768 6.66699 12 6.66699C11.8232 6.66699 11.6536 6.73723 11.5286 6.86225C11.4036 6.98728 11.3333 7.15685 11.3333 7.33366V11.3337H7.33333C7.15652 11.3337 6.98695 11.4039 6.86193 11.5289C6.7369 11.6539 6.66666 11.8235 6.66666 12.0003C6.66666 12.1771 6.7369 12.3467 6.86193 12.4717C6.98695 12.5968 7.15652 12.667 7.33333 12.667H11.3333V16.667C11.3333 16.8438 11.4036 17.0134 11.5286 17.1384C11.6536 17.2634 11.8232 17.3337 12 17.3337C12.1768 17.3337 12.3464 17.2634 12.4714 17.1384C12.5964 17.0134 12.6667 16.8438 12.6667 16.667V12.667H16.6667C16.8435 12.667 17.013 12.5968 17.1381 12.4717C17.2631 12.3467 17.3333 12.1771 17.3333 12.0003C17.3333 11.8235 17.2631 11.6539 17.1381 11.5289C17.013 11.4039 16.8435 11.3337 16.6667 11.3337Z"
      fill="#1E2229"
    />
  </SVG>
);

export default Plus;
