import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const NoVariation: FC<Props> = props => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.08333 10.8783L9.58333 15.2116C9.71001 15.2848 9.85372 15.3233 10 15.3233C10.1463 15.3233 10.29 15.2848 10.4167 15.2116L17.9167 10.8783C18.0426 10.8056 18.1473 10.7012 18.2204 10.5755C18.2935 10.4497 18.3325 10.3071 18.3333 10.1616C18.3339 10.0148 18.2957 9.87042 18.2225 9.7431C18.1493 9.61579 18.0438 9.51007 17.9167 9.43665L10.4167 5.11165C10.29 5.03851 10.1463 5 10 5C9.85372 5 9.71001 5.03851 9.58333 5.11165L2.08333 9.43665C1.95616 9.51007 1.85065 9.61579 1.77748 9.7431C1.70431 9.87042 1.66608 10.0148 1.66666 10.1616C1.66754 10.3071 1.70646 10.4497 1.77956 10.5755C1.85266 10.7012 1.95738 10.8056 2.08333 10.8783ZM10 6.82831L15.8333 10.1616L10 13.495L4.16666 10.1616L10 6.82831Z"
      fill="#1E2229"
    />
  </SVG>
);

export default NoVariation;
