import { gql } from '@apollo/client';

export const CREATE_MENU_ITEM = gql`
  mutation createMenuItem($input: MenuItemInput) {
    createMenuItem(input: $input) {
      message
      status
    }
  }
`;

export const DELETE_MENU_ITEM = gql`
  mutation deleteMenuItem($id: ID!) {
    deleteMenuItem(id: $id) {
      message
      status
    }
  }
`;

export const UPDATE_MENU_ITEM_STATUS = gql`
  mutation updateMenuItemStatus($id: ID!, $status: ItemStatusEnum!) {
    updateMenuItemStatus(id: $id, status: $status) {
      message
      status
    }
  }
`;

export const UPDATE_MENU_ITEM = gql`
  mutation updateMenuItem($id: ID!, $input: MenuItemInput) {
    updateMenuItem(id: $id, input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_MENU_ITEM_ORDERS = gql`
  mutation updateMenuItemOrders($input: [ItemSortIndex]) {
    updateMenuItemOrders(input: $input) {
      message
      status
    }
  }
`;

export const SYNC_MENU_ITEM_PRODUCTS = gql`
  mutation syncMenuItemProducts {
    syncMenuItemProducts {
      message
      status
    }
  }
`;
