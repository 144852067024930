import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'auth';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import routesConfig from 'configs/routesConfig';
import { StUtils } from '@stylique/utils';
import { RoutePayload } from 'types/routes';

const Authorization: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessGranted, setAccessGranted] = useState(true);
  const userRole = useAuth(state => state.user?.roles.name ?? null);

  useEffect(() => {
    setAccessGranted(() => {
      const matches = matchRoutes(routesConfig, location.pathname);
      let permissionType: RoutePayload['permissionType'] =
        'hasOneOfPermissions';

      if (matches) {
        const match = matches[matches.length - 1]; // get exact match

        if (match && match.route.permissionType) {
          permissionType = match.route.permissionType;
        }

        return StUtils[permissionType](match.route.auth, userRole);
      }

      return true;
    });
  }, [location.pathname, userRole]);

  const redirectRoute = useCallback(() => {
    const { pathname, state } = location;
    const redirectUrl = state?.redirectUrl ?? '/';

    if (!userRole || userRole.length === 0) {
      navigate('/login', { state: { redirectUrl: pathname } });
    } else {
      navigate(redirectUrl);
    }
  }, [location, navigate, userRole]);

  useEffect(() => {
    if (!accessGranted) {
      redirectRoute();
    }
  }, [accessGranted, redirectRoute]);

  return accessGranted ? <>{children}</> : null;
};

export default Authorization;
