import commonEn from './en/common.json';
import adminEn from './en/admin.json';
import manufacturerEn from './en/manufacturer.json';
import vendorEn from './en/vendor.json';
//
import commonDe from './de/common.json';
import adminDe from './de/admin.json';
import manufacturerDe from './de/manufacturer.json';
import vendorDe from './de/vendor.json';

export const en = {
  common: commonEn,
  admin: adminEn,
  manufacturer: manufacturerEn,
  vendor: vendorEn
};

export const de = {
  common: commonDe,
  admin: adminDe,
  manufacturer: manufacturerDe,
  vendor: vendorDe
};
