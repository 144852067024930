import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Loading } from '@stylique/core';

import getUserQueries from './graphql/queries';
import useAuth from './useAuth';
import AUTH_ROLES_ENUM from './authRoles';

const Wrapper = styled.main`
  min-height: 100vh;
`;

const dataPaths = {
  [AUTH_ROLES_ENUM.VENDOR]: 'vendorMe',
  [AUTH_ROLES_ENUM.ADMIN]: 'me',
  [AUTH_ROLES_ENUM.MANUFACTURE]: 'manufacturer'
};

const Auth: FC = ({ children }) => {
  const adminRole = useAuth(state => state.adminRole);
  const setData = useAuth(state => state.setData);
  const isLoggedIn = useAuth(state => !!state.token);
  const setAdminRole = useAuth(state => state.setAdminRole);

  useEffect(() => {
    const title = adminRole === AUTH_ROLES_ENUM.ADMIN ? 'Admin' : 'Partner';
    document.title = `Stylique | ${title}`;
  }, [adminRole, setAdminRole]);

  const { loading } = useQuery(getUserQueries[adminRole], {
    skip: !adminRole || !isLoggedIn,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      const userData = data[dataPaths[adminRole]];

      if (userData && userData.data) {
        setData({ user: userData.data });
      }
    }
  });

  if (!adminRole) {
    return <>no adminRole</>;
  }

  return <Wrapper>{loading ? <Loading /> : children}</Wrapper>;
};

export default Auth;
