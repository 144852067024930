export enum AUTH_ROLES_ENUM {
  ADMIN = 'admin',
  VENDOR = 'vendor',
  CRAFTSMAN = 'craftsman',
  MANUFACTURE = 'manufacturer',
  ARCHITECT = 'architect',
  WHOLESALER = 'wholesaler',
  ACCOUNT_PRO = 'pro',
  ACCOUNT_BASIC = 'basic'
}

export default AUTH_ROLES_ENUM;
