import { gql } from '@apollo/client';

export const SERVICE_GROUPS_FRAGMENT = gql`
  fragment ServiceGroupsFragment on ServiceGroupWithServices {
    id
    name
    services {
      id
      name
      duration
      groupId
      description
    }
  }
`;
