import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButtonResend = styled(Button)`
  height: auto;
  padding: 0;
  color: var(--primary-color-2);

  &.ant-btn-link:hover {
    background-color: transparent !important;
  }
  &.ant-btn-link span {
    text-decoration: underline;
  }
  &.ant-btn-link[disabled] {
    color: var(--gray-12);
  }
`;
