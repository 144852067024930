import React from 'react';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';

import Dashboard from './index';

const DashboardConfig = {
  settings: {
    layout: {
      navigation: {
        display: true
      }
    }
  },
  auth: [
    AUTH_ROLES_ENUM.ADMIN,
    AUTH_ROLES_ENUM.VENDOR,
    AUTH_ROLES_ENUM.CRAFTSMAN,
    AUTH_ROLES_ENUM.MANUFACTURE,
    AUTH_ROLES_ENUM.ARCHITECT,
    AUTH_ROLES_ENUM.ACCOUNT_PRO,
    AUTH_ROLES_ENUM.ACCOUNT_BASIC
  ],
  routes: [
    {
      path: '',
      element: <Dashboard />
    }
  ]
};

export default DashboardConfig;
