import { message } from 'antd';

export const dropzoneValidator = (file: File) => {
  const isPdf: boolean = file.type === 'application/pdf';
  const isPng: boolean = file.type === 'image/png';
  const isJpg: boolean = file.type === 'image/jpg';
  const isJpeg: boolean = file.type === 'image/jpeg';

  if (isPdf) {
    const isLt5M: boolean = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      message.error('Allowed max size is 5MB for PDF files');

      return {
        code: 'file-too-large',
        message: 'Allowed max size is 5MB for PDF files'
      };
    }
  }

  if (isPng || isJpg || isJpeg) {
    console.log('file.size', file.size);
    const isLt1M: boolean = file.size / 1024 / 1024 < 1;

    if (!isLt1M) {
      message.error('Allowed max size is 1MB for Image');

      return {
        code: 'file-too-large',
        message: 'Allowed max size is 1MB for Image'
      };
    }
  }

  return null;
};
