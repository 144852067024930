import axios from 'axios';

export const fileInstance = axios.create({
  withCredentials: false,
  baseURL: `${process.env.REACT_APP_UPLOAD_DATA_URL}/upload`
});

export const setFileInstanceToken = (token: string | null): void => {
  if (fileInstance.defaults.headers && token) {
    fileInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
};

export default async (
  event: Event,
  getPercent?: (value: number) => void
): Promise<void> => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];

  if (file) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await fileInstance.post('/products', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const { total, loaded } = progressEvent;
          const percent = Math.round((loaded / total) * 100);
          getPercent && getPercent(percent);
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
