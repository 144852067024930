import React from 'react';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';
import { Outlet } from 'react-router-dom';

const BlogListPage = React.lazy(() => import('./BlogListPage'));
const BlogDetails = React.lazy(() => import('./BlogDetails'));

const settings = {
  layout: {
    navigation: {
      display: false
    }
  }
};

const PartnerBlogConfig = {
  settings: {
    layout: {}
  },
  auth: [
    AUTH_ROLES_ENUM.MANUFACTURE,
    AUTH_ROLES_ENUM.VENDOR,
    AUTH_ROLES_ENUM.CRAFTSMAN,
    AUTH_ROLES_ENUM.ARCHITECT,
    AUTH_ROLES_ENUM.ACCOUNT_PRO,
    AUTH_ROLES_ENUM.ACCOUNT_BASIC
  ],
  routes: [
    {
      path: 'blog',
      element: <Outlet />,
      children: [
        { path: 'list', element: <BlogListPage /> },
        {
          settings,
          path: 'create',
          element: <BlogDetails />
        },
        {
          settings,
          path: ':id',
          element: <BlogDetails />
        }
      ]
    }
  ]
};

export default PartnerBlogConfig;
