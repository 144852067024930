import {
  categories_categories_data,
  categories_categories_data_children
} from '__generated__/categories';
import { productById_productById_data_category } from '__generated__/productById';
import AUTH_ROLES_ENUM from 'auth/authRoles';
import { UserResponse } from 'auth/types';

export const genUserType = (user: UserResponse) => {
  const isAdminUser = user?.roles.name.includes(AUTH_ROLES_ENUM.ADMIN);

  return isAdminUser ? 'admin' : 'manufacturer';
};

export const genProductTypeKeys = (productType: '1' | '2') => {
  if (productType === '2') {
    return {
      translation: 'sample_boxes',
      route: 'sample-boxes'
    };
  }

  return {
    translation: 'products',
    route: 'products'
  };
};

export const navigateToProductsPage = (
  productType: string,
  navigate: (path: string) => void
) => {
  setTimeout(() => {
    navigate(
      productType === '2' ? '/products/sample-boxes' : '/products/parents'
    );
  }, 1000);
};

export const getCategoryInitial = (
  categoriesData: productById_productById_data_category[]
) => {
  const categorySecondary: string[] = [];

  let mainParent = '';
  let mainChild = '';

  categoriesData?.forEach((item: productById_productById_data_category) => {
    if (item?.isMain) {
      if (!item.data?.parentId) {
        mainParent = String(item?.data?.id);
      } else {
        mainChild = String(item?.data?.id);
      }
    } else {
      categorySecondary.push(String(item?.data?.id));
    }
  });

  const categoryMain = [mainParent, mainChild].filter(i => i);

  return [categoryMain, categorySecondary];
};

export const getCategoriesOnFinish = (
  categoryMain: categories_categories_data[],
  categorySecondary: categories_categories_data[]
) => {
  const categories: { id: number; isMain?: boolean }[] = [];

  categoryMain?.forEach((i: categories_categories_data) => {
    categories.push({
      id: Number(i),
      isMain: true
    });
  });

  categorySecondary?.forEach((i: categories_categories_data) => {
    categories.push({
      id: Number(i)
    });
  });

  return categories;
};

export const getCategoryMainOptions = (
  categories: categories_categories_data[]
) => {
  return categories?.map((i: categories_categories_data_children) => {
    return {
      value: i.id,
      label: i.name,
      children: i?.children?.map((g: any) => {
        return {
          value: g.id,
          label: g.name
        };
      })
    };
  });
};
