import React, { FC } from 'react';

import SVG from '../SVG';

type Props = React.SVGProps<SVGSVGElement>;

const Star: FC<Props> = props => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    {...props}
  >
    <path
      d="M13.2503 2.36914H12.1581C12.0049 2.36914 11.8596 2.43945 11.7659 2.55977L5.32369 10.7207L2.23463 6.80664C2.1879 6.74731 2.12833 6.69934 2.06041 6.66632C1.99249 6.63331 1.91796 6.61611 1.84244 6.61602H0.750253C0.645565 6.61602 0.587753 6.73633 0.651815 6.81758L4.9315 12.2395C5.1315 12.4926 5.51588 12.4926 5.71744 12.2395L13.3487 2.56914C13.4128 2.48945 13.3549 2.36914 13.2503 2.36914Z"
      fill="white"
    />
  </SVG>
);

export default Star;
