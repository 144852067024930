import React, { FC, memo, useCallback, useRef } from 'react';
import { FormItem, Input, Select, TextArea, InputNumber } from '@stylique/core';

interface Props {
  label: string;
  id: number;
  name: string;
  type: string;
  options?: { label: string; value: string | number }[];
  isRequired: boolean;
}

const RenderField: FC<Props> = ({
  label,
  id,
  name,
  type,
  options,
  isRequired
}) => {
  const parentRef: any = useRef();

  const renderByType = useCallback(() => {
    switch (type) {
      case 'textarea':
        return <TextArea />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'select':
        return (
          <Select
            getPopupContainer={() => parentRef.current as HTMLDivElement}
            size="large"
            options={options}
            bordered
          />
        );
      default:
        return <Input />;
    }
  }, [options, type]);

  return (
    <div ref={parentRef}>
      <FormItem
        label={label}
        name={[`groups-${id}`, name]}
        rules={[{ required: isRequired }]}
      >
        {renderByType()}
      </FormItem>
    </div>
  );
};

export default memo(RenderField);
