import React, { createContext, useReducer, useContext, FC } from 'react';

import {
  StyleStateInputs,
  DispatchContext,
  ImageItemUpdate,
  NetworkInputs,
  ActionTypes,
  ProductItem,
  ImageItem,
  Dispatch,
  State
} from './types';
import reducer from './reducers';
import { initialState } from './initialState';

const StylesStateContext = createContext<State | undefined>(undefined);
const StylesDispatchContext = createContext<Dispatch | undefined>(undefined);

const StylesProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StylesStateContext.Provider value={state}>
      <StylesDispatchContext.Provider value={dispatch}>
        {children}
      </StylesDispatchContext.Provider>
    </StylesStateContext.Provider>
  );
};

const useStylesStateContext = (): State => {
  const context = useContext(StylesStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useStylesStateContext must be used within a useStylesStateContext'
    );
  }

  return context;
};

const useStylesDispatchContext = (): DispatchContext => {
  const dispatch = useContext(StylesDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useStylesDispatchContext must be used within a useStylesDispatchContext'
    );
  }

  const addImage = (image: ImageItem) => {
    dispatch({ type: ActionTypes.ADD_IMAGE, data: image });
  };

  const editImage = (image: ImageItem) => {
    dispatch({ type: ActionTypes.EDIT_IMAGE, data: image });
  };

  const updateImage = (index: number, data: ImageItemUpdate) => {
    dispatch({ type: ActionTypes.UPDATE_IMAGE, data: { index, data } });
  };

  const deleteImage = (index: number) => {
    dispatch({ type: ActionTypes.DELETE_IMAGE, data: index });
  };

  const setImageToEdit = (index: number) => {
    dispatch({ type: ActionTypes.SET_IMAGE_TO_EDIT, data: index });
  };

  const setImagePopup = (data: boolean) => {
    dispatch({ type: ActionTypes.SET_IMAGE_POPUP, data });
  };

  const setMainImage = (data: any) => {
    dispatch({ type: ActionTypes.SET_MAIN_IMAGE, data });
  };

  const linkProduct = (data: ProductItem) => {
    dispatch({ type: ActionTypes.LINK_PRODUCT, data });
  };

  const updateProduct = (id: number, data: number) => {
    dispatch({ type: ActionTypes.UPDATE_PRODUCT, data: { id, data } });
  };

  const updateAffiliateProducts = (id: string, type: string) => {
    dispatch({
      type: ActionTypes.UPDATE_AFFILIATE_PRODUCT,
      data: { id, type } as any
    });
  };

  const unlinkProduct = (data: number) => {
    dispatch({ type: ActionTypes.UNLINK_PRODUCT, data });
  };

  const unlinkAffiliateProduct = (productId: string) => {
    dispatch({ type: ActionTypes.UNLINK_AFFILIATE_PRODUCT, data: productId });
  };

  const setStyleValues = (data: StyleStateInputs) => {
    dispatch({ type: ActionTypes.SET_STYLE_VALUES, data });
  };

  const setValues = (data: State) => {
    dispatch({ type: ActionTypes.SET_VALUES, data });
  };

  const networkChanged = (data: (NetworkInputs | undefined)[]) => {
    const index = data.length - 1;

    const values = data[index];

    if (values) {
      dispatch({
        type: ActionTypes.UPDATE_NETWORK,
        data: { index, data: values }
      });
    } else {
      dispatch({ type: ActionTypes.ADD_NETWORK });
    }
  };

  const deleteNetwork = (data: number) => {
    dispatch({ type: ActionTypes.DELETE_NETWORK, data });
  };

  const setStatus = (data: number) => {
    dispatch({ type: ActionTypes.SET_STATUS, data });
  };

  const setSearchByName = (data: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_BY_NAME, data });
  };

  const setSearchBySku = (data: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_BY_SKU, data });
  };

  const setSearchByStatus = (data: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_BY_STATUS, data });
  };

  const setLinkTypeClickeds = (data: string) => {
    dispatch({ type: ActionTypes.SET_LINK_TYPE_CLICKEDS, data });
  };

  // New Style details actions

  const setCurrentStep = (data: number) => {
    dispatch({ type: ActionTypes.SET_CURRENT_STEP, data });
  };

  return {
    linkProduct,
    updateProduct,
    updateAffiliateProducts,
    unlinkProduct,
    unlinkAffiliateProduct,
    addImage,
    updateImage,
    deleteImage,
    setImagePopup,
    setStyleValues,
    setValues,
    networkChanged,
    deleteNetwork,
    setStatus,
    setImageToEdit,
    editImage,
    setSearchByName,
    setSearchBySku,
    setSearchByStatus,
    setLinkTypeClickeds,
    setMainImage,

    //
    setCurrentStep
  };
};

export default StylesProvider;
export { useStylesDispatchContext, useStylesStateContext };
