import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { attributes_attributes_data_results as AttributeType } from '__generated__/attributes';
import { Button } from '@stylique/core';
import client from 'apolloClient';
import { useParams } from 'react-router-dom';
import { useProductStateContext } from 'providers/Product';
import { GET_ATTRIBUTES } from 'graphql/attributes/queries';
import { PRODUCT_FRAGMENT } from 'graphql/products/fragments';

import SectionContainer from './SectionContainer';
import OptionItem from './OptionItem';

const MultiVariation: FC = () => {
  const { t } = useTranslation('admin');
  const { attributes } = useProductStateContext();
  const { id } = useParams();
  const [
    getAttributes,
    { data: { attributes: { data: { results = [] } = {} } = {} } = {} }
  ] = useLazyQuery(GET_ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    getAttributes({
      variables: { query: { is_configurable: true } }
    });
  }, [getAttributes]);

  const product = client.readFragment({
    id: client.cache.identify({ id, __typename: 'Products' }),
    fragment: PRODUCT_FRAGMENT,
    fragmentName: 'ProductFragment'
  });

  const convertedData = useMemo(() => {
    return results.map((item: AttributeType) => {
      const index = attributes.findIndex(
        element => element.attribute === item.id
      );

      return { ...item, disabled: index > -1 };
    });
  }, [attributes, results]);

  const initialValues = useMemo(() => {
    if (product && Object.keys(product).length) {
      return attributes;
    }

    return [{ attribute: undefined, values: [] }];
  }, [attributes, product]);

  return (
    <SectionContainer>
      <Form.List name="attributes" initialValue={initialValues}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index, arr) => {
              return (
                <OptionItem
                  key={`option-item-${key}`}
                  name={name}
                  fieldKey={fieldKey}
                  index={index}
                  remove={remove}
                  data={convertedData}
                  canRemove={arr.length > 1}
                  {...restField}
                />
              );
            })}
            {fields.length < results.length && (
              <Button
                size="middle"
                style={{ marginTop: 16 }}
                onClick={() => add()}
              >
                {t('variations.add_option')}
              </Button>
            )}
          </>
        )}
      </Form.List>
    </SectionContainer>
  );
};

export default MultiVariation;
