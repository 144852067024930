import React, { FC, Fragment } from 'react';
import { Space, Affix, AffixProps } from 'antd';
import { title } from 'process';

import {
  PageHeaderWrapper,
  Heading,
  HeadingLeft,
  HeadingExtra,
  HeadingTitle,
  BackButton,
  HeadingSubTitle
} from './styles';
import { ArrowLeft } from '../icon';

type Props = {
  backIcon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  extra?: React.ReactNode;
  onBack?: () => void;
  className?: string;
  ghost?: boolean;
  style?: React.CSSProperties;
  affix?: boolean;
  affixProps?: {
    offsetTop: number;
  };
};

const PageHeaderComponent: FC<Props> = ({
  backIcon,
  extra,
  title,
  subtitle,
  onBack,
  style,
  affix,
  affixProps = {}
}) => {
  const Wrapper = affix ? Affix : Fragment;

  return (
    <Wrapper {...affixProps}>
      <PageHeaderWrapper style={style} className="page-header">
        <Heading>
          <HeadingLeft>
            {onBack && (
              <BackButton onClick={onBack}>
                {backIcon ?? <ArrowLeft />}
              </BackButton>
            )}

            <Space direction="vertical">
              <HeadingTitle className="page-header-heading-title">
                {title}
              </HeadingTitle>
              {subtitle && <HeadingSubTitle>{subtitle}</HeadingSubTitle>}
            </Space>
          </HeadingLeft>

          <HeadingExtra>
            <Space>{extra}</Space>
          </HeadingExtra>
        </Heading>
      </PageHeaderWrapper>
    </Wrapper>
  );
};

export default PageHeaderComponent;
