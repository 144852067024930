import React, { FC, useMemo } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Popup } from '@stylique/core';

import { FormItemsTitle, ModalTitle } from '../../styles';
import Name from '../FormItems/Name';
import SeoTitle from '../FormItems/SeoTitle';
import SeoDescription from '../FormItems/SeoDescription';
import { IModal3, ISubmitLevel3 } from '../../types';
import Save from '../FormItems/Save';
import { getInitialValuesModal3 } from '../../utils/getInputValues';
import MetaKeywords from '../FormItems/MetaKeywords';
import MarketingTextTitle from '../FormItems/MarketingTextTitle';
import MarketingText from '../FormItems/MarketingText';

const Level3Modal: FC<IModal3> = ({
  isVisible,
  data,
  onClose,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();
  const type = data ? 'update' : 'create';

  const initialValues = useMemo(() => {
    if (!data) return {};

    return getInitialValuesModal3(data);
  }, [data]);

  const onCloseHandler = () => {
    onClose();
    form.resetFields();
  };

  const onFinishHandler = (values: ISubmitLevel3) => {
    onSubmit(values, type);
    onCloseHandler();
  };

  return (
    <Popup
      isVisible={isVisible}
      afterClose={onClose}
      onCancel={onCloseHandler}
      onClose={onCloseHandler}
      destroyOnClose
      width={600}
    >
      <ModalTitle>
        {type === 'create'
          ? t('website_menu.create_level_3')
          : t('website_menu.update_level_3')}
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishHandler}
        initialValues={initialValues}
      >
        <Name label={t('website_menu.name_level3')} />
        <FormItemsTitle>{t('website_menu.seo_block')}</FormItemsTitle>
        <MarketingTextTitle />
        <MarketingText />
        <SeoTitle />
        <SeoDescription />
        <MetaKeywords />
        <Save onClose={onClose} isLoading={isLoading} />
      </Form>
    </Popup>
  );
};

export default Level3Modal;
