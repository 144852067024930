import { gql } from '@apollo/client';

import { ATTRIBUTE_GROUP_FRAGMENT } from './fragments';

export const GET_ATTRIBUTE_GROUPS = gql`
  query attributeGroup {
    attributeGroup {
      data {
        ...AttributeGroupFragment
      }
      message
      status
    }
  }
  ${ATTRIBUTE_GROUP_FRAGMENT}
`;

export const GET_ATTRIBUTE_GROUP_BY_ID = gql`
  query attributeGroupId($id: ID!) {
    attributeGroupId(id: $id) {
      data {
        ...AttributeGroupFragment
      }
      message
      status
    }
  }
  ${ATTRIBUTE_GROUP_FRAGMENT}
`;

export const VARIATION_ATTRIBUTE_VALUE_GROUPS = gql`
  query variationAtributeValueGroups($query: SearchAttributeValueInput) {
    variationAtributeValueGroups(query: $query) {
      data {
        results {
          id
          attr_id
          name
          image
        }
        total
      }
      message
      status
    }
  }
`;
