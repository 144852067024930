import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Affix, Anchor, Col, Row, Form, Space, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, Link as ReactLink } from 'react-router-dom';
import {
  Button,
  Loading,
  Select,
  PageHeader,
  Paragraph,
  FormItem,
  Input,
  ImageUpload
} from '@stylique/core';
import { GET_ATTRIBUTE_FAMILY_BY_ID } from 'graphql/attributeFamilies/queries';
import { useAuthUserRoles } from 'auth/useAuth';
import { adminApiInstance } from 'services/adminApi';
import { getImageUrl } from '@stylique/tools';
import { WandStarts } from '@stylique/core/icons';

import TagSelect from 'components/TagSelect';
import {
  convertGroupsData,
  convertSimpleAttributeValues,
  findProductAttributeByName
} from 'utils/products';
import useQueryParams from 'hooks/useQueryParams';
import { GET_VARIATIONS_BY_ID } from '../../graphql/variationsProducts/queries';
import { FamilyQueryType, VariationItemQueryType, RequestData } from './types';
import { UPDATE_VARIATION } from '../../graphql/variationsProducts/mutations';
import {
  SectionContainer,
  RenderField
} from 'pages/ParentsProducts/components';
import { StyledVariationDetailsCard } from './styles';
import SuggestionPopup from './components/SuggestionPopup';
import JumpingDots from 'components/JumpingDots';
import { StyledAiButton } from 'components/CommonStyles';

const { Link } = Anchor;

const VariationDetails: FC = () => {
  const { t } = useTranslation('admin');
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { getParam } = useQueryParams();
  const backUrl = getParam('back');
  const [status, setStatus] = useState<'1' | '2'>('2');
  const [images, setImages] = useState<{ filename: string; main?: boolean }[]>(
    []
  );

  const [aiSuggestionLoading, setAiSuggestionLoading] = useState(false);
  const [isSuggestionModalOpened, setIsSuggestionModalOpened] = useState(false);
  const [suggestionData, setSuggestionData] = useState<Record<string, any>>({});

  const { isAdminUser } = useAuthUserRoles();

  const [
    getAttributeFamily,
    { data: { attributeFamilyById: { data: family = null } = {} } = {} }
  ] = useLazyQuery<FamilyQueryType>(GET_ATTRIBUTE_FAMILY_BY_ID);

  const {
    data: { variationById: { data: variation = null } = {} } = {},
    loading: isFetching
  } = useQuery<VariationItemQueryType>(GET_VARIATIONS_BY_ID, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache',
    onCompleted({ variationById: { data } }) {
      setStatus(data?.status as '1' | '2');

      if (data?.images) {
        setImages(
          data?.images?.map(item => ({
            main: item?.main || false,
            filename: item?.filename || ''
          }))
        );
      }
    }
  });

  // mutation for updating product
  const [updateVariation, { loading: isUpdating }] = useMutation(
    UPDATE_VARIATION,
    {
      onCompleted() {
        setImages([]);
        navigate(`/products/variations${backUrl?.replace(/AND/g, '&')}`);
      }
    }
  );

  useEffect(() => {
    if (variation?.parent?.family_id) {
      getAttributeFamily({ variables: { id: variation.parent.family_id } });
    }
  }, [variation, getAttributeFamily]);

  const onFinish = async (data: RequestData) => {
    const {
      name,
      slug,
      sku,
      price,
      tags,
      is_simple_product = false,
      general_images,
      ...rest
    } = data;

    const isMainSelected = images.some(item => item.main);

    if (!isMainSelected && images.length) {
      images[0].main = true;
    }

    const groupInputs = convertGroupsData(
      rest as unknown as { [key: string]: { [key: string]: string } }
    );

    const input = {
      name,
      slug,
      price,
      sku,
      tags,
      general_images,
      images,
      is_simple_product,
      inputs: groupInputs,
      status
    };

    updateVariation({ variables: { id: variation?.id, input } }).then(() =>
      message.success(
        status === '2'
          ? t('messages.successfully_product_set_draft', { ns: 'common' })
          : t('messages.successfully_product_published', { ns: 'common' })
      )
    );
  };

  const initialValues = useMemo(() => {
    if (variation) {
      return {
        name: variation.name,
        tags: variation.tags?.map(tag => tag?.data?.id),
        slug: variation.slug,
        sku: variation.sku,
        price: variation.price,
        is_simple_product: variation.is_simple_product,
        ...convertSimpleAttributeValues(variation.variationAttributes)
      };
    }

    return {};
  }, [variation]);

  const onMainImageSelect = (selected: number) => {
    setImages(prev => {
      const newData = prev.map(({ filename }, index) => {
        if (index === selected) {
          return { filename, main: true };
        }

        return { filename };
      });

      return newData;
    });
  };

  const onImagesChange = (values: string[] | string) => {
    setImages(
      (values as string[]).map(value => ({
        filename: value
      }))
    );
  };

  const handleAnalyzeWithAi = async () => {
    const imageUrls = images!.map(image => getImageUrl(image.filename, 1024));

    setAiSuggestionLoading(true);

    try {
      const { data: suggestionResponse } = await adminApiInstance.post<any>(
        '/ai-enhance/product-info-suggestion',
        { images: imageUrls }
      );

      if (suggestionResponse.data) {
        const suggestions = suggestionResponse.data;
        setSuggestionData({
          ...suggestions,
          tags: suggestions.tags.map((tag: any) => ({
            value: tag.id,
            label: tag.name
          }))
        });

        setIsSuggestionModalOpened(true);
      }
    } finally {
      setAiSuggestionLoading(false);
    }
  };

  const handleCloseSuggestionModal = () => {
    setIsSuggestionModalOpened(false);
  };

  const handleApplyAiSuggestions = async (suggestions: any) => {
    const attrDesc = findProductAttributeByName(
      family!.family,
      'Produktbeschreibung'
    );

    if (attrDesc) {
      form.setFieldValue(
        [`groups-${attrDesc.groupId}`, attrDesc.attribute.id],
        suggestions.description
      );
    }

    form.setFieldValue('tags', suggestions.tags);
    handleCloseSuggestionModal();
  };

  const defaultSelectedImage = useMemo(() => {
    if (variation) {
      return variation.images?.findIndex(item => item?.main);
    }

    return -1;
  }, [variation]);

  const combinationName = useMemo(() => {
    if (variation) {
      return variation.combinations?.reduce((acc, val): string => {
        if (!val) return '';

        if (!acc) {
          return val.option?.name || '';
        }

        return `${acc}/${val.option?.name}`;
      }, '');
    }

    return '';
  }, [variation]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <div style={{ paddingBottom: '120px' }}>
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <PageHeader
          ghost={false}
          title={t('variations.edit_variation')}
          extra={[
            <Select
              key="status"
              options={[
                {
                  label: t('draft', { ns: 'common' }),
                  value: '2'
                },
                {
                  label: t('publish', { ns: 'common' }),
                  value: '1'
                }
              ]}
              size="large"
              defaultValue={variation?.status || '2'}
              onChange={value => setStatus(value as '1' | '2')}
              bordered
            />,
            <Button
              key="publish_product"
              type="primary"
              size="large"
              style={{ marginLeft: 4 }}
              htmlType="submit"
              loading={isUpdating}
              onClick={form.submit}
            >
              {status === '1'
                ? t('products.publish_product')
                : t('save', { ns: 'common' })}
            </Button>
          ]}
        />
        <Row gutter={[8, 0]}>
          <Col sm={7}>
            <Affix offsetTop={30}>
              <StyledVariationDetailsCard>
                <Anchor offsetTop={30}>
                  <Link
                    href="#product-info"
                    title={t('products.product_info')}
                  />
                  <Link
                    href="#product-details"
                    title={t('products.product_details')}
                  />
                  {family?.family?.map(item => {
                    if (!item?.groupAttributes?.length) {
                      return null;
                    }

                    return (
                      <Link
                        key={`group-link-item-${item.id}`}
                        href={`#group-item-${item.id}`}
                        title={item.familyGroup?.name}
                      />
                    );
                  })}
                  <Link
                    href="#images"
                    title={t('variations.images')}
                    target=""
                  />
                </Anchor>
              </StyledVariationDetailsCard>
            </Affix>
          </Col>
          <Col sm={17}>
            <Space size={24} direction="vertical" style={{ width: '100%' }}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={initialValues}
                validateMessages={{
                  required: '${label} is required!'
                }}
                scrollToFirstError
              >
                <Space size={8} direction="vertical" style={{ width: '100%' }}>
                  <SectionContainer id="product-info">
                    <Paragraph color="text-color" style={{ marginBottom: 24 }}>
                      {t('products.product_info')}
                    </Paragraph>
                    <Space
                      size={8}
                      direction="vertical"
                      style={{ width: '100%' }}
                    >
                      <Paragraph color="text-color" style={{ fontSize: 14 }}>
                        <strong>{t('products.combination')}: </strong>{' '}
                        {combinationName}
                      </Paragraph>
                      <Paragraph color="text-color" style={{ fontSize: 14 }}>
                        <strong>{t('products.parent')}: </strong>{' '}
                        <ReactLink
                          to={`/products/parents/details/${variation?.parent?.id}`}
                        >
                          {variation?.parent?.name}
                        </ReactLink>
                      </Paragraph>
                      <Paragraph color="text-color" style={{ fontSize: 14 }}>
                        <strong>{t('products.category')}: </strong>{' '}
                        {variation?.category
                          ?.map(item => item?.data?.name)
                          ?.join(', ')}
                      </Paragraph>
                      <Paragraph color="text-color" style={{ fontSize: 14 }}>
                        <strong>{t('products.attributeFamily')}: </strong>{' '}
                        {family?.name}
                      </Paragraph>
                    </Space>
                  </SectionContainer>
                  <SectionContainer id="product-details">
                    <Paragraph color="text-color" style={{ marginBottom: 24 }}>
                      {t('products.product_details')}
                    </Paragraph>
                    {variation?.parent?.type === '2' && (
                      <FormItem
                        name="is_simple_product"
                        valuePropName="checked"
                        rules={[{ required: false }]}
                      >
                        <Checkbox>{t('products.as_simple_product')}</Checkbox>
                      </FormItem>
                    )}
                    <FormItem
                      label={t('products.name_title')}
                      name="name"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      label={t('slug_name', { ns: 'common' })}
                      name="slug"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      label="sku"
                      name="sku"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="price"
                      label={t('products.price')}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </FormItem>
                    <TagSelect
                      label={t('products.tags')}
                      name="tags"
                      type="product"
                    />
                  </SectionContainer>
                  {console.log(family?.family)}
                  {family?.family?.map(item => {
                    if (!item?.groupAttributes?.length) {
                      return null;
                    }

                    return (
                      <SectionContainer
                        key={`group-item-${item.id}`}
                        id={`group-item-${item.id}`}
                      >
                        <Paragraph
                          color="text-color"
                          style={{ marginBottom: 24 }}
                        >
                          {item.familyGroup?.name}
                        </Paragraph>
                        {item.groupAttributes.map(attribute => {
                          return (
                            <RenderField
                              key={`product-section-field-${item.id}-${attribute?.id}`}
                              id={item.familyGroup?.id || 0}
                              name={attribute?.attribute?.id || ''}
                              label={attribute?.attribute?.name || ''}
                              type={attribute?.attribute?.type || ''}
                              isRequired={
                                attribute?.attribute?.is_required || false
                              }
                              options={attribute?.attribute?.options?.map(
                                (item, index) => ({
                                  label: item?.name || '',
                                  value: item?.id || '',
                                  key: index
                                })
                              )}
                            />
                          );
                        })}
                      </SectionContainer>
                    );
                  })}
                  <SectionContainer id="images">
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Paragraph
                          color="text-color"
                          style={{ marginBottom: 24 }}
                        >
                          <Space align="center">
                            {t('variations.images')}
                            {isAdminUser && (
                              <StyledAiButton
                                onClick={handleAnalyzeWithAi}
                                disabled={aiSuggestionLoading}
                              >
                                <Space>
                                  Analyze Images with AI
                                  {aiSuggestionLoading ? (
                                    <JumpingDots light />
                                  ) : (
                                    <WandStarts />
                                  )}
                                </Space>
                              </StyledAiButton>
                            )}
                          </Space>
                        </Paragraph>
                      </Col>
                      <Col>
                        <FormItem name="general_images" valuePropName="checked">
                          <Checkbox>{t('variations.use_for_all')}</Checkbox>
                        </FormItem>
                      </Col>
                    </Row>
                    <ImageUpload
                      multiple
                      onChange={onImagesChange}
                      initialValues={variation?.images?.map(
                        image => image?.filename || ''
                      )}
                      selectedData={{
                        isSelectable: true,
                        onItemSelect: onMainImageSelect,
                        defaultSelected: defaultSelectedImage
                      }}
                    />
                  </SectionContainer>
                </Space>
              </Form>
            </Space>
          </Col>
        </Row>
      </Space>

      <SuggestionPopup
        open={isSuggestionModalOpened}
        initialValues={suggestionData as any}
        onClose={handleCloseSuggestionModal}
        onSubmit={handleApplyAiSuggestions}
      />
    </div>
  );
};

export default VariationDetails;
