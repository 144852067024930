import React from 'react';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';
import { Outlet } from 'react-router-dom';

import WebsiteMenu from './WebsiteMenu';

const Parents = React.lazy(() => import('../ParentsProducts'));
const ProductsDetails = React.lazy(
  () => import('../ParentsProducts/ProductsDetails')
);

const WebsiteBanners = React.lazy(() => import('./WebsiteBanners'));
const WebsiteBannersDetails = React.lazy(
  () => import('./WebsiteBanners/WebsiteBannersDetails')
);

const Categories = React.lazy(() => import('./Categories'));
const Tags = React.lazy(() => import('./Tags'));
const Attributes = React.lazy(() => import('./Attributes'));
const BlogList = React.lazy(() => import('./BlogList'));
const UserFeedback = React.lazy(() => import('./UserFeedback'));
const UserFeedbackDetails = React.lazy(
  () => import('./UserFeedback/UserFeedbackDetails')
);

const UserFeedbackAnalytics = React.lazy(
  () => import('./UserFeedback/UserFeedbackAnalytics')
);

const InformativeAttributeOptions = React.lazy(
  () => import('./Attributes/InformativeAttributeOptions')
);

const SystemAttributeOptions = React.lazy(
  () => import('./Attributes/SystemAttributeOptions')
);

const AttributesGroup = React.lazy(() => import('./AttributesGroup'));
const AttributeFamilies = React.lazy(() => import('./AttributeFamilies'));
const AttributeFamilyDetails = React.lazy(
  () => import('./AttributeFamilies/AttributeFamilyDetails')
);

const PartnerNetworkDetails = React.lazy(
  () => import('./ExternalProductSources/PartnerNetworkDetails')
);

const PartnerNetworks = React.lazy(
  () => import('./ExternalProductSources/PartnerNetworks')
);

const AffiliatesProducts = React.lazy(
  () => import('./ExternalProductSources/AffiliateProducts')
);

const Manufacturers = React.lazy(() => import('./Manufacturers'));
const Vendors = React.lazy(() => import('./Vendors'));
const Gallery = React.lazy(() => import('./Gallery'));

const Services = React.lazy(() => import('./Services'));
const Customers = React.lazy(() => import('./Customers'));

const AffiliateCategories = React.lazy(() => import('./AffiliateCategories'));

const AdminConfig = {
  settings: {
    layout: {}
  },
  auth: [AUTH_ROLES_ENUM.ADMIN],
  routes: [
    {
      path: 'collections',
      element: <Outlet />,
      children: [
        {
          path: 'categories',
          element: <Categories />
        },
        {
          path: 'tags',
          element: <Tags />
        },
        {
          path: 'attributes',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Attributes />
            },
            {
              path: 'options/informative/:id',
              element: <InformativeAttributeOptions />
            },
            {
              path: 'options/system/:id',
              element: <SystemAttributeOptions />
            }
          ]
        },
        {
          path: 'attribute-groups',
          element: <AttributesGroup />
        },
        {
          path: 'attribute-families',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <AttributeFamilies />
            },
            {
              path: 'details',
              element: <AttributeFamilyDetails />
            },
            {
              path: 'details/:id',
              element: <AttributeFamilyDetails />
            }
          ]
        }
      ]
    },
    {
      path: 'products/sample-boxes',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Parents productType="2" />
        },
        {
          path: 'details/:id',
          element: <ProductsDetails productType="2" />
        },
        {
          path: 'details',
          element: <ProductsDetails productType="2" />
        }
      ]
    },
    {
      path: 'external-product-sources',
      element: <Outlet />,
      children: [
        {
          path: 'networks/:id',
          element: <PartnerNetworkDetails />
        },
        {
          path: 'networks',
          element: <PartnerNetworks />
        },
        {
          path: 'affiliates',
          element: <Outlet />,
          children: [
            {
              path: 'products',
              element: <AffiliatesProducts />
            },
            {
              path: 'categories',
              element: <AffiliateCategories />
            }
          ]
        }
      ]
    },
    {
      path: 'user-management',
      element: <Outlet />,
      children: [
        {
          path: 'manufacturer',
          element: <Manufacturers />
        },
        {
          path: 'vendor',
          element: <Vendors />
        },
        {
          path: 'customers',
          element: <Customers />
        }
      ]
    },
    {
      path: 'gallery',
      element: <Gallery />
    },
    {
      path: 'services',
      element: <Services />
    },
    {
      path: 'user-feedback',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <UserFeedback />
        },
        {
          path: 'details/:id',
          element: <UserFeedbackDetails />
        },
        {
          path: 'analytics',
          element: <UserFeedbackAnalytics />
        }
      ]
    },
    {
      path: 'blog-list',
      element: <BlogList />
    },
    {
      path: 'website-banners',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <WebsiteBanners />
        },
        {
          path: 'details',
          element: <WebsiteBannersDetails />
        },
        {
          path: 'details/:id',
          element: <WebsiteBannersDetails />
        }
      ]
    },
    {
      path: 'website-menu',
      element: <WebsiteMenu />
    }
  ]
};

export default AdminConfig;
