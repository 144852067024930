import { gql } from '@apollo/client';

import { VENDOR_FRAGMENT } from './fragments';

export const CHECK_VENDOR_KEY = gql`
  mutation vendorCheck($input: VendorCheck!) {
    vendorCheck(input: $input) {
      data {
        ...VerifyVendorFragment
      }
      message
    }
  }
  ${VENDOR_FRAGMENT}
`;
