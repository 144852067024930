import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '@stylique/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';

const MarketingText = () => {
  const { t } = useTranslation('admin');
  const reactQuillRef = useRef<ReactQuill>(null);
  const form = Form.useFormInstance();
  const marketingText = form.getFieldValue(['marketing', 'text']);

  useEffect(() => {
    const quillInstance = reactQuillRef.current?.getEditor();

    if (quillInstance) {
      const delta = quillInstance.clipboard.convert(marketingText);
      quillInstance.setContents(delta, 'silent');
    }
  }, [marketingText]);

  return (
    <FormItem name={['marketing', 'text']} label={t('Marketing Text')}>
      <ReactQuill
        theme="snow"
        ref={reactQuillRef}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ color: [] }, { background: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean']
          ]
        }}
        style={{ height: 'auto' }}
      />
    </FormItem>
  );
};

export default MarketingText;
