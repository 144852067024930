import React, { FC } from 'react';
import { Modal, ModalProps } from 'antd';

interface Props extends ModalProps {
  isVisible: boolean;
  onClose: () => void;
  width?: string | number;
  closable?: boolean;
  forceRender?: boolean;
}

const Popup: FC<Props> = ({
  isVisible,
  onClose,
  width = 350,
  children,
  closable = true,
  forceRender = true,
  ...restProps
}) => {
  return (
    <Modal
      open={isVisible}
      forceRender={forceRender}
      afterClose={onClose}
      onCancel={onClose}
      footer={false}
      closable={closable}
      style={{ backgroundColor: 'var(--white)', borderRadius: 8 }}
      width={width}
      centered
      destroyOnClose
      {...restProps}
    >
      {children}
    </Modal>
  );
};

export default Popup;
