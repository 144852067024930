import React, { FC } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

interface Props {
  id?: string;
}

const StyledCard = styled(Card)`
  padding: 24px;
`;

const SectionContainer: FC<Props> = ({ id, children }) => {
  return <StyledCard id={id}>{children}</StyledCard>;
};

export default SectionContainer;
