export const combinationsString = (combinations: string[]) => {
  if (combinations.length) {
    return combinations.reduce((acc: any, val: any): string => {
      const name = val.option?.name || val.name || '';

      if (!acc) {
        return name;
      }

      return `${acc}/${name}`;
    }, '');
  }

  return '';
};
