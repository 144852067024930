import {
  getMenuItems_getMenuItems_data as IMenuItem,
  getMenuItems_getMenuItems_data_brands
} from '__generated__/getMenuItems';
import {
  getMenuItem_getMenuItem_data as IMenuItem4,
  getMenuItem_getMenuItem_data_attributes_value_groups,
  getMenuItem_getMenuItem_data_categories,
  getMenuItem_getMenuItem_data_manufacturers,
  getMenuItem_getMenuItem_data_products,
  getMenuItem_getMenuItem_data_tags
} from '__generated__/getMenuItem';

export const getInitialValuesModal2 = (data: IMenuItem) => ({
  name: data.name,
  mobile_image: data?.mobile_image,
  seo_data: {
    title: data.seo_data?.title,
    description: data.seo_data?.description,
    meteKeywords: data.seo_data?.meteKeywords ? data.seo_data?.meteKeywords : []
  },
  marketing: {
    title: data?.marketing?.title,
    text: data?.marketing?.text
  },
  promo_block: {
    image: data.image,
    link: data.link,
    promo_text: data.promo_text
  },
  brands: data.brands
    ? data.brands.map(
        (brand: getMenuItems_getMenuItems_data_brands | null) => ({
          slug: brand?.slug,
          image: brand?.image
        })
      )
    : []
});

export const getInitialValuesModal3 = (data: IMenuItem) => ({
  name: data.name,
  marketing: {
    title: data?.marketing?.title,
    text: data?.marketing?.text
  },
  seo_data: {
    title: data.seo_data?.title,
    description: data.seo_data?.description,
    meteKeywords: data.seo_data?.meteKeywords ? data.seo_data?.meteKeywords : []
  }
});

export const getInitialValuesModal4 = (data: IMenuItem4) => ({
  name: data.name,
  marketing: {
    title: data?.marketing?.title,
    text: data?.marketing?.text
  },
  seo_data: {
    title: data.seo_data?.title,
    description: data.seo_data?.description,
    meteKeywords: data.seo_data?.meteKeywords ? data.seo_data?.meteKeywords : []
  },
  manufacturersCheckbox: data.manufacturers?.length,
  manufacturers: data.manufacturers?.map(
    (item: getMenuItem_getMenuItem_data_manufacturers | null) =>
      item?.manufacturer_id
  ),
  tagsCheckbox: data.tags?.length,
  tags: data.tags?.map(
    (item: getMenuItem_getMenuItem_data_tags | null) => item?.tag_id
  ),
  categoriesCheckbox: data.categories?.length,
  categories: data.categories?.map(
    (item: getMenuItem_getMenuItem_data_categories | null) =>
      String(item?.cat_id)
  ),
  attributeValuesCheckbox: data.attributes_value_groups?.length,
  attributeValues: data.attributes_value_groups?.map(
    (item: getMenuItem_getMenuItem_data_attributes_value_groups | null) =>
      item?.data?.id
  ),
  exactProductCheckbox: data.products?.length,
  products: data.products?.map(
    (item: getMenuItem_getMenuItem_data_products | null) => item?.sku || ''
  )
});
