import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Card, Typography, Form } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Loading, FormItem, Input } from '@stylique/core';

import { GET_VENDOR_BY_KEY } from './graphql/queries';
import { CHECK_VENDOR_KEY } from './graphql/mutations';

const { Paragraph } = Typography;

type Input = {
  password: string;
  confirm_password: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
`;

const Container = styled(Card)`
  padding: 54px 76px;
  width: 522px;
`;

const Titles = styled.div`
  text-align: center;
`;

const Welcome = styled(Paragraph)`
  font-size: 18px;
`;

const VerifyVendor: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { key } = useParams();
  const navigate = useNavigate();

  const { loading } = useQuery(GET_VENDOR_BY_KEY, {
    variables: { key }
  });

  const [checkKey, { loading: loadingMutation }] = useMutation(
    CHECK_VENDOR_KEY,
    {
      onCompleted() {
        navigate('/login');
      }
    }
  );

  const onFinish = async (input: Input) => {
    await checkKey({
      variables: {
        input: {
          key,
          password: input.password
        }
      }
    });
  };

  if (loading) {
    <Loading />;
  }

  return (
    <Wrapper>
      <Container>
        <Titles>
          <Welcome>{t('login.title', { ns: 'common' })}</Welcome>
          <Paragraph>{t('login.create_password', { ns: 'common' })}</Paragraph>
        </Titles>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem
            label={t('password', { ns: 'common' })}
            name="password"
            rules={[
              {
                required: true,
                message: t('required', { ns: 'common' })
              }
            ]}
          >
            <Input type="password" />
          </FormItem>
          <FormItem
            label={t('confirm_password', { ns: 'common' })}
            name="confirm_password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('required', { ns: 'common' })
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    t('password_dont_match', { ns: 'common' })
                  );
                }
              })
            ]}
          >
            <Input type="password" />
          </FormItem>
          <Form.Item style={{ marginTop: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loadingMutation}
              style={{
                background: 'var(--gray-10)',
                borderColor: 'var(--gray-10)'
              }}
            >
              {t('save', { ns: 'common' })}
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default VerifyVendor;
