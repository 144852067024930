import React from 'react';
import { AUTH_ROLES_ENUM } from 'auth/authRoles';
import { Outlet } from 'react-router-dom';

const ProfileInformation = React.lazy(() => import('./ProfileInformation'));
const Showrooms = React.lazy(() => import('./Showrooms'));
const Products = React.lazy(() => import('./Products'));
const PaymentConfigurations = React.lazy(
  () => import('./ProfileSettings/PaymentConfigurations')
);

const MyNetwork = React.lazy(() => import('./Partners/MyNetwork'));
const StyliqueNetwork = React.lazy(() => import('./Partners/StyliqueNetwork'));
const NetworkProducts = React.lazy(
  () => import('./Partners/components/Products')
);

const ProfileSettingsDelivery = React.lazy(
  () => import('./ProfileSettings/Delivery')
);

const ProfileSettingsClickAndCollect = React.lazy(
  () => import('./ProfileSettings/ClickAndCollect')
);

const OrderSettings = React.lazy(() => import('./OrderSettings/OrderSettings'));

const CreaftsmanServiceSettings = React.lazy(
  () => import('./CraftsmanSettings/ServiceSettings')
);

const CraftsmanDrivingCosts = React.lazy(
  () => import('./CraftsmanSettings/DrivingCosts')
);

const DeliveryDetails = React.lazy(
  () => import('./ProfileSettings/Delivery/DeliveryDetails')
);

const VendorConfig = {
  settings: {
    layout: {}
  },
  auth: [
    AUTH_ROLES_ENUM.VENDOR,
    AUTH_ROLES_ENUM.CRAFTSMAN,
    AUTH_ROLES_ENUM.ARCHITECT,
    AUTH_ROLES_ENUM.ACCOUNT_PRO,
    AUTH_ROLES_ENUM.ACCOUNT_BASIC
  ],
  routes: [
    {
      path: 'vendor-profile',
      element: <Outlet />,
      children: [
        {
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ARCHITECT,
            AUTH_ROLES_ENUM.ACCOUNT_PRO,
            AUTH_ROLES_ENUM.ACCOUNT_BASIC
          ],
          path: 'profile-information',
          element: <ProfileInformation />
        },
        {
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ARCHITECT,
            AUTH_ROLES_ENUM.ACCOUNT_BASIC,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ],
          path: 'showrooms',
          element: <Showrooms />
        }
      ]
    },
    {
      path: 'vendor-products',
      element: <Products />
    },
    {
      path: 'configurations',
      element: <Outlet />,
      children: [
        {
          path: 'payment-configuration',
          element: <PaymentConfigurations />
        },
        {
          path: 'delivery/details',
          element: <ProfileSettingsDelivery />
        },
        {
          path: 'click-and-collect',
          element: <ProfileSettingsClickAndCollect />
        },
        {
          path: 'order-settings',
          element: <OrderSettings />,
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ]
        },
        {
          path: 'delivery/details/add-delivery-template',
          element: <DeliveryDetails />
        },
        {
          path: 'delivery/details/:id',
          element: <DeliveryDetails />
        }
      ]
    },
    {
      auth: [
        AUTH_ROLES_ENUM.VENDOR,
        AUTH_ROLES_ENUM.CRAFTSMAN,
        AUTH_ROLES_ENUM.ARCHITECT,
        AUTH_ROLES_ENUM.ACCOUNT_PRO
      ],
      path: 'vendor-partners',
      element: <Outlet />,
      children: [
        {
          path: 'my-network',
          element: <MyNetwork />,
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ARCHITECT,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ]
        },
        {
          path: 'stylique-network',
          element: <StyliqueNetwork />,
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ARCHITECT,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ]
        },
        {
          path: 'stylique-network/products/:id',
          element: <NetworkProducts />
        }
      ]
    },
    {
      permissionType: 'hasAllPermissions',
      path: 'craftsman-settings',
      element: <Outlet />,
      children: [
        {
          path: 'service-settings',
          element: <CreaftsmanServiceSettings />,
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ]
        },
        {
          path: 'driving-costs',
          element: <CraftsmanDrivingCosts />,
          auth: [
            AUTH_ROLES_ENUM.VENDOR,
            AUTH_ROLES_ENUM.CRAFTSMAN,
            AUTH_ROLES_ENUM.ACCOUNT_PRO
          ]
        }
      ]
    }
  ]
};

export default VendorConfig;
