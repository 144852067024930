import IS_BROWSER from 'utils/isBrowser';

const isLocalStorageAvaulablte = () => {
  try {
    return window.localStorage ? window.localStorage : fakeStorage;
  } catch (e) {
    return fakeStorage;
  }
};

const fakeStorage = (function () {
  let storage = new Map();

  return {
    getItem: (key: string) => storage.get(key),
    setItem: (key: string, value: any) => storage.set(key, value),
    removeItem: (key: string) => storage.delete(key),
    clear: () => (storage = new Map())
  };
})();

const storageApi = ((IS_BROWSER && isLocalStorageAvaulablte()) ||
  fakeStorage) as typeof localStorage;

export default storageApi;
