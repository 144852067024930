import { Button } from '@stylique/core';
import { Card, Typography } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCard = styled(Card)`
  min-height: calc(100vh - 88px);
  position: relative;
  .ant-page-header-heading-extra {
    margin-left: auto;
  }
`;

export const BoldTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  color: #464646;
  line-height: 1.3;
  margin-bottom: 0;
`;

interface IProps {
  $published: boolean;
}

export const StyledChip = styled(Typography.Text)<IProps>`
  border-radius: 16px;
  padding: 3px 10px;
  font-size: 13px;
  white-space: nowrap;
  max-width: 120px;
  ${props => {
    if (props.$published) {
      return css`
        color: #679436;
        border: 1px solid rgba(103, 148, 54, 0.5);
      `;
    }

    return css`
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(0, 0, 0, 0.26);
    `;
  }}
`;

export const StyledAiButton = styled(Button)`
  background-color: var(--sunrise-yellow);
  color: var(--white);
  border: none;

  svg path {
    fill: var(--white);
  }

  &:disabled {
    && {
      background-color: var(--sunrise-yellow-hover);
      opacity: 0.7;
      color: var(--white) !important;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background-color: var(--sunrise-yellow-hover);
    color: var(--white);
    border: none;
  }
`;
