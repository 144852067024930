import { gql } from '@apollo/client';

import { ATTRIBUTE_FRAGMENT } from '../attributes/fragments';

export const ATTRIBUTE_GROUP_FRAGMENT = gql`
  fragment AttributeGroupFragment on AttributeGroup {
    id
    name
    slug
    is_editable
    group {
      id
      sortIndex
      attribute {
        ...AttributeFragment
      }
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;
