import { gql } from '@apollo/client';

import { PRODUCTS_LIST_FRAGMENT } from './fragments';

export const DELETE_PRODUCT = gql`
  mutation productsDelete($id: ID!) {
    productsDelete(id: $id) {
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation productsCreate($input: ProductsInput!) {
    productsCreate(input: $input) {
      data {
        ...ProductListFragment
      }
      status
      message
    }
  }
  ${PRODUCTS_LIST_FRAGMENT}
`;

export const UPDATE_PRODUCT = gql`
  mutation productsUpdate($id: ID!, $input: ProductsInput!) {
    productsUpdate(id: $id, input: $input) {
      data {
        ...ProductListFragment
      }
      status
      message
    }
  }
  ${PRODUCTS_LIST_FRAGMENT}
`;

export const UNLINK_FROM_SAMPLE_BOX = gql`
  mutation unLinkSampleBox($input: SampleBoxInput!) {
    unLinkSampleBox(input: $input) {
      status
      message
    }
  }
`;
