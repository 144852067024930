import React, { FC, memo, useMemo } from 'react';
import { type NavigationType } from 'types/navigation';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'auth';
import { StUtils } from '@stylique/utils';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

type Props = {
  navigation: NavigationType[];
};

const Navigation: FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const user = useAuth(state => state.user);
  const { pathname } = useLocation();

  const defaultOpenKeys = useMemo(() => {
    const menuIndex = navigation.findIndex(nav => {
      return (
        nav.children &&
        nav.children.findIndex(child =>
          new RegExp('^' + child.key + '(\\b|$)').test(pathname)
        ) > -1
      );
    });

    if (menuIndex > -1) {
      return [navigation[menuIndex].key];
    }

    return [];
  }, [navigation, pathname]);

  const items = useMemo(() => {
    const userRole = user?.roles.name;

    function getMenuItems(navigation: NavigationType[], userRole: any): any {
      const result: ItemType[] = [];

      if (!userRole) {
        return result;
      }

      for (const item of navigation) {
        const permissionType = item.permissionType || 'hasOneOfPermissions';

        // TODO: change this
        if (
          user?.wholesaler &&
          item.key === '/vendor-orders' &&
          !user.isVisible
        ) {
          continue;
        }

        if (!StUtils[permissionType](item.auth, userRole)) {
          continue;
        }

        item.label = t(item.label);

        if (item.children) {
          if (item.children.length === 0) {
            continue;
          }

          item.children = getMenuItems(item.children, userRole);

          if (item.children?.length === 1) {
            result.push(...item.children);
            continue;
          }
        }

        result.push({
          children: item.children,
          key: item.key,
          label: item.label
        });
      }

      return result;
    }

    return getMenuItems(navigation, userRole);
  }, [navigation, t, user]);

  return (
    <>
      <Menu
        mode="inline"
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[pathname]}
        activeKey={pathname}
        items={items}
        onClick={props => {
          navigate(props.key);
        }}
      />
    </>
  );
};

export default memo(Navigation);
